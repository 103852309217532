import translationEn from "./en";
import translationJp from "./jp";

const resources = {
    en: {
        translation: translationEn,
    },
    jp: {
        translation: translationJp,
    },
};

export default resources;
