// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    helpSupport as helpSupportApi,
} from '../../../helpers/api/dashboardActions/help';

import { helpApiResponseSuccess, helpApiResponseError } from './actions';
import { HelpActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* helpSupport({ payload: { subject, message } }) {
    try {
        const response = yield call(helpSupportApi, {subject, message});
        yield put(helpApiResponseSuccess(HelpActionTypes.HELP_SUPPORT, ProcessData(response)));
    } catch (error) {
        yield put(helpApiResponseError(HelpActionTypes.HELP_SUPPORT, ProcessError(error)));
    }
}

export function* watchHelpSupport(): any {
    yield takeEvery(HelpActionTypes.HELP_SUPPORT, helpSupport);
}

function* helpSage(): any {
    yield all([
        fork(watchHelpSupport),
    ]);
}

export default helpSage;
