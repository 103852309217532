import main from './main.json';
import appStoreList from './appStoreList.json';
import addAppStore from './addAppStore.json';
import editAppStore from './editAppStore.json';
import syncAppStore from './syncAppStore.json';
import removeAppStore from './removeAppStore.json';
import manageAppStore from './manage';

const output = Object.assign({}, main, appStoreList, addAppStore, editAppStore, syncAppStore, removeAppStore, manageAppStore);

export default output;
