import generic from './generic.json';
import login from './login.json';
import logout from './logout.json';
import forgetPassword from './forgetPassword.json';
import resetPassword from './resetPassword.json';
import register from './register.json';
import confirmRegister from './confirmRegister.json';
import validateEmail from './validateEmail.json';

const output = Object.assign({}, generic, login, logout, forgetPassword, resetPassword, register, confirmRegister, validateEmail);

export default output;
