// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    validateUserEmail as validateUserEmailApi,
    forgotPassword as forgotPasswordApi,
    forgotPasswordConfirm as forgotPasswordConfirmApi,
    verify as verifyApi,
} from '../../../helpers/api/dashboardActions/auth';

import { APICore, setAuthorization } from '../../../helpers/api/apiCore';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password } }) {
    try {
        const response = yield call(loginApi, { email, password });
        const data = ProcessData(response);
        api.setLoggedInUser(data);
        setAuthorization(data['refreshToken']);
        api.setSelection(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, ProcessError(error)));
        api.setLoggedInUser(null);
        setAuthorization(null);
        api.setSelection(null);
    }
}

/**
 * Logout the user
 */
function* logout() {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        api.setSelection(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error) {
        api.setLoggedInUser(null);
        setAuthorization(null);
        api.setSelection(null);
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, ProcessError(error)));
    }
}

function* signup({ payload: { fullname, email, password } }) {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, ProcessData(response)));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, ProcessError(error)));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* validateUserEmail({ payload: { token } }) {
    try {
        const response = yield call(validateUserEmailApi, { token });
        yield put(authApiResponseSuccess(AuthActionTypes.VALIDATE_USER_EMAIL, ProcessData(response)));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.VALIDATE_USER_EMAIL, ProcessError(error)));
    }
}

function* forgotPassword({ payload: { email } }) {
    try {
        const response = yield call(forgotPasswordApi, { email });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, ProcessData(response)));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, ProcessError(error)));
    }
}

function* forgotPasswordChange({ payload: { token, password } }) {
    try {
        const response = yield call(forgotPasswordConfirmApi, {token, password});
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, ProcessData(response)));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, ProcessError(error)));
    }
}
function* verify() {
    try {
        const response = yield call(verifyApi, {});
        const data = ProcessData(response);
        api.setLoggedInUser(data);
        setAuthorization(data['refreshToken']);
        yield put(authApiResponseSuccess(AuthActionTypes.VERIFY_USER, data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.VERIFY_USER, ProcessError(error)));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchEmailValidate(): any {
    yield takeEvery(AuthActionTypes.VALIDATE_USER_EMAIL, validateUserEmail);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

export function* watchVerify(): any {
    yield takeEvery(AuthActionTypes.VERIFY_USER, verify);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchEmailValidate),
        fork(watchForgotPassword),
        fork(watchForgotPasswordChange),
        fork(watchVerify),
    ]);
}

export default authSaga;
