import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// Routes
import { DashboardRoutesConstants } from '../constants/urls/routes';

// load component
import { getLayout, DefaultLayout } from './Layout';

// Routes
import { ErrorRouter } from './components/error';
import { AuthRouter } from './components/auth';
import DashboardRouter from './components/dashboard';
import ServicesRouter from './components/services';
import ServicesPublicRouter from './components/services/public';

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

   const Layout = getLayout(layout.layoutType);

    return useRoutes([
        { path: DashboardRoutesConstants.BaseURL, element: <Root /> },
        {
            // public routes
            path: DashboardRoutesConstants.BaseURL,
            element: <DefaultLayout />,
            children: AuthRouter,
        },
        {
            // public routes
            path: DashboardRoutesConstants.BaseURL,
            element: <DefaultLayout />,
            children: ServicesPublicRouter,
        },

        {
            // auth protected routes
            path: DashboardRoutesConstants.BaseURL,
            element: <PrivateRoute roles={['*']} component={Layout} />,
            children: [
                ...DashboardRouter,
                ...ServicesRouter,
            ],
        },

        ...ErrorRouter,
    ]);
};

export { AllRoutes };
