import { DashboardRouter } from './dashboard';
import { HelpRouter } from './help';
import { OrganizationRouter } from './organization';
import { ApplicationRouter } from './application';

const routes = [
    ...DashboardRouter,
    ...HelpRouter,
    ...OrganizationRouter,
    ...ApplicationRouter,
];

export default routes;
