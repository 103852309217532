// @flow
import { BuildManagerActionTypes } from './constants';

type BuildManagerAction = { type: string, payload: {} | string };

// common success
export const buildManagerApiResponseSuccess = (actionType: string, data: any): BuildManagerAction => ({
    type: BuildManagerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const buildManagerApiResponseError = (actionType: string, error: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Package Manager
export const buildManagerValidate = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_VALIDATE,
    payload: {},
});

export const resetBuildManager = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.RESET,
    payload: {},
});

// Settings
export const buildManagerSettingsInfo = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_SETTINGS_INFO,
    payload: {},
});
export const buildManagerSettingsGit = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_SETTINGS_GIT,
    payload: {},
});
export const buildManagerSettingsUpdate = (gitId: string, gitRepo: string, unityId: string, unityVersion: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_SETTINGS_UPDATE,
    payload: { gitId: gitId, gitRepo: gitRepo, unityId: unityId, unityVersion: unityVersion },
});
export const resetBuildManagerSettings = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_SETTINGS_RESET,
    payload: {},
});

// Flow
export const buildManagerFlowInfo = (flowIds: any): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_FLOW_INFO,
    payload: { flowIds: flowIds },
});
export const buildManagerFlowEssential = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_FLOW_ESSENTIAL,
    payload: {},
});
export const buildManagerFlowAdd = (flowName: string, gitBranch: string, bundleId: string, platform: string, environment: string, credentialId: string, credentialSelection: string, storeId: string, password: string,
                                    settingsBuildAddressables: boolean, settingsAddressablesProfile: string, settingsPrebuildMethod: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_FLOW_ADD,
    payload: { flowName: flowName, gitBranch: gitBranch, bundleId: bundleId, platform: platform, environment: environment, credentialId: credentialId, credentialSelection: credentialSelection, storeId: storeId, password: password,
        settingsBuildAddressables: settingsBuildAddressables, settingsAddressablesProfile: settingsAddressablesProfile, settingsPrebuildMethod: settingsPrebuildMethod },
});
export const buildManagerFlowEdit = (flowId: string, flowName: string, gitBranch: string, bundleId: string, platform: string, environment: string, credentialId: string, credentialSelection: string, storeId: string, password: string,
                                     settingsBuildAddressables: boolean, settingsAddressablesProfile: string, settingsPrebuildMethod: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_FLOW_EDIT,
    payload: { flowId: flowId, flowName: flowName, gitBranch: gitBranch, bundleId: bundleId, platform: platform, environment: environment, credentialId: credentialId, credentialSelection: credentialSelection, storeId: storeId, password: password,
        settingsBuildAddressables: settingsBuildAddressables, settingsAddressablesProfile: settingsAddressablesProfile, settingsPrebuildMethod: settingsPrebuildMethod },
});
export const buildManagerFlowRemove = (flowId: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_FLOW_REMOVE,
    payload: { flowId: flowId },
});
export const resetBuildManagerFlow = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_FLOW_RESET,
    payload: {},
});

// Build
export const buildManagerBuildInfo = (buildIds: any, flowId: any): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO,
    payload: { buildIds: buildIds, flowId: flowId },
});
export const buildManagerBuildStart = (flowId: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_START,
    payload: { flowId: flowId },
});
export const buildManagerBuildUnlock = (buildId: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_UNLOCK,
    payload: { buildId: buildId },
});
export const buildManagerBuildRemove = (buildId: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_REMOVE,
    payload: { buildId: buildId },
});
export const buildManagerBuildInfoDetails = (buildId: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DETAILS,
    payload: { buildId: buildId },
});
export const buildManagerBuildInfoDownload = (buildId: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DOWNLOAD,
    payload: { buildId: buildId },
});
export const buildManagerBuildInfoLog = (buildId: string): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_LOG,
    payload: { buildId: buildId },
});
export const resetBuildManagerBuild = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_BUILD_RESET,
    payload: {},
});


// Public - Build
export const buildManagerPublicBuildInfo = (buildId: any, password: any): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_INFO,
    payload: { buildId: buildId, password: password },
});
export const resetBuildManagerPublicBuild = (): BuildManagerAction => ({
    type: BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_RESET,
    payload: {},
});

