// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    packageManagerValidate as packageManagerValidateApi,

    packageManagerKeysInfo as packageManagerKeysInfoApi,
    packageManagerAddKey as packageManagerAddKeyApi,
    packageManagerRemoveKey as packageManagerRemoveKeyApi,

    packageManagerPackagesInfo as packageManagerPackagesInfoApi,
    packageManagerPackageGit as packageManagerPackageGitApi,
    packageManagerAddPackage as packageManagerAddPackageApi,
    packageManagerEditPackage as packageManagerEditPackageApi,
    packageManagerSyncPackage as packageManagerSyncPackageApi,
    packageManagerRemovePackage as packageManagerRemovePackageApi,
} from '../../../helpers/api/servicesActions/package-manager';

import { packageManagerApiResponseSuccess, packageManagerApiResponseError } from './actions';
import { PackageManagerActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* packageManagerValidate() {
    try {
        const response = yield call(packageManagerValidateApi);
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_VALIDATE, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_VALIDATE, ProcessError(error)));
    }
}
function* packageManagerKeysInfo({ payload: { keysIds } }) {
    try {
        const response = yield call(packageManagerKeysInfoApi, { keysIds });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_KEY_INFO, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_KEY_INFO, ProcessError(error)));
    }
}
function* packageManagerAddKey({ payload: { keyName } }) {
    try {
        const response = yield call(packageManagerAddKeyApi, { keyName });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_KEY_ADD, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_KEY_ADD, ProcessError(error)));
    }
}
function* packageManagerRemoveKey({ payload: { keyId } }) {
    try {
        const response = yield call(packageManagerRemoveKeyApi, { keyId });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_KEY_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_KEY_REMOVE, ProcessError(error)));
    }
}
function* packageManagerPackagesInfo({ payload: { packagesIds } }) {
    try {
        const response = yield call(packageManagerPackagesInfoApi, { packagesIds });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_INFO, ProcessError(error)));
    }
}
function* packageManagerPackageGit() {
    try {
        const response = yield call(packageManagerPackageGitApi);
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_GIT, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_GIT, ProcessError(error)));
    }
}
function* packageManagerAddPackage({ payload: { gitId, gitRepo, gitBranch, isPublic } }) {
    try {
        const response = yield call(packageManagerAddPackageApi, { gitId, gitRepo, gitBranch, isPublic });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_ADD, ProcessError(error)));
    }
}
function* packageManagerEditPackage({ payload: { packageId, gitId, gitRepo, gitBranch, isPublic } }) {
    try {
        const response = yield call(packageManagerEditPackageApi, { packageId, gitId, gitRepo, gitBranch, isPublic });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_EDIT, ProcessError(error)));
    }
}
function* packageManagerSyncPackage({ payload: { packageId } }) {
    try {
        const response = yield call(packageManagerSyncPackageApi, { packageId });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_SYNC, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_SYNC, ProcessError(error)));
    }
}
function* packageManagerRemovePackage({ payload: { packageId, packageVersion } }) {
    try {
        const response = yield call(packageManagerRemovePackageApi, { packageId, packageVersion });
        yield put(packageManagerApiResponseSuccess(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(packageManagerApiResponseError(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_REMOVE, ProcessError(error)));
    }
}

export function* watchPackageManagerValidate(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_VALIDATE, packageManagerValidate);
}
export function* watchPackageManagerKeysInfo(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_KEY_INFO, packageManagerKeysInfo);
}
export function* watchPackageManagerAddKey(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_KEY_ADD, packageManagerAddKey);
}
export function* watchPackageManagerRemoveKey(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_KEY_REMOVE, packageManagerRemoveKey);
}
export function* watchPackageManagerPackagesInfo(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_INFO, packageManagerPackagesInfo);
}
export function* watchPackageManagerPackagesGit(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_GIT, packageManagerPackageGit);
}
export function* watchPackageManagerAddPackage(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_ADD, packageManagerAddPackage);
}
export function* watchPackageManagerEditPackage(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_EDIT, packageManagerEditPackage);
}
export function* watchPackageManagerSyncPackage(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_SYNC, packageManagerSyncPackage);
}
export function* watchPackageManagerRemovePackage(): any {
    yield takeEvery(PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_REMOVE, packageManagerRemovePackage);
}

function* packageManagerSage(): any {
    yield all([
        fork(watchPackageManagerValidate),
        fork(watchPackageManagerKeysInfo),
        fork(watchPackageManagerPackagesGit),
        fork(watchPackageManagerAddKey),
        fork(watchPackageManagerRemoveKey),
        fork(watchPackageManagerPackagesInfo),
        fork(watchPackageManagerAddPackage),
        fork(watchPackageManagerEditPackage),
        fork(watchPackageManagerSyncPackage),
        fork(watchPackageManagerRemovePackage),
    ]);
}

export default packageManagerSage;
