// @flow
import { CredentialsActionTypes } from './constants';

type CredentialsAction = { type: string, payload: {} | string };

// common success
export const credentialsApiResponseSuccess = (actionType: string, data: any): CredentialsAction => ({
    type: CredentialsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const credentialsApiResponseError = (actionType: string, error: string): CredentialsAction => ({
    type: CredentialsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Credentials
export const credentialsValidate = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_VALIDATE,
    payload: {},
});

export const resetCredentials = (): CredentialsAction => ({
    type: CredentialsActionTypes.RESET,
    payload: {},
});

// Git
export const credentialsGitInfo = (gitIds: any): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GIT_INFO,
    payload: { gitIds: gitIds },
});
export const credentialsAddGit = (gitName: string, gitType: string, gitUrl: string, gitIdentifier:string, gitToken: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GIT_ADD,
    payload: { gitName: gitName, gitType: gitType, gitUrl: gitUrl, gitIdentifier: gitIdentifier, gitToken: gitToken },
});
export const credentialsEditGit = (gitId: string, gitName: string, gitType: string, gitUrl: string, gitIdentifier:string, gitToken: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GIT_EDIT,
    payload: { gitId: gitId, gitName: gitName, gitType: gitType, gitUrl: gitUrl, gitIdentifier: gitIdentifier, gitToken: gitToken },
});
export const credentialsSyncGit = (gitId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GIT_SYNC,
    payload: { gitId: gitId },
});
export const credentialsRemoveGit = (gitId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GIT_REMOVE,
    payload: { gitId: gitId },
});
export const resetCredentialsGit = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GIT_RESET,
    payload: {},
});

// Unity
export const credentialsUnityInfo = (unityIds: any): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_UNITY_INFO,
    payload: { unityIds: unityIds },
});
export const credentialsAddUnity = (unityName: string, unityUsername: string, unityPassword: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_UNITY_ADD,
    payload: { unityName: unityName, unityUsername: unityUsername, unityPassword: unityPassword },
});
export const credentialsEditUnity = (unityId: string, unityName: string, unityUsername: string, unityPassword: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_UNITY_EDIT,
    payload: { unityId: unityId, unityName: unityName, unityUsername: unityUsername, unityPassword: unityPassword },
});
export const credentialsRemoveUnity = (unityId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_UNITY_REMOVE,
    payload: { unityId: unityId },
});
export const resetCredentialsUnity = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_UNITY_RESET,
    payload: {},
});

// PlayStore
export const credentialsGooglePlayStoreInfo = (playStoreIds: any): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_INFO,
    payload: { playStoreIds: playStoreIds },
});
export const credentialsGooglePlayStoreAdd = (keyName: string, file: FileList): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_ADD,
    payload: { keyName: keyName, file: file },
});
export const credentialsGooglePlayStoreEdit = (playStoreId: string, keyName: string, file: FileList): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_EDIT,
    payload: { playStoreId: playStoreId, keyName: keyName, file: file },
});
export const credentialsGooglePlayStoreSync = (playStoreId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_SYNC,
    payload: { playStoreId: playStoreId },
});
export const credentialsGooglePlayStoreRemove = (playStoreId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE,
    payload: { playStoreId: playStoreId },
});
export const resetCredentialsGooglePlayStore = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_RESET,
    payload: {},
});

// Keystore
export const credentialsGoogleKeystoreInfo = (keystoreIds: any): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_INFO,
    payload: { keystoreIds: keystoreIds },
});
export const credentialsGoogleKeystoreAdd = (keyName: string, file: FileList, keyPassword: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_ADD,
    payload: { keyName: keyName, file: file, keyPassword: keyPassword },
});
export const credentialsGoogleKeystoreEdit = (keystoreId: string, keyName: string, file: FileList, keyPassword: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_EDIT,
    payload: { keystoreId: keystoreId, keyName: keyName, file: file, keyPassword: keyPassword },
});
export const credentialsGoogleKeystoreUpdatePassword = (keystoreId: string, keyName: string, keyPassword: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD,
    payload: { keystoreId: keystoreId, keyName: keyName, keyPassword: keyPassword },
});
export const credentialsGoogleKeystoreSync = (keystoreId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_SYNC,
    payload: { keystoreId: keystoreId },
});
export const credentialsGoogleKeystoreRemove = (keystoreId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_REMOVE,
    payload: { keystoreId: keystoreId },
});
export const resetCredentialsGoogleKeystore = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_RESET,
    payload: {},
});

// AppStore
export const credentialsAppleAppStoreInfo = (appStoreIds: any): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_INFO,
    payload: { appStoreIds: appStoreIds },
});
export const credentialsAppleAppStoreAdd = (storeName: string, username: string, teamId: string, keyIssuer: string, keyName: string, keyId: string, file: FileList): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_ADD,
    payload: { storeName: storeName, username: username, teamId: teamId, keyIssuer: keyIssuer, keyName: keyName, keyId: keyId, file: file },
});
export const credentialsAppleAppStoreEdit = (appStoreId: string, storeName: string, username: string, teamId: string, keyIssuer: string, keyName: string, keyId: string, file: FileList): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_EDIT,
    payload: { appStoreId: appStoreId, storeName: storeName, username: username, teamId: teamId, keyIssuer: keyIssuer, keyName: keyName, keyId: keyId, file: file },
});
export const credentialsAppleAppStoreSync = (appStoreId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_SYNC,
    payload: { appStoreId: appStoreId },
});
export const credentialsAppleAppStoreRemove = (appStoreId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_REMOVE,
    payload: { appStoreId: appStoreId },
});
export const resetCredentialsAppleAppStore = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_RESET,
    payload: {},
});

// Certificate
export const credentialsAppleCertificateInfo = (certificateIds: any): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_INFO,
    payload: { certificateIds: certificateIds },
});
export const credentialsAppleCertificateAdd = (certName: string, certTag: string, file: FileList, file2: FileList, password: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_ADD,
    payload: { certName: certName, certTag: certTag, file: file, file2: file2, password: password },
});
export const credentialsAppleCertificateEdit = (certificateId: string, certName: string, certTag:string, file: FileList, file2: FileList, password: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_EDIT,
    payload: { certificateId: certificateId, certName: certName, certTag: certTag, file: file, file2: file2, password: password },
});
export const credentialsAppleCertificateSync = (certificateId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_SYNC,
    payload: { certificateId: certificateId },
});
export const credentialsAppleCertificateRemove = (certificateId: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_REMOVE,
    payload: { certificateId: certificateId },
});
export const resetCredentialsAppleCertificate = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_RESET,
    payload: {},
});

// Provision
export const credentialsAppleProvisionInfo = (certificateId: string, provisionIds: any): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_INFO,
    payload: { certificateId: certificateId, provisionIds: provisionIds },
});
export const credentialsAppleProvisionAdd = (certificateId: string, provisionName: string, provisionTag: string, file: FileList): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_ADD,
    payload: { certificateId: certificateId, provisionName: provisionName, provisionTag: provisionTag, file: file },
});
export const credentialsAppleProvisionRemove = (certificateId: string, provisionName: string): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_REMOVE,
    payload: { certificateId: certificateId, provisionName: provisionName },
});
export const resetCredentialsAppleProvision = (): CredentialsAction => ({
    type: CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_RESET,
    payload: {},
});
