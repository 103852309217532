// @flow
import { ServiceActionTypes } from './constants';

type ServiceAction = { type: string, payload: {} | string };

// common success
export const serviceApiResponseSuccess = (actionType: string, data: any): ServiceAction => ({
    type: ServiceActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const serviceApiResponseError = (actionType: string, error: string): ServiceAction => ({
    type: ServiceActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Account
export const serviceInfo = (): ServiceAction => ({
    type: ServiceActionTypes.SERVICES_INFO,
    payload: {},
});

export const serviceAccess = (serviceId: string): ServiceAction => ({
    type: ServiceActionTypes.SERVICES_ACCESS,
    payload: { serviceId: serviceId },
});

export const resetService = (): ServiceAction => ({
    type: ServiceActionTypes.RESET,
    payload: {},
});
