import main from './main.json';
import valuesList from './valuesList.json';
import addValue from './addValue.json';
import updateValue from './updateValue.json';
import removeValue from './removeValue.json';
import importValues from './importValues.json';

const output = Object.assign({}, main, valuesList, addValue, updateValue, removeValue, importValues);

export default output;
