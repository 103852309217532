// @flow
import { all } from 'redux-saga/effects';

import { dashboardSagas } from './dashboard/sagas';
import { servicesSagas } from './services/sagas';
import layoutSaga from './layout/saga';

export default function* rootSaga(): any {
    yield all([
        ...dashboardSagas,
        ...servicesSagas,
        layoutSaga()]);
}
