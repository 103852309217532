// @flow
import { APICore } from '../apiCore';
import services from '../../../constants/services';

// Routes
import { RemoteSettingsAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// remoteSettings
function remoteSettingsValidate(): any {
    return api.post(RemoteSettingsAPIConstants.ValidateURL, {}, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsKeysInfo(params: any): any {
    return api.post(RemoteSettingsAPIConstants.APIKeysInfoURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsAddKey(params: any): any {
    return api.post(RemoteSettingsAPIConstants.APIKeyAddURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsRemoveKey(params: any): any {
    return api.post(RemoteSettingsAPIConstants.APIKeyRemoveURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsSettingsInfo(params: any): any {
    return api.post(RemoteSettingsAPIConstants.SettingsInfoURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsAddSettings(params: any): any {
    return api.post(RemoteSettingsAPIConstants.SettingsAddURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsRemoveSettings(params: any): any {
    return api.post(RemoteSettingsAPIConstants.SettingsRemoveURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsValuesInfo(params: any): any {
    return api.post(RemoteSettingsAPIConstants.ValuesInfoURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsAddValues(params: any): any {
    return api.post(RemoteSettingsAPIConstants.ValuesAddURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsUpdateValues(params: any): any {
    return api.post(RemoteSettingsAPIConstants.ValuesUpdateURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsRemoveValues(params: any): any {
    return api.post(RemoteSettingsAPIConstants.ValuesRemoveURL, params, api.getServiceHeaders(services.RemoteSettings));
}

function remoteSettingsImportValues(params: any): any {
    return api.postWithFile(RemoteSettingsAPIConstants.ValuesImportURL,
        {settingsId: params.settingsId, newValues: params.newValues, missingValues: params.missingValues, existingValues: params.existingValues},
        "file", params.file,
        api.getServiceHeaders(services.RemoteSettings));
}

export { remoteSettingsValidate,
    remoteSettingsKeysInfo, remoteSettingsAddKey, remoteSettingsRemoveKey,
    remoteSettingsSettingsInfo, remoteSettingsAddSettings, remoteSettingsRemoveSettings,
    remoteSettingsValuesInfo, remoteSettingsAddValues, remoteSettingsUpdateValues, remoteSettingsRemoveValues,
    remoteSettingsImportValues,
};
