// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    userUpdate as userUpdateApi,
    userInfo as userInfoApi,
} from '../../../helpers/api/dashboardActions/user';

import { APICore, setAuthorization } from '../../../helpers/api/apiCore';
import { userApiResponseSuccess, userApiResponseError } from './actions';
import { UserActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

const api = new APICore();

function* userUpdate({ payload: { fullname, password, newPassword } }) {
    try {
        const response = yield call(userUpdateApi, {fullname, password, newPassword});

        const data = ProcessData(response);
        api.setLoggedInUser(data);
        setAuthorization(data['refreshToken']);

        yield put(userApiResponseSuccess(UserActionTypes.USER_UPDATE, data));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.USER_UPDATE, ProcessError(error)));
    }
}
function* userInfo({ payload: params }) {
    try {
        const response = yield call(userInfoApi, params);
        yield put(userApiResponseSuccess(UserActionTypes.USER_INFO, ProcessData(response)));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.USER_INFO, ProcessError(error)));
    }
}

export function* watchUserUpdate(): any {
    yield takeEvery(UserActionTypes.USER_UPDATE, userUpdate);
}
export function* watchUserInfo(): any {
    yield takeEvery(UserActionTypes.USER_INFO, userInfo);
}

function* userSage(): any {
    yield all([
        fork(watchUserUpdate),
        fork(watchUserInfo),
    ]);
}

export default userSage;
