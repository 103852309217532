import menu from './menu.json';
import main from './main.json';
import settings from './settings.json';
import flows from './flows';
import builds from './builds';
import publicPages from './public';

const output = Object.assign({}, menu, main, settings, flows, builds, publicPages );

export default output;
