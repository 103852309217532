// @flow
import { UserActionTypes } from './constants';
import config from '../../../config';

const INIT_STATE = {
    loading: false,
};

type UserAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const User = (state: State = INIT_STATE, action: UserAction): any => {
    if(config.DEBUG === true && Object.values(UserActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case UserActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserActionTypes.USER_UPDATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        userUpdated: true,
                    };
                }
                case UserActionTypes.USER_INFO: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        userinfo: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserActionTypes.USER_UPDATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        userUpdated: false,
                    };
                }
                case UserActionTypes.USER_INFO: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        userinfo: null,
                    };
                }
                default:
                    return { ...state };
            }
        case UserActionTypes.USER_UPDATE:
            return { ...state, loading: true, userUpdated: false };
        case UserActionTypes.USER_INFO:
            return { ...state, loading: true, userinfo: null };
        case UserActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userUpdated: false,
                userinfo: null,
            };
        default:
            return { ...state };
    }
};

export default User;
