//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import { LoadServiceComponent } from "./LoadServiceComponent";

import services from "../../../constants/services";

// Routes
import { PackageManagerRoutesConstants } from '../../../constants/urls/routes';

// package manager
const PackageManager = React.lazy(() => import('../../../pages/services/package-manager/index'));
const Keys = React.lazy(() => import('../../../pages/services/package-manager/keys/index'));
const AddKey = React.lazy(() => import('../../../pages/services/package-manager/keys/AddKey'));
const RemoveKey = React.lazy(() => import('../../../pages/services/package-manager/keys/RemoveKey'));
const Packages = React.lazy(() => import('../../../pages/services/package-manager/packages/index'));
const AddPackage = React.lazy(() => import('../../../pages/services/package-manager/packages/AddPackage'));
const EditPackage = React.lazy(() => import('../../../pages/services/package-manager/packages/EditPackage'));
const SyncPackage = React.lazy(() => import('../../../pages/services/package-manager/packages/SyncPackage'));
const RemovePackage = React.lazy(() => import('../../../pages/services/package-manager/packages/RemovePackage'));

const PackageManagerRouter = [
    { path: PackageManagerRoutesConstants.PackageManagerURL, element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={PackageManager} />, },

    { path: PackageManagerRoutesConstants.PackageManagerNPMKeysURL, element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={Keys} />, },
    { path: PackageManagerRoutesConstants.PackageManagerAPIKeysAddURL, element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={AddKey} />, },
    { path: PackageManagerRoutesConstants.PackageManagerAPIKeysRemoveURL, element: <Navigate to={PackageManagerRoutesConstants.PackageManagerNPMKeysURL} />, },
    { path: PackageManagerRoutesConstants.PackageManagerAPIKeysRemoveURL+"/:keyId", element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={RemoveKey} />, },

    { path: PackageManagerRoutesConstants.PackageManagerPackagesURL, element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={Packages} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesAddURL, element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={AddPackage} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesEditURL, element: <Navigate to={PackageManagerRoutesConstants.PackageManagerPackagesURL} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesEditURL+"/:packageId", element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={EditPackage} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesSyncURL, element: <Navigate to={PackageManagerRoutesConstants.PackageManagerPackagesURL} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesSyncURL+"/:packageId", element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={SyncPackage} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesRemoveURL, element: <Navigate to={PackageManagerRoutesConstants.PackageManagerPackagesURL} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesRemoveURL+"/:packageId", element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={RemovePackage} />, },
    { path: PackageManagerRoutesConstants.PackageManagerPackagesRemoveURL+"/:packageId/:packageVersion", element: <LoadServiceComponent serviceId={services["PackageManager"]} selectionLevel={"organization"} component={RemovePackage} />, },
];

export { PackageManagerRouter };
