// @flow
import { UserActionTypes } from './constants';
const md5 = require('md5');

type UserAction = { type: string, payload: {} | string };

// common success
export const userApiResponseSuccess = (actionType: string, data: any): UserAction => ({
    type: UserActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const userApiResponseError = (actionType: string, error: string): UserAction => ({
    type: UserActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Account
export const updateUser = (fullname: string, password: string, newPassword: string): UserAction => ({
    type: UserActionTypes.USER_UPDATE,
    payload: { fullname: fullname, password: (password === null || password === undefined || newPassword === null || newPassword === undefined) ? null : md5(password), newPassword: (password === null || password === undefined || newPassword === null || newPassword === undefined) ? null : md5(newPassword) },
});
export const userInfoByEmail = (email: string): UserAction => ({
    type: UserActionTypes.USER_INFO,
    payload: { email: email },
});
export const userInfoById = (userId: string): UserAction => ({
    type: UserActionTypes.USER_INFO,
    payload: { userId: userId },
});

export const resetUser = (): UserAction => ({
    type: UserActionTypes.RESET,
    payload: {},
});
