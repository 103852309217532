// @flow
import { OrganizationActionTypes } from './constants';
import config from "../../../config";

const INIT_STATE = {
    loading: false,
};

type UserAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Organization = (state: State = INIT_STATE, action: UserAction): any => {
    if(config.DEBUG === true && Object.values(OrganizationActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case OrganizationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrganizationActionTypes.ORGANIZATION_INFO: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        organizationsData: action.payload.data,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_CREATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        organizationCreated: true,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_EDIT: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        organizationEdited: true,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_DELETE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        organizationDeleted: true,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_ADD_USER: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        userCreated: true,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_REMOVE_USER: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        userDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case OrganizationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrganizationActionTypes.ORGANIZATION_INFO: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        organizationsData: [],
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_CREATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        organizationCreated: false,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_EDIT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        organizationEdited: false,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_DELETE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        organizationDeleted: false,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_ADD_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        userCreated: false,
                    };
                }
                case OrganizationActionTypes.ORGANIZATION_REMOVE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        userDeleted: false,
                    };
                }
                default:
                    return { ...state };
            }
        case OrganizationActionTypes.ORGANIZATION_INFO:
            return { ...state, loading: true, organizationsData: [] };
        case OrganizationActionTypes.ORGANIZATION_CREATE:
            return { ...state, loading: true, organizationCreated: false };
        case OrganizationActionTypes.ORGANIZATION_EDIT:
            return { ...state, loading: true, organizationEdited: false };
        case OrganizationActionTypes.ORGANIZATION_DELETE:
            return { ...state, loading: true, organizationDeleted: false };
        case OrganizationActionTypes.ORGANIZATION_ADD_USER:
            return { ...state, loading: true, userCreated: false };
        case OrganizationActionTypes.ORGANIZATION_REMOVE_USER:
            return { ...state, loading: true, userDeleted: false };
        case OrganizationActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                organizationCreated: false,
                organizationDeleted: false,
                userCreated: false,
                organizationEdited: false,
                userDeleted: false,
                organizationsData: [],
            };
        default:
            return { ...state };
    }
};

export default Organization;
