import main from './main.json';
import KeystoreList from './keystoreList.json';
import addKeystore from './addKeystore.json';
import editKeystore from './editKeystore.json';
import passwordsKeystore from './passwordsKeystore.json';
import syncKeystore from './syncKeystore.json';
import removeKeystore from './removeKeystore.json';

const output = Object.assign({}, main, KeystoreList, addKeystore, editKeystore, passwordsKeystore, syncKeystore, removeKeystore);

export default output;
