//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import { LoadServiceComponent } from "./LoadServiceComponent";

import services from "../../../constants/services";

// Routes
import { RemoteSettingsRoutesConstants } from '../../../constants/urls/routes';

// remote settings
const RemoteSettings = React.lazy(() => import('../../../pages/services/remote-settings/index'));
const Keys = React.lazy(() => import('../../../pages/services/remote-settings/keys/index'));
const AddKey = React.lazy(() => import('../../../pages/services/remote-settings/keys/AddKey'));
const RemoveKey = React.lazy(() => import('../../../pages/services/remote-settings/keys/RemoveKey'));
const Settings = React.lazy(() => import('../../../pages/services/remote-settings/settings/index'));
const AddSettings = React.lazy(() => import('../../../pages/services/remote-settings/settings/AddSettings'));
const RemoveSettings = React.lazy(() => import('../../../pages/services/remote-settings/settings/RemoveSettings'));
const Values = React.lazy(() => import('../../../pages/services/remote-settings/values/index'));
const AddValue = React.lazy(() => import('../../../pages/services/remote-settings/values/AddValue'));
const UpdateValue = React.lazy(() => import('../../../pages/services/remote-settings/values/UpdateValue'));
const RemoveValue = React.lazy(() => import('../../../pages/services/remote-settings/values/RemoveValue'));
const ImportValues = React.lazy(() => import('../../../pages/services/remote-settings/values/ImportValues'));

const RemoteSettingsRouter = [
    { path: RemoteSettingsRoutesConstants.RemoteSettingsURL, element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={RemoteSettings} />, },

    { path: RemoteSettingsRoutesConstants.RemoteSettingsAPIKeysURL, element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={Keys} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsAPIKeysAddURL, element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={AddKey} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsAPIKeysRemoveURL, element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsAPIKeysURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsAPIKeysRemoveURL+"/:keyId", element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={RemoveKey} />, },

    { path: RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL, element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={Settings} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsSettingsAddURL, element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={AddSettings} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsSettingsRemoveURL, element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsSettingsRemoveURL+"/:settingsId", element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={RemoveSettings} />, },

    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesURL, element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesURL+"/:settingsId", element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={Values} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesAddURL+"/:settingsId", element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={AddValue} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesUpdateURL, element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesUpdateURL+"/:settingsId", element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesUpdateURL+"/:settingsId/:valueId", element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={UpdateValue} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesRemoveURL, element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesRemoveURL+"/:settingsId", element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesRemoveURL+"/:settingsId/:valueId", element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={RemoveValue} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesImportURL, element: <Navigate to={RemoteSettingsRoutesConstants.RemoteSettingsSettingsURL} />, },
    { path: RemoteSettingsRoutesConstants.RemoteSettingsValuesImportURL+"/:settingsId", element: <LoadServiceComponent serviceId={services["RemoteSettings"]} selectionLevel={"application"} component={ImportValues} />, },
];

export { RemoteSettingsRouter };
