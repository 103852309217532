import main from './main.json';
import dashboard from './dashboard';
import mailer from './mailer';
import remoteSettings from './remote-settings';
import translations from './translations';
import credentials from './credentials';
import packageManager from './package-manager';

const output = Object.assign({}, main, dashboard, mailer, remoteSettings, translations, credentials, packageManager);

export default output;
