import menu from './menu.json';
import main from './main.json';
import git from './git';
import unity from './unity';
import google from './google';
import apple from './apple';

const output = Object.assign({}, menu, main, git, unity, google, apple);

export default output;
