// @flow
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

export function reactConfigureStore(initialState: {}): any {
    const store = configureStore({
       reducer: reducers,
       middleware:  middlewares,
       preloadedState: initialState,
    });
    sagaMiddleware.run(rootSaga);
    return store;
}
