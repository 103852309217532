//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import {LoadComponent} from "../LoadComponent";

// Routes
import { DashboardRoutesConstants } from '../../../constants/urls/routes';

// application
const Applications = React.lazy(() => import('../../../pages/hub/applications'));
const ApplicationCreate = React.lazy(() => import('../../../pages/hub/applications/CreateApplication'));
const ApplicationEdit = React.lazy(() => import('../../../pages/hub/applications/EditApplication'));
const ApplicationDelete = React.lazy(() => import('../../../pages/hub/applications/DeleteApplication'));

const ApplicationRouter = [
    { path: DashboardRoutesConstants.ApplicationsURL, element: <LoadComponent component={Applications} />, },

    { path: DashboardRoutesConstants.CreateApplicationURL, element: <LoadComponent component={ApplicationCreate} />, },
    { path: DashboardRoutesConstants.EditApplicationURL, element: <Navigate to={DashboardRoutesConstants.ApplicationsURL} />, },
    { path: DashboardRoutesConstants.EditApplicationURL+"/:applicationId", element: <LoadComponent component={ApplicationEdit} />, },
    { path: DashboardRoutesConstants.DeleteApplicationURL, element: <Navigate to={DashboardRoutesConstants.ApplicationsURL} />, },
    { path: DashboardRoutesConstants.DeleteApplicationURL+"/:applicationId", element: <LoadComponent component={ApplicationDelete} />, },
];

export { ApplicationRouter };
