// @flow
import { APICore } from '../apiCore';

// Routes
import { HubAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// account
function login(params: any): any {
    return api.post(HubAPIConstants.LoginURL, params);
}

function logout(): any {
    return api.post(HubAPIConstants.LogoutURL, {});
}

function signup(params: any): any {
    return api.post(HubAPIConstants.RegisterURL, params);
}

function validateUserEmail(params: any): any {
    return api.post(HubAPIConstants.ValidateEmailURL, params);
}

function forgotPassword(params: any): any {
    return api.post(HubAPIConstants.ForgetPasswordURL, params);
}

function forgotPasswordConfirm(params: any): any {
    return api.post(HubAPIConstants.ResetPasswordURL, params);
}

function verify(): any {
    return api.post(HubAPIConstants.VerifyURL, {});
}

export { login, logout, signup, validateUserEmail, forgotPassword, forgotPasswordConfirm, verify };
