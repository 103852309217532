// @flow
import { RemoteSettingsActionTypes } from './constants';
import config from '../../../config';
import { APICore } from "../../../helpers/api/apiCore";
import services from "../../../constants/services";

const INIT_STATE = {
    loading: false,
    Key: {
        loading: false,
    },
    Settings: {
        loading: false,
    },
    Values: {
        loading: false,
    },
};

type RemoteSettingsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const RemoteSettings = (state: State = INIT_STATE, action: RemoteSettingsAction): any => {
    if(config.DEBUG === true && Object.values(RemoteSettingsActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case RemoteSettingsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALIDATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        validated: true,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keysData = action.payload.data;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyAdded = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyRemoved = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_INFO: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = false;
                    settingsState.settingsData = action.payload.data;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_ADD: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = false;
                    settingsState.settingsAdded = true;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_REMOVE: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = false;
                    settingsState.settingsRemoved = true;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_INFO: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valuesData = action.payload.data;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_ADD: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueAdded = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_UPDATE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueUpdated = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_REMOVE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueRemoved = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_IMPORT: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueImported = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                default:
                    return { ...state };
            }

        case RemoteSettingsActionTypes.API_RESPONSE_ERROR:
            if(action.payload.error.includes("RSE-E-UV-AUW-"))
            {
                const api = new APICore();
                api.setServiceAccess(services.RemoteSettings, null);
            }
            switch (action.payload.actionType) {
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALIDATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        validated: false,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keysData = [];
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyAdded = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyRemoved = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_INFO: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = action.payload.error;
                    settingsState.settingsData = [];
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_ADD: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = action.payload.error;
                    settingsState.settingsAdded = false;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_REMOVE: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = action.payload.error;
                    settingsState.settingsRemoved = false;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_INFO: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valuesData = [];
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_ADD: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueAdded = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_UPDATE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueUpdated = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_REMOVE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueRemoved = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_IMPORT: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueImported = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                default:
                    return { ...state };
            }
        case RemoteSettingsActionTypes.REMOTESETTINGS_VALIDATE:
            return { ...state, loading: true, validated: false };
        case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_INFO: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keysData = null;
            return {...state, Key: keyState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_ADD: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyAdded = false;
            return {...state, Key: keyState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_REMOVE:{
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyRemoved = false;
            return {...state, Key: keyState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_INFO: {
            const settingsState = state.Settings ?? {};
            settingsState.loading = true;
            settingsState.settingsData = null;
            return {...state, Settings: settingsState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_ADD: {
            const settingsState = state.Settings ?? {};
            settingsState.loading = true;
            settingsState.settingsAdded = false;
            return {...state, Settings: settingsState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_REMOVE:{
            const settingsState = state.Settings ?? {};
            settingsState.loading = true;
            settingsState.settingsRemoved = false;
            return {...state, Settings: settingsState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_INFO: {
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valuesData = null;
            return {...state, Values: valuesState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_ADD: {
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueAdded = false;
            return {...state, Values: valuesState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_UPDATE: {
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueUpdated = false;
            return {...state, Values: valuesState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_REMOVE:{
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueRemoved = false;
            return {...state, Values: valuesState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_IMPORT:{
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueImported = false;
            return {...state, Values: valuesState};
        }
        case RemoteSettingsActionTypes.REMOTESETTINGS_KEY_RESET:
            return {
                ...state,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
            };
        case RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_RESET:
            return {
                ...state,
                Settings: {
                    loading: false,
                    error: false,
                    settingsData: null,
                    settingsAdded: false,
                    settingsRemoved: false,
                },
            };
        case RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_RESET:
            return {
                ...state,
                Values: {
                    loading: false,
                    error: false,
                    valuesData: null,
                    valueAdded: false,
                    valueUpdated: false,
                    valueRemoved: false,
                    valueImported: false,
                },
            };
        case RemoteSettingsActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                validated: false,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
                Settings: {
                    loading: false,
                    error: false,
                    settingsData: null,
                    settingsAdded: false,
                    settingsRemoved: false,
                },
                Values: {
                    loading: false,
                    error: false,
                    valuesData: null,
                    valueAdded: false,
                    valueUpdated: false,
                    valueRemoved: false,
                    valueImported: false,
                },
            };
        default:
            return { ...state };
    }
};

export default RemoteSettings;
