import main from './main.json';
import packagesList from './packagesList.json';
import addPackage from './addPackage.json';
import editPackage from './editPackage.json';
import syncPackage from './syncPackage.json';
import removePackage from './removePackage.json';

const output = Object.assign({}, main, packagesList, addPackage, editPackage, syncPackage, removePackage);

export default output;
