import config from '../../../config';

const BaseURL = config.MAILER_URL +"/";
const MailerAPIConstants = {
    ValidateURL: BaseURL + "validate",

    APIKeysInfoURL: BaseURL + "keys",
    APIKeyAddURL: BaseURL + "keys/add",
    APIKeyRemoveURL: BaseURL + "keys/remove",

    TokensInfoURL: BaseURL + "tokens",
    TokenAddURL: BaseURL + "tokens/add",
    TokenRemoveURL: BaseURL + "tokens/remove",

    SendURL: BaseURL + "email",
};

export default MailerAPIConstants;
