// @flow
import { OrganizationActionTypes } from './constants';

type OrganizationAction = { type: string, payload: {} | string };

// common success
export const organizationApiResponseSuccess = (actionType: string, data: any): OrganizationAction => ({
    type: OrganizationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const organizationApiResponseError = (actionType: string, error: string): OrganizationAction => ({
    type: OrganizationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Organization
export const organizationInfo = (organizations: Array): OrganizationAction => ({
    type: OrganizationActionTypes.ORGANIZATION_INFO,
    payload: { organizations: organizations },
});
export const createOrganization = (organizationName: string): OrganizationAction => ({
    type: OrganizationActionTypes.ORGANIZATION_CREATE,
    payload: { organizationName: organizationName },
});
export const editOrganization = (organizationId: string, organizationName: string): OrganizationAction => ({
    type: OrganizationActionTypes.ORGANIZATION_EDIT,
    payload: { organizationId: organizationId, organizationName: organizationName },
});
export const deleteOrganization = (organizationId: string): OrganizationAction => ({
    type: OrganizationActionTypes.ORGANIZATION_DELETE,
    payload: { organizationId: organizationId },
});

// Users
export const addUserToOrganization = (organizationId: string, email: string): OrganizationAction => ({
    type: OrganizationActionTypes.ORGANIZATION_ADD_USER,
    payload: { organizationId: organizationId, email: email },
});
export const removeUserFromOrganization = (organizationId: string, userId: string): OrganizationAction => ({
    type: OrganizationActionTypes.ORGANIZATION_REMOVE_USER,
    payload: { organizationId: organizationId, userId: userId },
});

export const resetOrganization = (): OrganizationAction => ({
    type: OrganizationActionTypes.RESET,
    payload: {},
});
