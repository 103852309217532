//@flow
import React from 'react';

import {LoadComponent} from "../LoadComponent";

// Routes
import { DashboardRoutesConstants} from '../../../constants/urls/routes';

// dashboard
const Dashboard = React.lazy(() => import('../../../pages/hub/dashboard'));
const Account = React.lazy(() => import('../../../pages/hub/dashboard/Account'));

const DashboardRouter = [
    { path: DashboardRoutesConstants.DefaultURL, element: <LoadComponent component={Dashboard} />, },
    { path: DashboardRoutesConstants.AccountURL, element: <LoadComponent component={Account} />, },
];

export { DashboardRouter };
