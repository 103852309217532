import BasePath from "./Base";
const TranslationsRoutes = {
    // Translations
    TranslationsURL: BasePath + "translations",

    // + API Keys
    TranslationsAPIKeysURL: BasePath + "translations/apiKeys",
    TranslationsAPIKeysAddURL: BasePath + "translations/apiKeys/add",
    TranslationsAPIKeysRemoveURL: BasePath + "translations/apiKeys/remove",

    // + Languages
    TranslationsLanguagesURL: BasePath + "translations/languages",
    TranslationsLanguagesAddURL: BasePath + "translations/languages/add",
    TranslationsLanguagesRemoveURL: BasePath + "translations/languages/remove",
    TranslationsLanguagesDefaultURL: BasePath + "translations/languages/default",
    TranslationsLanguagesSyncURL: BasePath + "translations/languages/sync",

    // + Values
    TranslationsValuesURL: BasePath + "translations/values",
    TranslationsValuesAddURL: BasePath + "translations/values/add",
    TranslationsValuesUpdateURL: BasePath + "translations/values/update",
    TranslationsValuesRemoveURL: BasePath + "translations/values/remove",
    TranslationsValuesImportURL: BasePath + "translations/values/import",
};

export default TranslationsRoutes;
