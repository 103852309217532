// @flow
import { MailerActionTypes } from './constants';

type MailerAction = { type: string, payload: {} | string };

// common success
export const mailerApiResponseSuccess = (actionType: string, data: any): MailerAction => ({
    type: MailerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const mailerApiResponseError = (actionType: string, error: string): MailerAction => ({
    type: MailerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Mailer
export const mailerValidate = (): MailerAction => ({
    type: MailerActionTypes.MAILER_VALIDATE,
    payload: {},
});

export const resetMailer = (): MailerAction => ({
    type: MailerActionTypes.RESET,
    payload: {},
});

// Keys
export const mailerKeyInfo = (keys: any): MailerAction => ({
    type: MailerActionTypes.MAILER_KEY_INFO,
    payload: { keysIds: keys },
});
export const mailerAddKey = (keyName: string): MailerAction => ({
    type: MailerActionTypes.MAILER_KEY_ADD,
    payload: { keyName: keyName },
});
export const mailerRemoveKey = (keyId: string): MailerAction => ({
    type: MailerActionTypes.MAILER_KEY_REMOVE,
    payload: { keyId: keyId },
});
export const resetMailerKey = (): MailerAction => ({
    type: MailerActionTypes.MAILER_KEY_RESET,
    payload: {},
});

// Tokens
export const mailerTokenInfo = (tokens: any): MailerAction => ({
    type: MailerActionTypes.MAILER_TOKEN_INFO,
    payload: { tokens: tokens },
});
export const mailerAddToken = (tokenName: string, tokenProvider: string, from:string, token: string, isGlobal: string): MailerAction => ({
    type: MailerActionTypes.MAILER_TOKEN_ADD,
    payload: { tokenName: tokenName, tokenProvider: tokenProvider, from: from, token: token, isGlobal: isGlobal },
});
export const mailerRemoveToken = (tokenId: string): MailerAction => ({
    type: MailerActionTypes.MAILER_TOKEN_REMOVE,
    payload: { tokenId: tokenId },
});
export const resetMailerToken = (): MailerAction => ({
    type: MailerActionTypes.MAILER_TOKEN_RESET,
    payload: {},
});

// Email
export const mailerMailSend = (token: string, to: string, subject: string, message: string): MailerAction => ({
    type: MailerActionTypes.MAILER_MAIL_SEND,
    payload: { token: token, to: to, subject: subject, message: message },
});
export const resetMailerMail = (): MailerAction => ({
    type: MailerActionTypes.MAILER_MAIL_RESET,
    payload: {},
});
