// @flow
import { AuthActionTypes } from './constants';
const md5 = require('md5');

type AuthAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (actionType: string, data: any): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Auth
export const loginUser = (email: string, password: string): AuthAction => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email: email, password: md5(password) },
});

export const logoutUser = (): AuthAction => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (fullname: string, email: string, password: string): AuthAction => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { fullname: fullname, email: email, password: md5(password) },
});

export const validateUserEmail = (token: string): AuthAction => ({
    type: AuthActionTypes.VALIDATE_USER_EMAIL,
    payload: { token },
});

export const forgotPassword = (email: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { email },
});

export const forgotPasswordChange = (token: string, password: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD_CHANGE,
    payload: { token: token, password: (password === null || password === undefined) ? null : md5(password) },
});

export const verifyUser = (): AuthAction => ({
    type: AuthActionTypes.VERIFY_USER,
    payload: {},
});

export const resetAuth = (): AuthAction => ({
    type: AuthActionTypes.RESET,
    payload: {},
});
