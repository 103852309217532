//@flow
import React from 'react';

import {LoadComponent} from "./LoadComponent";

// Routes
import { ErrorsRoutesConstants } from '../../constants/urls/routes';

// errors
const ErrorPageNotFound = React.lazy(() => import('../../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../../pages/error/ServerError'));

const ErrorRouter = [
    { path: ErrorsRoutesConstants.Error500URL, element: <LoadComponent component={ServerError} />},
    { path: '*', element: <LoadComponent component={ErrorPageNotFound} />},
];

export { ErrorRouter, ServerError };
