//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import { LoadServiceComponent } from "./LoadServiceComponent";

import services from "../../../constants/services";

// Routes
import { MailerRoutesConstants } from '../../../constants/urls/routes';

// mailer
const Mailer = React.lazy(() => import('../../../pages/services/mailer/index'));
const MailerTokens = React.lazy(() => import('../../../pages/services/mailer/tokens/index'));
const AddToken = React.lazy(() => import('../../../pages/services/mailer/tokens/AddToken'));
const RemoveToken = React.lazy(() => import('../../../pages/services/mailer/tokens/RemoveToken'));
const MailerKeys = React.lazy(() => import('../../../pages/services/mailer/keys/index'));
const AddKey = React.lazy(() => import('../../../pages/services/mailer/keys/AddKey'));
const RemoveKey = React.lazy(() => import('../../../pages/services/mailer/keys/RemoveKey'));
const SendEmail = React.lazy(() => import('../../../pages/services/mailer/SendEmail'));

const MailerRouter = [
    { path: MailerRoutesConstants.MailerURL, element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"organization"} component={Mailer} />, },

    { path: MailerRoutesConstants.MailerTokensURL, element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"organization"} component={MailerTokens} />, },
    { path: MailerRoutesConstants.MailerTokensAddURL, element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"organization"} component={AddToken} />, },
    { path: MailerRoutesConstants.MailerTokensRemoveURL, element: <Navigate to={MailerRoutesConstants.MailerTokensURL} />, },
    { path: MailerRoutesConstants.MailerTokensRemoveURL+"/:tokenId", element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"organization"} component={RemoveToken} />, },

    { path: MailerRoutesConstants.MailerAPIKeysURL, element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"application"} component={MailerKeys} />, },
    { path: MailerRoutesConstants.MailerAPIKeysAddURL, element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"application"} component={AddKey} />, },
    { path: MailerRoutesConstants.MailerAPIKeysRemoveURL, element: <Navigate to={MailerRoutesConstants.MailerAPIKeysURL} />, },
    { path: MailerRoutesConstants.MailerAPIKeysRemoveURL+"/:keyId", element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"application"} component={RemoveKey} />, },

    { path: MailerRoutesConstants.MailerSendEmailURL, element: <LoadServiceComponent serviceId={services["Mailer"]} selectionLevel={"application"} component={SendEmail} />, },
];

export { MailerRouter };
