// @flow
import mailerSega from './mailer/saga';
import remoteSettingsSega from './remote-settings/saga';
import translationsSega from './translations/saga';
import credentialsSega from './credentials/saga';
import manageCredentialsSega from './credentials/manage/saga';
import packageManagerSega from './package-manager/saga';
import buildManagerSega from './build-manager/saga';

const servicesSagas = [mailerSega(), remoteSettingsSega(), translationsSega(), credentialsSega(), manageCredentialsSega(), packageManagerSega(), buildManagerSega()];

export { servicesSagas };
