// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    credentialsValidate as credentialsValidateApi,

    credentialsGitInfo as credentialsGitInfoApi,
    credentialsAddGit as credentialsAddGitApi,
    credentialsEditGit as credentialsEditGitApi,
    credentialsSyncGit as credentialsSyncGitApi,
    credentialsRemoveGit as credentialsRemoveGitApi,

    credentialsUnityInfo as credentialsUnityInfoApi,
    credentialsAddUnity as credentialsAddUnityApi,
    credentialsEditUnity as credentialsEditUnityApi,
    credentialsRemoveUnity as credentialsRemoveUnityApi,

    credentialsGooglePlayStoreInfo as credentialsGooglePlayStoreInfoApi,
    credentialsGooglePlayStoreAdd as credentialsGooglePlayStoreAddApi,
    credentialsGooglePlayStoreEdit as credentialsGooglePlayStoreEditApi,
    credentialsGooglePlayStoreSync as credentialsGooglePlayStoreSyncApi,
    credentialsGooglePlayStoreRemove as credentialsGooglePlayStoreRemoveApi,

    credentialsGoogleKeystoreInfo as credentialsGoogleKeystoreInfoApi,
    credentialsGoogleKeystoreAdd as credentialsGoogleKeystoreAddApi,
    credentialsGoogleKeystoreEdit as credentialsGoogleKeystoreEditApi,
    credentialsGoogleKeystoreUpdatePassword as credentialsGoogleKeystoreUpdatePasswordApi,
    credentialsGoogleKeystoreSync as credentialsGoogleKeystoreSyncApi,
    credentialsGoogleKeystoreRemove as credentialsGoogleKeystoreRemoveApi,

    credentialsAppleAppStoreInfo as credentialsAppleAppStoreInfoApi,
    credentialsAppleAppStoreAdd as credentialsAppleAppStoreAddApi,
    credentialsAppleAppStoreEdit as credentialsAppleAppStoreEditApi,
    credentialsAppleAppStoreSync as credentialsAppleAppStoreSyncApi,
    credentialsAppleAppStoreRemove as credentialsAppleAppStoreRemoveApi,

    credentialsAppleCertificateInfo as credentialsAppleCertificateInfoApi,
    credentialsAppleCertificateAdd as credentialsAppleCertificateAddApi,
    credentialsAppleCertificateEdit as credentialsAppleCertificateEditApi,
    credentialsAppleCertificateSync as credentialsAppleCertificateSyncApi,
    credentialsAppleCertificateRemove as credentialsAppleCertificateRemoveApi,

    credentialsAppleProvisionInfo as credentialsAppleProvisionInfoApi,
    credentialsAppleProvisionAdd as credentialsAppleProvisionAddApi,
    credentialsAppleProvisionRemove as credentialsAppleProvisionRemoveApi,
} from '../../../helpers/api/servicesActions/credentials';

import { credentialsApiResponseSuccess, credentialsApiResponseError } from './actions';
import { CredentialsActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* credentialsValidate() {
    try {
        const response = yield call(credentialsValidateApi);
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_VALIDATE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_VALIDATE, ProcessError(error)));
    }
}
function* credentialsGitInfo({ payload: { gitIds } }) {
    try {
        const response = yield call(credentialsGitInfoApi, { gitIds });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GIT_INFO, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GIT_INFO, ProcessError(error)));
    }
}
function* credentialsAddGit({ payload: { gitName, gitType, gitUrl, gitIdentifier, gitToken } }) {
    try {
        const response = yield call(credentialsAddGitApi, { gitName, gitType, gitUrl, gitIdentifier, gitToken });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GIT_ADD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GIT_ADD, ProcessError(error)));
    }
}
function* credentialsEditGit({ payload: { gitId, gitName, gitType, gitUrl, gitIdentifier, gitToken } }) {
    try {
        const response = yield call(credentialsEditGitApi, { gitId, gitName, gitType, gitUrl, gitIdentifier, gitToken });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GIT_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GIT_EDIT, ProcessError(error)));
    }
}
function* credentialsSyncGit({ payload: { gitId } }) {
    try {
        const response = yield call(credentialsSyncGitApi, { gitId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GIT_SYNC, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GIT_SYNC, ProcessError(error)));
    }
}
function* credentialsRemoveGit({ payload: { gitId } }) {
    try {
        const response = yield call(credentialsRemoveGitApi, { gitId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GIT_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GIT_REMOVE, ProcessError(error)));
    }
}
function* credentialsUnityInfo({ payload: { unityIds } }) {
    try {
        const response = yield call(credentialsUnityInfoApi, { unityIds });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_UNITY_INFO, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_UNITY_INFO, ProcessError(error)));
    }
}
function* credentialsAddUnity({ payload: { unityName, unityUsername, unityPassword } }) {
    try {
        const response = yield call(credentialsAddUnityApi, { unityName, unityUsername, unityPassword });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_UNITY_ADD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_UNITY_ADD, ProcessError(error)));
    }
}
function* credentialsEditUnity({ payload: { unityId, unityName, unityUsername, unityPassword } }) {
    try {
        const response = yield call(credentialsEditUnityApi, { unityId, unityName, unityUsername, unityPassword });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_UNITY_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_UNITY_EDIT, ProcessError(error)));
    }
}
function* credentialsRemoveUnity({ payload: { unityId } }) {
    try {
        const response = yield call(credentialsRemoveUnityApi, { unityId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_UNITY_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_UNITY_REMOVE, ProcessError(error)));
    }
}
function* credentialsGooglePlayStoreInfo({ payload: { playStoreIds } }) {
    try {
        const response = yield call(credentialsGooglePlayStoreInfoApi, { playStoreIds });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_INFO, ProcessError(error)));
    }
}
function* credentialsGooglePlayStoreAdd({ payload: { keyName, file } }) {
    try {
        const response = yield call(credentialsGooglePlayStoreAddApi, { keyName, file });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_ADD, ProcessError(error)));
    }
}
function* credentialsGooglePlayStoreEdit({ payload: { playStoreId, keyName, file } }) {
    try {
        const response = yield call(credentialsGooglePlayStoreEditApi, { playStoreId, keyName, file });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_EDIT, ProcessError(error)));
    }
}
function* credentialsGooglePlayStoreSync({ payload: { playStoreId } }) {
    try {
        const response = yield call(credentialsGooglePlayStoreSyncApi, { playStoreId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_SYNC, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_SYNC, ProcessError(error)));
    }
}
function* credentialsGooglePlayStoreRemove({ payload: { playStoreId } }) {
    try {
        const response = yield call(credentialsGooglePlayStoreRemoveApi, { playStoreId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE, ProcessError(error)));
    }
}
function* credentialsGoogleKeystoreInfo({ payload: { keystoreIds } }) {
    try {
        const response = yield call(credentialsGoogleKeystoreInfoApi, { keystoreIds });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_INFO, ProcessError(error)));
    }
}
function* credentialsGoogleKeystoreAdd({ payload: { keyName, file, keyPassword } }) {
    try {
        const response = yield call(credentialsGoogleKeystoreAddApi, { keyName, file, keyPassword });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_ADD, ProcessError(error)));
    }
}
function* credentialsGoogleKeystoreEdit({ payload: { keystoreId, keyName, file, keyPassword } }) {
    try {
        const response = yield call(credentialsGoogleKeystoreEditApi, { keystoreId, keyName, file, keyPassword });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_EDIT, ProcessError(error)));
    }
}
function* credentialsGoogleKeystoreUpdatePassword({ payload: { keystoreId, keyName, keyPassword } }) {
    try {
        const response = yield call(credentialsGoogleKeystoreUpdatePasswordApi, { keystoreId, keyName, keyPassword });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD, ProcessError(error)));
    }
}
function* credentialsGoogleKeystoreSync({ payload: { keystoreId } }) {
    try {
        const response = yield call(credentialsGoogleKeystoreSyncApi, { keystoreId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_SYNC, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_SYNC, ProcessError(error)));
    }
}
function* credentialsGoogleKeystoreRemove({ payload: { keystoreId } }) {
    try {
        const response = yield call(credentialsGoogleKeystoreRemoveApi, { keystoreId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_REMOVE, ProcessError(error)));
    }
}
function* credentialsAppleAppStoreInfo({ payload: { appStoreIds } }) {
    try {
        const response = yield call(credentialsAppleAppStoreInfoApi, { appStoreIds });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_INFO, ProcessError(error)));
    }
}
function* credentialsAppleAppStoreAdd({ payload: { storeName, username, teamId, keyIssuer, keyName, keyId, file } }) {
    try {
        const response = yield call(credentialsAppleAppStoreAddApi, { storeName, username, teamId, keyIssuer, keyName, keyId, file });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_ADD, ProcessError(error)));
    }
}
function* credentialsAppleAppStoreEdit({ payload: { appStoreId, storeName, username, teamId, keyIssuer, keyName, keyId, file } }) {
    try {
        const response = yield call(credentialsAppleAppStoreEditApi, { appStoreId, storeName, username, teamId, keyIssuer, keyName, keyId, file });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_EDIT, ProcessError(error)));
    }
}
function* credentialsAppleAppStoreSync({ payload: { appStoreId } }) {
    try {
        const response = yield call(credentialsAppleAppStoreSyncApi, { appStoreId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_SYNC, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_SYNC, ProcessError(error)));
    }
}
function* credentialsAppleAppStoreRemove({ payload: { appStoreId } }) {
    try {
        const response = yield call(credentialsAppleAppStoreRemoveApi, { appStoreId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_REMOVE, ProcessError(error)));
    }
}
function* credentialsAppleCertificateInfo({ payload: { certificateIds } }) {
    try {
        const response = yield call(credentialsAppleCertificateInfoApi, { certificateIds });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_INFO, ProcessError(error)));
    }
}
function* credentialsAppleCertificateAdd({ payload: { certName, certTag, file, file2, password } }) {
    try {
        const response = yield call(credentialsAppleCertificateAddApi, { certName, certTag, file, file2, password });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_ADD, ProcessError(error)));
    }
}
function* credentialsAppleCertificateEdit({ payload: { certificateId, certName, certTag, file, file2, password } }) {
    try {
        const response = yield call(credentialsAppleCertificateEditApi, { certificateId, certName, certTag, file, file2, password });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_EDIT, ProcessError(error)));
    }
}
function* credentialsAppleCertificateSync({ payload: { certificateId } }) {
    try {
        const response = yield call(credentialsAppleCertificateSyncApi, { certificateId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_SYNC, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_SYNC, ProcessError(error)));
    }
}
function* credentialsAppleCertificateRemove({ payload: { certificateId } }) {
    try {
        const response = yield call(credentialsAppleCertificateRemoveApi, { certificateId });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_REMOVE, ProcessError(error)));
    }
}
function* credentialsAppleProvisionInfo({ payload: { certificateId, provisionIds } }) {
    try {
        const response = yield call(credentialsAppleProvisionInfoApi, { certificateId, provisionIds });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_INFO, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_INFO, ProcessError(error)));
    }
}
function* credentialsAppleProvisionAdd({ payload: { certificateId, provisionName, provisionTag, file } }) {
    try {
        const response = yield call(credentialsAppleProvisionAddApi, { certificateId, provisionName, provisionTag, file });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_ADD, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_ADD, ProcessError(error)));
    }
}
function* credentialsAppleProvisionRemove({ payload: { certificateId, provisionName } }) {
    try {
        const response = yield call(credentialsAppleProvisionRemoveApi, { certificateId, provisionName });
        yield put(credentialsApiResponseSuccess(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(credentialsApiResponseError(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_REMOVE, ProcessError(error)));
    }
}

export function* watchCredentialsValidate(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_VALIDATE, credentialsValidate);
}
export function* watchCredentialsGitInfo(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GIT_INFO, credentialsGitInfo);
}
export function* watchCredentialsAddGit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GIT_ADD, credentialsAddGit);
}
export function* watchCredentialsEditGit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GIT_EDIT, credentialsEditGit);
}
export function* watchCredentialsSyncGit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GIT_SYNC, credentialsSyncGit);
}
export function* watchCredentialsRemoveGit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GIT_REMOVE, credentialsRemoveGit);
}
export function* watchCredentialsUnityInfo(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_UNITY_INFO, credentialsUnityInfo);
}
export function* watchCredentialsAddUnity(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_UNITY_ADD, credentialsAddUnity);
}
export function* watchCredentialsEditUnity(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_UNITY_EDIT, credentialsEditUnity);
}
export function* watchCredentialsRemoveUnity(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_UNITY_REMOVE, credentialsRemoveUnity);
}
export function* watchCredentialsGooglePlayStoreInfo(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_INFO, credentialsGooglePlayStoreInfo);
}
export function* watchCredentialsGooglePlayStoreAdd(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_ADD, credentialsGooglePlayStoreAdd);
}
export function* watchCredentialsGooglePlayStoreEdit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_EDIT, credentialsGooglePlayStoreEdit);
}
export function* watchCredentialsGooglePlayStoreSync(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_SYNC, credentialsGooglePlayStoreSync);
}
export function* watchCredentialsGooglePlayStoreRemove(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE, credentialsGooglePlayStoreRemove);
}
export function* watchCredentialsGoogleKeystoreInfo(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_INFO, credentialsGoogleKeystoreInfo);
}
export function* watchCredentialsGoogleKeystoreAdd(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_ADD, credentialsGoogleKeystoreAdd);
}
export function* watchCredentialsGoogleKeystoreEdit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_EDIT, credentialsGoogleKeystoreEdit);
}
export function* watchCredentialsGoogleKeystoreUpdatePassword(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD, credentialsGoogleKeystoreUpdatePassword);
}
export function* watchCredentialsGoogleKeystoreSync(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_SYNC, credentialsGoogleKeystoreSync);
}
export function* watchCredentialsGoogleKeystoreRemove(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_GOOGLE_KEYSTORE_REMOVE, credentialsGoogleKeystoreRemove);
}
export function* watchCredentialsAppleAppStoreInfo(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_INFO, credentialsAppleAppStoreInfo);
}
export function* watchCredentialsAppleAppStoreAdd(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_ADD, credentialsAppleAppStoreAdd);
}
export function* watchCredentialsAppleAppStoreEdit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_EDIT, credentialsAppleAppStoreEdit);
}
export function* watchCredentialsAppleAppStoreSync(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_SYNC, credentialsAppleAppStoreSync);
}
export function* watchCredentialsAppleAppStoreRemove(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_APPSTORE_REMOVE, credentialsAppleAppStoreRemove);
}
export function* watchCredentialsAppleCertificateInfo(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_INFO, credentialsAppleCertificateInfo);
}
export function* watchCredentialsAppleCertificateAdd(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_ADD, credentialsAppleCertificateAdd);
}
export function* watchCredentialsAppleCertificateEdit(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_EDIT, credentialsAppleCertificateEdit);
}
export function* watchCredentialsAppleCertificateSync(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_SYNC, credentialsAppleCertificateSync);
}
export function* watchCredentialsAppleCertificateRemove(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_CERTIFICATE_REMOVE, credentialsAppleCertificateRemove);
}
export function* watchCredentialsAppleProvisionInfo(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_INFO, credentialsAppleProvisionInfo);
}
export function* watchCredentialsAppleProvisionAdd(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_ADD, credentialsAppleProvisionAdd);
}
export function* watchCredentialsAppleProvisionRemove(): any {
    yield takeEvery(CredentialsActionTypes.CREDENTIALS_APPLE_PROVISION_REMOVE, credentialsAppleProvisionRemove);
}

function* credentialsSage(): any {
    yield all([
        fork(watchCredentialsValidate),

        fork(watchCredentialsGitInfo),
        fork(watchCredentialsAddGit),
        fork(watchCredentialsEditGit),
        fork(watchCredentialsSyncGit),
        fork(watchCredentialsRemoveGit),

        fork(watchCredentialsUnityInfo),
        fork(watchCredentialsAddUnity),
        fork(watchCredentialsEditUnity),
        fork(watchCredentialsRemoveUnity),

        fork(watchCredentialsGooglePlayStoreInfo),
        fork(watchCredentialsGooglePlayStoreAdd),
        fork(watchCredentialsGooglePlayStoreEdit),
        fork(watchCredentialsGooglePlayStoreSync),
        fork(watchCredentialsGooglePlayStoreRemove),

        fork(watchCredentialsGoogleKeystoreInfo),
        fork(watchCredentialsGoogleKeystoreAdd),
        fork(watchCredentialsGoogleKeystoreEdit),
        fork(watchCredentialsGoogleKeystoreUpdatePassword),
        fork(watchCredentialsGoogleKeystoreSync),
        fork(watchCredentialsGoogleKeystoreRemove),

        fork(watchCredentialsAppleAppStoreInfo),
        fork(watchCredentialsAppleAppStoreAdd),
        fork(watchCredentialsAppleAppStoreEdit),
        fork(watchCredentialsAppleAppStoreSync),
        fork(watchCredentialsAppleAppStoreRemove),

        fork(watchCredentialsAppleCertificateInfo),
        fork(watchCredentialsAppleCertificateAdd),
        fork(watchCredentialsAppleCertificateEdit),
        fork(watchCredentialsAppleCertificateSync),
        fork(watchCredentialsAppleCertificateRemove),

        fork(watchCredentialsAppleProvisionInfo),
        fork(watchCredentialsAppleProvisionAdd),
        fork(watchCredentialsAppleProvisionRemove),
    ]);
}

export default credentialsSage;
