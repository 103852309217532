//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import { LoadServiceComponent } from "./LoadServiceComponent";

import services from "../../../constants/services";

// Routes
import { TranslationsRoutesConstants } from '../../../constants/urls/routes';

// translations
const Translations = React.lazy(() => import('../../../pages/services/translations/index'));
const Keys = React.lazy(() => import('../../../pages/services/translations/keys/index'));
const AddKey = React.lazy(() => import('../../../pages/services/translations/keys/AddKey'));
const RemoveKey = React.lazy(() => import('../../../pages/services/translations/keys/RemoveKey'));
const Languages = React.lazy(() => import('../../../pages/services/translations/languages/index'));
const AddLanguage = React.lazy(() => import('../../../pages/services/translations/languages/AddLanguage'));
const RemoveLanguage = React.lazy(() => import('../../../pages/services/translations/languages/RemoveLanguage'));
const DefaultLanguage = React.lazy(() => import('../../../pages/services/translations/languages/DefaultLanguage'));
const SyncLanguage = React.lazy(() => import('../../../pages/services/translations/languages/SyncLanguage'));
const Values = React.lazy(() => import('../../../pages/services/translations/values/index'));
const AddValue = React.lazy(() => import('../../../pages/services/translations/values/AddValue'));
const UpdateValue = React.lazy(() => import('../../../pages/services/translations/values/UpdateValue'));
const RemoveValue = React.lazy(() => import('../../../pages/services/translations/values/RemoveValue'));
const ImportValues = React.lazy(() => import('../../../pages/services/translations/values/ImportValues'));

const TranslationsRouter = [
    { path: TranslationsRoutesConstants.TranslationsURL, element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={Translations} />, },

    { path: TranslationsRoutesConstants.TranslationsAPIKeysURL, element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={Keys} />, },
    { path: TranslationsRoutesConstants.TranslationsAPIKeysAddURL, element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={AddKey} />, },
    { path: TranslationsRoutesConstants.TranslationsAPIKeysRemoveURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsAPIKeysURL} />, },
    { path: TranslationsRoutesConstants.TranslationsAPIKeysRemoveURL+"/:keyId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={RemoveKey} />, },

    { path: TranslationsRoutesConstants.TranslationsLanguagesURL, element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={Languages} />, },
    { path: TranslationsRoutesConstants.TranslationsLanguagesAddURL, element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={AddLanguage} />, },
    { path: TranslationsRoutesConstants.TranslationsLanguagesRemoveURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsLanguagesRemoveURL+"/:languageId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={RemoveLanguage} />, },
    { path: TranslationsRoutesConstants.TranslationsLanguagesDefaultURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsLanguagesDefaultURL+"/:languageId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={DefaultLanguage} />, },
    { path: TranslationsRoutesConstants.TranslationsLanguagesSyncURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsLanguagesSyncURL+"/:languageId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={SyncLanguage} />, },

    { path: TranslationsRoutesConstants.TranslationsValuesURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesURL+"/:languageId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={Values} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesAddURL+"/:languageId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={AddValue} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesUpdateURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesUpdateURL+"/:languageId", element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesUpdateURL+"/:languageId/:valueId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={UpdateValue} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesRemoveURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesRemoveURL+"/:languageId", element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesRemoveURL+"/:languageId/:valueId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={RemoveValue} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesImportURL, element: <Navigate to={TranslationsRoutesConstants.TranslationsLanguagesURL} />, },
    { path: TranslationsRoutesConstants.TranslationsValuesImportURL+"/:languageId", element: <LoadServiceComponent serviceId={services["Translations"]} selectionLevel={"application"} component={ImportValues} />, },
];

export { TranslationsRouter };
