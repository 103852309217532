import main from './main.json';
import applicationsList from './applicationsList.json';
import applicationsSelect from './applicationsSelect.json';
import createApplication from './createApplication.json';
import editApplication from './editApplication.json';
import deleteApplication from './deleteApplication.json';

const output = Object.assign({}, main, applicationsList, applicationsSelect, createApplication, editApplication, deleteApplication );

export default output;
