import config from "../config";

const services = {
    "Mailer": config.MAILER_ID,
    "RemoteSettings": config.REMOTESETTINGS_ID,
    "Translations": config.TRANSLATIONS_ID,
    "Credentials": config.CREDENTIALS_ID,
    "PackageManager": config.PACKAGEMANAGER_ID,
    "BuildManager": config.BUILDMANAGER_ID,
};

export default services;
