import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';
import { APICore } from "../../../helpers/api/apiCore";
import config from "../../../config";
import { serviceAccess } from '../../../redux/actions';
import {ServerError} from "../error";
import {LoadComponent, LoadingComponent} from "../LoadComponent";

// Routes
import { DashboardRoutesConstants } from "../../../constants/urls/routes";

type LoadServiceComponentProps = {
    serviceId: string,
    selectionLevel: string,
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadServiceComponent = ({ serviceId, selectionLevel, component: Component }: LoadServiceComponentProps) => {
    const dispatch = useDispatch();

    const { loading, error } = useSelector((state) => {
        return ({
            loading: state.Service.loading,
            error: state.Service.error,
        })
    });

    useEffect(() => {
        if(config.MAINTENANCE === true)
            return;
        if((error !== false && error !== undefined) || loading !== false)
            return;
        const api = new APICore();
        const selection = api.getSelection();
        if(selection === null || selection === undefined || selection.organizationId === null || selection.organizationId === undefined)
            return;
        const access = api.getServiceAccess(serviceId);
        if(access !== null && access !== undefined && access.organizationId === selection.organizationId && access.applicationId === selection.applicationId)
            return;
        dispatch(serviceAccess(serviceId));
    }, [dispatch, serviceId, error, loading]);

    if(error !== false && error !== undefined)
    {
        return (
            <LoadComponent component={ServerError} />
        );
    }

    const api = new APICore();
    if(selectionLevel !== null && selectionLevel !== undefined)
    {
        const selection = api.getSelection();
        if(selection === null || selection === undefined)
        {
            return (<Navigate to={DashboardRoutesConstants.DefaultURL} />);
        }
        if((selectionLevel === "organization" || selectionLevel === "application" ) && (selection.organizationId === null || selection.organizationId === undefined))
        {
            return (<Navigate to={DashboardRoutesConstants.DefaultURL} />);
        }
        if(selectionLevel === "application" && (selection.applicationId === null || selection.applicationId === undefined))
        {
            return (<Navigate to={DashboardRoutesConstants.DefaultURL} />);
        }
    }

    const access = api.getServiceAccess(serviceId);
    if(loading !== false || access === null || access === undefined)
    {
        return (
            <LoadComponent component={LoadingComponent} />
        );
    }

    return (
        <LoadComponent component={Component} />
    );
}

export { LoadServiceComponent };
