import React, { Suspense } from 'react';
import {Row, Col} from "react-bootstrap";
import config from "../../config";

// view
import Spinner from "../../components/Spinner";
const Maintenance = React.lazy(() => import('../../pages/error/Maintenance'));

const LoadingComponent = () => (
    <>
        <Row>
            <Col lg={5}></Col>
            <Col lg={2} style={{textAlign: "center"}}><Spinner className="text-primary m-2" color="primary" size={"lg"} /></Col>
            <Col lg={5}></Col>
        </Row>
    </>
);

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {
    if(config.MAINTENANCE === true)
    {
        return (
            <Suspense fallback={LoadingComponent()}>
                <Maintenance />
            </Suspense>
        );
    }
    return (
        <Suspense fallback={LoadingComponent()}>
            <Component />
        </Suspense>
    );
}

export { LoadComponent, LoadingComponent };
