export const PackageManagerActionTypes = {
    API_RESPONSE_SUCCESS: '@@services/package-manager/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@services/package-manager/API_RESPONSE_ERROR',

    PACKAGEMANAGER_VALIDATE: '@@services/package-manager/VALIDATE',

    PACKAGEMANAGER_KEY_INFO: '@@services/package-manager/KEY_INFO',
    PACKAGEMANAGER_KEY_ADD: '@@services/package-manager/KEY_ADD',
    PACKAGEMANAGER_KEY_REMOVE: '@@services/package-manager/KEY_REMOVE',
    PACKAGEMANAGER_KEY_RESET: '@@services/package-manager/KEY_RESET',

    PACKAGEMANAGER_PACKAGE_INFO: '@@services/package-manager/PACKAGE_INFO',
    PACKAGEMANAGER_PACKAGE_GIT: '@@services/package-manager/PACKAGE_GIT',
    PACKAGEMANAGER_PACKAGE_ADD: '@@services/package-manager/PACKAGE_ADD',
    PACKAGEMANAGER_PACKAGE_EDIT: '@@services/package-manager/PACKAGE_EDIT',
    PACKAGEMANAGER_PACKAGE_SYNC: '@@services/package-manager/PACKAGE_SYNC',
    PACKAGEMANAGER_PACKAGE_REMOVE: '@@services/package-manager/PACKAGE_REMOVE',
    PACKAGEMANAGER_PACKAGE_RESET: '@@services/package-manager/PACKAGE_RESET',

    RESET: '@@services/package-manager/RESET',
};
