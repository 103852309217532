import {Navigate} from 'react-router-dom';

// Routes
import { DashboardRoutesConstants } from '../constants/urls/routes';

const Root = () => {
    return <Navigate to={`${DashboardRoutesConstants.DefaultURL}`} />;
};

export default Root;
