// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    remoteSettingsValidate as remoteSettingsValidateApi,

    remoteSettingsKeysInfo as remoteSettingsKeysInfoApi,
    remoteSettingsAddKey as remoteSettingsAddKeyApi,
    remoteSettingsRemoveKey as remoteSettingsRemoveKeyApi,

    remoteSettingsSettingsInfo as remoteSettingsSettingsInfoApi,
    remoteSettingsAddSettings as remoteSettingsAddSettingsApi,
    remoteSettingsRemoveSettings as remoteSettingsRemoveSettingsApi,

    remoteSettingsValuesInfo as remoteSettingsValuesInfoApi,
    remoteSettingsAddValues as remoteSettingsAddValuesApi,
    remoteSettingsUpdateValues as remoteSettingsUpdateValuesApi,
    remoteSettingsRemoveValues as remoteSettingsRemoveValuesApi,
    remoteSettingsImportValues as remoteSettingsImportValuesApi,
} from '../../../helpers/api/servicesActions/remote-settings';

import { remoteSettingsApiResponseSuccess, remoteSettingsApiResponseError } from './actions';
import { RemoteSettingsActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* remoteSettingsValidate() {
    try {
        const response = yield call(remoteSettingsValidateApi);
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_VALIDATE, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_VALIDATE, ProcessError(error)));
    }
}
function* remoteSettingsKeysInfo({ payload: { keysIds } }) {
    try {
        const response = yield call(remoteSettingsKeysInfoApi, { keysIds });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_INFO, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_INFO, ProcessError(error)));
    }
}
function* remoteSettingsAddKey({ payload: { keyName } }) {
    try {
        const response = yield call(remoteSettingsAddKeyApi, { keyName });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_ADD, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_ADD, ProcessError(error)));
    }
}
function* remoteSettingsRemoveKey({ payload: { keyId } }) {
    try {
        const response = yield call(remoteSettingsRemoveKeyApi, { keyId });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_REMOVE, ProcessError(error)));
    }
}
function* remoteSettingsSettingsInfo({ payload: { settingsIds } }) {
    try {
        const response = yield call(remoteSettingsSettingsInfoApi, { settingsIds });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_INFO, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_INFO, ProcessError(error)));
    }
}
function* remoteSettingsAddSettings({ payload: { settingsName } }) {
    try {
        const response = yield call(remoteSettingsAddSettingsApi, { settingsName });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_ADD, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_ADD, ProcessError(error)));
    }
}
function* remoteSettingsRemoveSettings({ payload: { settingsId } }) {
    try {
        const response = yield call(remoteSettingsRemoveSettingsApi, { settingsId });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_REMOVE, ProcessError(error)));
    }
}
function* remoteSettingsValuesInfo({ payload: { settingsId, valueIds } }) {
    try {
        const response = yield call(remoteSettingsValuesInfoApi, { settingsId, valueIds });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_INFO, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_INFO, ProcessError(error)));
    }
}
function* remoteSettingsAddValues({ payload: { settingsId, key, type, value } }) {
    try {
        const response = yield call(remoteSettingsAddValuesApi, { settingsId, key, type, value });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_ADD, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_ADD, ProcessError(error)));
    }
}
function* remoteSettingsUpdateValues({ payload: { settingsId, key, type, value } }) {
    try {
        const response = yield call(remoteSettingsUpdateValuesApi, { settingsId, key, type, value });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_UPDATE, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_UPDATE, ProcessError(error)));
    }
}
function* remoteSettingsRemoveValues({ payload: { settingsId, key } }) {
    try {
        const response = yield call(remoteSettingsRemoveValuesApi, { settingsId, key });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_REMOVE, ProcessError(error)));
    }
}
function* remoteSettingsImportValues({ payload: { settingsId, file, newValues, missingValues, existingValues } }) {
    try {
        const response = yield call(remoteSettingsImportValuesApi, { settingsId, file, newValues, missingValues, existingValues });
        yield put(remoteSettingsApiResponseSuccess(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_IMPORT, ProcessData(response)));
    } catch (error) {
        yield put(remoteSettingsApiResponseError(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_IMPORT, ProcessError(error)));
    }
}

export function* watchRemoteSettingsValidate(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_VALIDATE, remoteSettingsValidate);
}
export function* watchRemoteSettingsKeysInfo(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_INFO, remoteSettingsKeysInfo);
}
export function* watchRemoteSettingsAddKey(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_ADD, remoteSettingsAddKey);
}
export function* watchRemoteSettingsRemoveKey(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_KEY_REMOVE, remoteSettingsRemoveKey);
}
export function* watchRemoteSettingsSettingsInfo(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_INFO, remoteSettingsSettingsInfo);
}
export function* watchRemoteSettingsAddSettings(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_ADD, remoteSettingsAddSettings);
}
export function* watchRemoteSettingsRemoveSettings(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_REMOVE, remoteSettingsRemoveSettings);
}
export function* watchRemoteSettingsValuesInfo(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_INFO, remoteSettingsValuesInfo);
}
export function* watchRemoteSettingsAddValues(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_ADD, remoteSettingsAddValues);
}
export function* watchRemoteSettingsUpdateValues(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_UPDATE, remoteSettingsUpdateValues);
}
export function* watchRemoteSettingsRemoveValues(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_REMOVE, remoteSettingsRemoveValues);
}
export function* watchRemoteSettingsImportValues(): any {
    yield takeEvery(RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_IMPORT, remoteSettingsImportValues);
}

function* remoteSettingsSage(): any {
    yield all([
        fork(watchRemoteSettingsValidate),
        fork(watchRemoteSettingsKeysInfo),
        fork(watchRemoteSettingsAddKey),
        fork(watchRemoteSettingsRemoveKey),
        fork(watchRemoteSettingsSettingsInfo),
        fork(watchRemoteSettingsAddSettings),
        fork(watchRemoteSettingsRemoveSettings),
        fork(watchRemoteSettingsValuesInfo),
        fork(watchRemoteSettingsAddValues),
        fork(watchRemoteSettingsUpdateValues),
        fork(watchRemoteSettingsRemoveValues),
        fork(watchRemoteSettingsImportValues),
    ]);
}

export default remoteSettingsSage;
