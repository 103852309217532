import BasePath from "./Base";
const BuildManagerRoutes = {
    // BuildManager
    BuildManagerURL: BasePath + "buildManager",

    // + Settings
    BuildManagerSettingsURL: BasePath + "buildManager/settings",

    // + Flow
    BuildManagerFlowsURL: BasePath + "buildManager/flows",
    BuildManagerFlowsAddURL: BasePath + "buildManager/flows/add",
    BuildManagerFlowsEditURL: BasePath + "buildManager/flows/edit",
    BuildManagerFlowsRemoveURL: BasePath + "buildManager/flows/remove",

    // + Build
    BuildManagerBuildsURL: BasePath + "buildManager/builds",
    BuildManagerBuildsStartURL: BasePath + "buildManager/builds/start",
    BuildManagerBuildsUnlockURL: BasePath + "buildManager/builds/unlock",
    BuildManagerBuildsRemoveURL: BasePath + "buildManager/builds/remove",

    // Public

    // + Build
    BuildManagerBuildsPublicURL: BasePath + "builds",
    BuildManagerBuildsUDIDURL: BasePath + "udid",
};

export default BuildManagerRoutes;
