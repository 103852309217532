import main from './main.json';
import playStoreList from './playStoreList.json';
import addPlayStore from './addPlayStore.json';
import editPlayStore from './editPlayStore.json';
import syncPlayStore from './syncPlayStore.json';
import removePlayStore from './removePlayStore.json';

const output = Object.assign({}, main, playStoreList, addPlayStore, editPlayStore, syncPlayStore, removePlayStore);

export default output;
