//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import { LoadServiceComponent } from "./LoadServiceComponent";

import services from "../../../constants/services";

// Routes
import {BuildManagerRoutesConstants } from '../../../constants/urls/routes';
import BasePath from "../../../constants/urls/routes/Base";
import {LoadComponent} from "../LoadComponent";

// build manager
const BuildManager = React.lazy(() => import('../../../pages/services/build-manager/index'));
const Settings = React.lazy(() => import('../../../pages/services/build-manager/Settings'));
const Flows = React.lazy(() => import('../../../pages/services/build-manager/flows/index'));
const AddFlow = React.lazy(() => import('../../../pages/services/build-manager/flows/AddFlow'));
const EditFlow = React.lazy(() => import('../../../pages/services/build-manager/flows/EditFlow'));
const RemoveFlow = React.lazy(() => import('../../../pages/services/build-manager/flows/RemoveFlow'));
const Builds = React.lazy(() => import('../../../pages/services/build-manager/builds/index'));
const BuildStart = React.lazy(() => import('../../../pages/services/build-manager/builds/StartBuild'));
const BuildUnlock = React.lazy(() => import('../../../pages/services/build-manager/builds/UnlockBuild'));
const BuildRemove = React.lazy(() => import('../../../pages/services/build-manager/builds/RemoveBuild'));

const PublicBuilds = React.lazy(() => import('../../../pages/services/build-manager/public'));
// const UDID = React.lazy(() => import('../../../pages/services/build-manager/public/udid'));

const BuildManagerRouter = [
    { path: BuildManagerRoutesConstants.BuildManagerURL, element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={BuildManager} />, },
    { path: BuildManagerRoutesConstants.BuildManagerSettingsURL, element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={Settings} />, },

    { path: BuildManagerRoutesConstants.BuildManagerFlowsURL, element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={Flows} />, },
    { path: BuildManagerRoutesConstants.BuildManagerFlowsAddURL, element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={AddFlow} />, },
    { path: BuildManagerRoutesConstants.BuildManagerFlowsEditURL, element: <Navigate to={BuildManagerRoutesConstants.BuildManagerFlowsURL} />, },
    { path: BuildManagerRoutesConstants.BuildManagerFlowsEditURL+"/:flowId", element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={EditFlow} />, },
    { path: BuildManagerRoutesConstants.BuildManagerFlowsRemoveURL, element: <Navigate to={BuildManagerRoutesConstants.BuildManagerFlowsURL} />, },
    { path: BuildManagerRoutesConstants.BuildManagerFlowsRemoveURL+"/:flowId", element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={RemoveFlow} />, },

    { path: BuildManagerRoutesConstants.BuildManagerBuildsURL, element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={Builds} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsURL+"/:flowId", element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={Builds} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsURL+"/:flowId/:buildId", element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={Builds} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsStartURL, element: <Navigate to={BuildManagerRoutesConstants.BuildManagerBuildsURL} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsStartURL+"/:flowId", element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={BuildStart} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsUnlockURL, element: <Navigate to={BuildManagerRoutesConstants.BuildManagerBuildsURL} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsUnlockURL+"/:buildId", element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={BuildUnlock} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsRemoveURL, element: <Navigate to={BuildManagerRoutesConstants.BuildManagerBuildsURL} />, },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsRemoveURL+"/:buildId", element: <LoadServiceComponent serviceId={services["BuildManager"]} selectionLevel={"application"} component={BuildRemove} />, },
];

const BuildManagerPublicRouter = [
    { path: BuildManagerRoutesConstants.BuildManagerBuildsPublicURL, element: <Navigate to={BasePath} /> },
    { path: BuildManagerRoutesConstants.BuildManagerBuildsPublicURL+"/:buildId", element: <LoadComponent component={PublicBuilds} /> },
    // { path: BuildManagerRoutesConstants.BuildManagerBuildsUDIDURL, element: <LoadComponent component={UDID} /> },
];

export { BuildManagerRouter, BuildManagerPublicRouter };
