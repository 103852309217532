import main from './main.json';
import organizationsList from './organizationsList.json';
import organizationsSelect from './organizationsSelect.json';
import createOrganization from './createOrganization.json';
import editOrganization from './editOrganization.json';
import deleteOrganization from './deleteOrganization.json';
import addUser from './addUser.json';
import removeUser from './removeUser.json';

const output = Object.assign({}, main, organizationsList, organizationsSelect, createOrganization, editOrganization, deleteOrganization, addUser, removeUser);

export default output;
