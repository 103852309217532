// @flow
import { all, fork, call, takeEvery } from 'redux-saga/effects';
import { APICore } from "../../../helpers/api/apiCore";

import { SelectionActionTypes } from './constants';

function setOrganization(organizationId: string)
{
    const api = new APICore();
    const selection = api.getSelection();
    selection.organizationId = organizationId;
    selection.applicationId = null;
    api.setSelection(selection)
    window.location.reload();
    return true;
}

function setApplication(applicationId: string)
{
    const api = new APICore();
    const selection = api.getSelection();
    selection.applicationId = applicationId;
    api.setSelection(selection);
    window.location.reload();
    return true;
}

function* selectOrganization({ payload: { organizationId } }) {
    try {
        yield call(setOrganization, organizationId);
    } catch (error) {}
}
function* selectApplication({ payload: { applicationId } }) {
    try {
        yield call(setApplication, applicationId);
    } catch (error) {}
}

export function* watchSelectOrganization(): any {
    yield takeEvery(SelectionActionTypes.SELECT_ORGANIZATION, selectOrganization);
}
export function* watchSelectApplication(): any {
    yield takeEvery(SelectionActionTypes.SELECT_APPLICATION, selectApplication);
}

function* selectionSage(): any {
    yield all([
        fork(watchSelectOrganization),
        fork(watchSelectApplication),
    ]);
}

export default selectionSage;
