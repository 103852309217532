import jwtDecode from 'jwt-decode';
import axios from 'axios';

import config from '../../config';
import {getLanguage} from "../language";

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.BASE_URL;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        return Promise.reject(error);
    }
);

const AUTH_SESSION_KEY = 'userSession';
const SELECTION_SESSION_KEY = 'selectionSession';
const ACCESS_SESSION_KEY = 'accessSession';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
const getSelectionFromSession = () => {
    const selection = sessionStorage.getItem(SELECTION_SESSION_KEY);
    return selection ? (typeof selection == 'object' ? selection : JSON.parse(selection)) : {};
};
const getAccessFromSession = () => {
    const access = sessionStorage.getItem(ACCESS_SESSION_KEY);
    return access ? (typeof access == 'object' ? access : JSON.parse(access)) : {};
};

const processUrl = (url) =>
{
    let sep = "?";
    if(url.includes("?"))
    {
        sep = "&";
    }
    return url + "" + sep + "lang="+getLanguage();
}

class APICore {
    /**
     * Fetches data from given url
     */
    get = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    post = (url, data, headers = []) => {
        url = processUrl(url);
        return axios.post(url, data, {headers: {
                ...axios.defaults.headers.post,
                ...headers
            }});
    };


    /**
     * post given data to url, with a file
     */
    postWithFile = (url, data, fileKeyName, fileObject, headers = []) => {
        url = processUrl(url);

        const formData = new FormData();
        formData.append(fileKeyName, fileObject);

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return axios.post(url, formData, {
            headers: {
                ...axios.defaults.headers.post,
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    /**
     * post given data to url, with a file
     */
    postWithFiles = (url, data, fileKeyNames, fileObjects, headers = []) => {
        url = processUrl(url);

        const formData = new FormData();
        for (let i = 0; i < fileKeyNames.length; i++)
        {
            formData.append(fileKeyNames[i], fileObjects[i]);
        }

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return axios.post(url, formData, {
            headers: {
                ...axios.defaults.headers.post,
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        url = processUrl(url);
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = (url, data) => {
        url = processUrl(url);
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        url = processUrl(url);
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data, headers = []) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                ...headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data, headers = []) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                ...headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();
        if (!user || (user && !user.refreshToken)) {
            return false;
        }
        const decoded = jwtDecode(user.refreshToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (session) => {
        if (session) {
            sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        }
        else {
            sessionStorage.setItem(AUTH_SESSION_KEY, "{}");
            sessionStorage.removeItem(AUTH_SESSION_KEY);
        }
    };

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromSession();
    };

    setUserInSession = (modifiedUser) => {
        let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };

    /**
     * Returns the active selection
     */
    getSelection = () => {
        return getSelectionFromSession();
    };
    getSelectionOrganizationHeaders = () => {
        const selection = this.getSelection();
        if(selection === null || selection === undefined || selection.organizationId === null || selection.organizationId === undefined)
            return {};
        return {
            "X-Organization": selection.organizationId,
        };
    };
    getSelectionApplicationHeaders = () => {
        const selection = this.getSelection();
        if(selection === null || selection === undefined || selection.applicationId === null || selection.applicationId === undefined)
            return {};
        return {
            "X-Application": selection.applicationId,
        };
    };
    setSelection = (selection) => {
        if (selection) {
            sessionStorage.setItem(SELECTION_SESSION_KEY, JSON.stringify(selection));
        }
        else {
            sessionStorage.setItem(SELECTION_SESSION_KEY, "{}");
            sessionStorage.removeItem(SELECTION_SESSION_KEY);
        }
        this.setAccess(null);
    };
    isSelectionAdmin = () =>
    {
        const selection = this.getSelection();
        let admin = false;
        if(selection !== null && selection !== undefined && selection.organizationId !== null && selection.organizationId !== undefined)
        {
            const userData = this.getLoggedInUser();
            for (const organizationData of userData.Organizations)
            {
                if(organizationData.Id !== selection.organizationId)
                    continue;
                admin = organizationData.Role === "Admin";
                break;
            }
        }
        return admin;
    }

    /**
     * Returns the active selection
     */
    getAccess = () => {
        return getAccessFromSession();
    };
    getServiceAccess = (serviceId) => {
        const access = this.getAccess();
        if(access === null || access === undefined)
            return null;
        return access[serviceId];
    };
    getServiceHeaders = (serviceId) => {
        const access = this.getServiceAccess(serviceId);
        if(access === null || access === undefined)
            return {};
        return {
            Authorization: "JWT " + access.accessToken,
        };
    }
    setAccess = (access) => {
        if (access) {
            sessionStorage.setItem(ACCESS_SESSION_KEY, JSON.stringify(access));
        }
        else {
            sessionStorage.setItem(ACCESS_SESSION_KEY, "{}");
            sessionStorage.removeItem(ACCESS_SESSION_KEY);
        }
    };
    setServiceAccess = (serviceId, accessInfo) => {
        let access = this.getAccess();
        if(access === null || access === undefined)
        {
            access = {};
        }
        access[serviceId] = accessInfo;
        this.setAccess(access);
    };
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
    const { refreshToken } = user;
    if (refreshToken) {
        setAuthorization(refreshToken);
    }
}

export { APICore, setAuthorization };
