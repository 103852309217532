import BasePath from "./Base";
const MailerRoutes = {
    // Mailer
    MailerURL: BasePath + "mailer",

    // + Tokens
    MailerTokensURL: BasePath + "mailer/tokens",
    MailerTokensAddURL: BasePath + "mailer/tokens/add",
    MailerTokensRemoveURL: BasePath + "mailer/tokens/remove",

    // + API Keys
    MailerAPIKeysURL: BasePath + "mailer/apiKeys",
    MailerAPIKeysAddURL: BasePath + "mailer/apiKeys/add",
    MailerAPIKeysRemoveURL: BasePath + "mailer/apiKeys/remove",

    // + Send Emails
    MailerSendEmailURL: BasePath + "mailer/send",
};

export default MailerRoutes;
