import BasePath from "./Base";
const PackageManagerRoutes = {
    // PackageManager
    PackageManagerURL: BasePath + "packageManager",

    // + API Keys
    PackageManagerNPMKeysURL: BasePath + "packageManager/npmKeys",
    PackageManagerAPIKeysAddURL: BasePath + "packageManager/npmKeys/add",
    PackageManagerAPIKeysRemoveURL: BasePath + "packageManager/npmKeys/remove",

    // + Packages
    PackageManagerPackagesURL: BasePath + "packageManager/packages",
    PackageManagerPackagesAddURL: BasePath + "packageManager/packages/add",
    PackageManagerPackagesEditURL: BasePath + "packageManager/packages/edit",
    PackageManagerPackagesSyncURL: BasePath + "packageManager/packages/sync",
    PackageManagerPackagesRemoveURL: BasePath + "packageManager/packages/remove",
};

export default PackageManagerRoutes;
