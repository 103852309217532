export const RemoteSettingsActionTypes = {
    API_RESPONSE_SUCCESS: '@@services/remote-settings/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@services/remote-settings/API_RESPONSE_ERROR',

    REMOTESETTINGS_VALIDATE: '@@services/remote-settings/VALIDATE',

    REMOTESETTINGS_KEY_INFO: '@@services/remote-settings/KEY_INFO',
    REMOTESETTINGS_KEY_ADD: '@@services/remote-settings/KEY_ADD',
    REMOTESETTINGS_KEY_REMOVE: '@@services/remote-settings/KEY_REMOVE',
    REMOTESETTINGS_KEY_RESET: '@@services/remote-settings/KEY_RESET',

    REMOTESETTINGS_SETTINGS_INFO: '@@services/remote-settings/SETTINGS_INFO',
    REMOTESETTINGS_SETTINGS_ADD: '@@services/remote-settings/SETTINGS_ADD',
    REMOTESETTINGS_SETTINGS_REMOVE: '@@services/remote-settings/SETTINGS_REMOVE',
    REMOTESETTINGS_SETTINGS_RESET: '@@services/remote-settings/SETTINGS_RESET',

    REMOTESETTINGS_VALUES_INFO: '@@services/remote-settings/VALUES_INFO',
    REMOTESETTINGS_VALUES_ADD: '@@services/remote-settings/VALUES_ADD',
    REMOTESETTINGS_VALUES_UPDATE: '@@services/remote-settings/VALUES_UPDATE',
    REMOTESETTINGS_VALUES_REMOVE: '@@services/remote-settings/VALUES_REMOVE',
    REMOTESETTINGS_VALUES_RESET: '@@services/remote-settings/VALUES_RESET',
    REMOTESETTINGS_VALUES_IMPORT: '@@services/remote-settings/VALUES_IMPORT',

    RESET: '@@services/remote-settings/RESET',
};
