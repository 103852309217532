import Auth from './auth/reducers';
import User from './user/reducers';
import Organization from './organization/reducers';
import Application from './application/reducers';
import Service from './service/reducers';
import Help from './help/reducers';
import Selection from './selection/reducers';

const dashboardReducers = {
    Auth,
    User,
    Organization,
    Application,
    Service,
    Help,
    Selection,
};

export { dashboardReducers };
