import enFlag from './en/flag.jpg';
import jpFlag from './jp/flag.jpg';

const Languages = {
    en: {
        name: 'English',
        flag: enFlag,
    },
    jp: {
        name: '日本語',
        flag: jpFlag,
    },
};

export default Languages;
