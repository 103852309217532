//@flow
import React from 'react';

import { LoadComponent } from "./LoadComponent";
import { Navigate } from "react-router-dom";

// Routes
import { DashboardRoutesConstants } from '../../constants/urls/routes';

// auth
const Login = React.lazy(() => import('../../pages/auth/Login'));
const Logout = React.lazy(() => import('../../pages/auth/Logout'));
const Register = React.lazy(() => import('../../pages/auth/Register'));
const ConfirmRegister = React.lazy(() => import('../../pages/auth/ConfirmRegister'));
const ValidateEmail = React.lazy(() => import('../../pages/auth/ValidateEmail'));
const ForgetPassword = React.lazy(() => import('../../pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../../pages/auth/ResetPassword'));

const AuthRouter = [
    { path: DashboardRoutesConstants.AuthURL, element: <Navigate to={DashboardRoutesConstants.BaseURL} />, },

    { path: DashboardRoutesConstants.LoginURL, element: <LoadComponent component={Login} /> },
    { path: DashboardRoutesConstants.RegisterURL, element: <LoadComponent component={Register} /> },
    { path: DashboardRoutesConstants.RegisterConfirmURL, element: <LoadComponent component={ConfirmRegister} /> },
    { path: DashboardRoutesConstants.ValidateEmailURL, element: <LoadComponent component={ValidateEmail} /> },
    { path: DashboardRoutesConstants.ForgotPasswordURL, element: <LoadComponent component={ForgetPassword} /> },
    { path: DashboardRoutesConstants.ResetPasswordURL, element: <LoadComponent component={ResetPassword} /> },
    { path: DashboardRoutesConstants.LogoutURL, element: <LoadComponent component={Logout} /> },
];

export { AuthRouter };
