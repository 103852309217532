export const ManageCredentialsActionTypes = {
    API_RESPONSE_SUCCESS: '@@services/credentials/manage/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@services/credentials/manage/API_RESPONSE_ERROR',

    CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO: '@@services/credentials/manage/APPLE_CERTIFICATE_INFO',
    CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD: '@@services/credentials/manage/APPLE_CERTIFICATE_ADD',
    CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE: '@@services/credentials/manage/APPLE_CERTIFICATE_REMOVE',
    CREDENTIALS_MANAGE_APPLE_CERTIFICATE_RESET: '@@services/credentials/manage/APPLE_CERTIFICATE_RESET',

    CREDENTIALS_MANAGE_APPLE_DEVICE_INFO: '@@services/credentials/manage/APPLE_DEVICE_INFO',
    CREDENTIALS_MANAGE_APPLE_DEVICE_ADD: '@@services/credentials/manage/APPLE_DEVICE_ADD',
    CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE: '@@services/credentials/manage/APPLE_DEVICE_TOGGLE',
    CREDENTIALS_MANAGE_APPLE_DEVICE_RESET: '@@services/credentials/manage/APPLE_DEVICE_RESET',

    CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO: '@@services/credentials/manage/APPLE_BUNDLEID_INFO',
    CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD: '@@services/credentials/manage/APPLE_BUNDLEID_ADD',
    CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE: '@@services/credentials/manage/APPLE_BUNDLEID_REMOVE',
    CREDENTIALS_MANAGE_APPLE_BUNDLEID_RESET: '@@services/credentials/manage/APPLE_BUNDLEID_RESET',

    CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO: '@@services/credentials/manage/APPLE_PROVISIONPROFILE_INFO',
    CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD: '@@services/credentials/manage/APPLE_PROVISIONPROFILE_ADD',
    CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT: '@@services/credentials/manage/APPLE_PROVISIONPROFILE_IMPORT',
    CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE: '@@services/credentials/manage/APPLE_PROVISIONPROFILE_REMOVE',
    CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_RESET: '@@services/credentials/manage/APPLE_PROVISIONPROFILE_RESET',

    RESET: '@@services/credentials/manage/RESET',
};
