import main from './main.json';
import certificateList from './certificateList.json';
import addCertificate from './addCertificate.json';
import editCertificate from './editCertificate.json';
import syncCertificate from './syncCertificate.json';
import removeCertificate from './removeCertificate.json';

const output = Object.assign({}, main, certificateList, addCertificate, editCertificate, syncCertificate, removeCertificate);

export default output;
