function ProcessData(response)
{
    let data = response;
    if(data.data !== undefined && data.data !== null)
    {
        data = data.data;
    }
    if(data.message !== undefined && data.message !== null)
    {
        data = data.message;
    }
    return data;
}

function ProcessError(error)
{
    let errorMessage = error;
    if(errorMessage.response !== undefined && errorMessage.response !== null)
    {
        errorMessage = errorMessage.response;
        if(errorMessage.data !== undefined && errorMessage.data !== null)
        {
            errorMessage = errorMessage.data;
        }
    }
    if(errorMessage.message !== undefined && errorMessage.message !== null)
    {
        errorMessage = errorMessage.message;
        if(errorMessage.message !== undefined && errorMessage.message !== null)
        {
            errorMessage = errorMessage.message;
        }
    }
    if (typeof errorMessage === 'string' || errorMessage instanceof String)
    {
        if(errorMessage.includes(":"))
        {
            const parts = errorMessage.split(":");
            if(parts.length === 2)
            {
                errorMessage = parts[1];
            }
        }
    }
    return errorMessage;
}

export { ProcessData, ProcessError }
