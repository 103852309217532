export const ApplicationActionTypes = {
    API_RESPONSE_SUCCESS: '@@application/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@application/API_RESPONSE_ERROR',

    APPLICATION_INFO: '@@application/APPLICATION_INFO',
    APPLICATION_CREATE: '@@application/APPLICATION_CREATE',
    APPLICATION_EDIT: '@@application/APPLICATION_EDIT',
    APPLICATION_DELETE: '@@application/APPLICATION_DELETE',

    RESET: '@@application/RESET',
};
