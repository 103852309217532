// @flow
import { ServiceActionTypes } from './constants';
import config from '../../../config';

const INIT_STATE = {
    loading: false,
};

type ServiceAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Service = (state: State = INIT_STATE, action: ServiceAction): any => {
    if(config.DEBUG === true && Object.values(ServiceActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case ServiceActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ServiceActionTypes.SERVICES_INFO: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        services: action.payload.data,
                    };
                }
                case ServiceActionTypes.SERVICES_ACCESS: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        access: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case ServiceActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ServiceActionTypes.SERVICES_INFO: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        services: [],
                    };
                }
                case ServiceActionTypes.SERVICES_ACCESS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        access: null,
                    };
                }
                default:
                    return { ...state };
            }
        case ServiceActionTypes.SERVICES_INFO:
            return { ...state, loading: true, services: null };
        case ServiceActionTypes.SERVICES_ACCESS:
            return { ...state, loading: true, access: null };
        case ServiceActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                services: null,
                access: null,
            };
        default:
            return { ...state };
    }
};

export default Service;
