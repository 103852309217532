// @flow
import { RemoteSettingsActionTypes } from './constants';

type RemoteSettingsAction = { type: string, payload: {} | string };

// common success
export const remoteSettingsApiResponseSuccess = (actionType: string, data: any): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const remoteSettingsApiResponseError = (actionType: string, error: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Remote Settings
export const remoteSettingsValidate = (): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_VALIDATE,
    payload: {},
});

export const resetRemoteSettings = (): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.RESET,
    payload: {},
});

// Keys
export const remoteSettingsKeyInfo = (keys: any): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_KEY_INFO,
    payload: { keysIds: keys },
});
export const remoteSettingsAddKey = (keyName: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_KEY_ADD,
    payload: { keyName: keyName },
});
export const remoteSettingsRemoveKey = (keyId: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_KEY_REMOVE,
    payload: { keyId: keyId },
});
export const resetRemoteSettingsKey = (): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_KEY_RESET,
    payload: {},
});

// Settings
export const remoteSettingsSettingsInfo = (settingsIds: any): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_INFO,
    payload: { settingsIds: settingsIds },
});
export const remoteSettingsAddSettings = (settingsName: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_ADD,
    payload: { settingsName: settingsName },
});
export const remoteSettingsRemoveSettings = (settingsId: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_REMOVE,
    payload: { settingsId: settingsId },
});
export const resetRemoteSettingsSettings = (): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_SETTINGS_RESET,
    payload: {},
});

// Values
export const remoteSettingsValuesInfo = (settingsId: string, valueIds: any): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_INFO,
    payload: { settingsId: settingsId, valueIds: valueIds },
});
export const remoteSettingsAddValues = (settingsId: string, key: string, type: string, value: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_ADD,
    payload: { settingsId: settingsId, key: key, type: type, value: value },
});
export const remoteSettingsUpdateValues = (settingsId: string, key: string, type: string, value: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_UPDATE,
    payload: { settingsId: settingsId, key: key, type: type, value: value },
});
export const remoteSettingsRemoveValues = (settingsId: string, key: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_REMOVE,
    payload: { settingsId: settingsId, key: key },
});
export const resetRemoteSettingsValues = (): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_RESET,
    payload: {},
});
export const remoteSettingsImportValues = (settingsId: string, file: FileList, newValues: string, missingValues: string, existingValues: string): RemoteSettingsAction => ({
    type: RemoteSettingsActionTypes.REMOTESETTINGS_VALUES_IMPORT,
    payload: { settingsId: settingsId, file: file, newValues: newValues, missingValues: missingValues, existingValues: existingValues },
});
