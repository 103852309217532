// @flow
import { ManageCredentialsActionTypes } from './constants';
import config from '../../../../config';
import { APICore } from "../../../../helpers/api/apiCore";
import services from "../../../../constants/services";

const INIT_STATE = {
    loading: false,
    Apple: {
        Certificate: {
            loading: false,
        },
        Device: {
            loading: false,
        },
        BundleId: {
            loading: false,
        },
        ProvisionProfile: {
            loading: false,
        },
    },
};

type ManageCredentialsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const ManageCredentials = (state: State = INIT_STATE, action: ManageCredentialsAction): any => {
    if(config.DEBUG === true && Object.values(ManageCredentialsActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case ManageCredentialsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateData = action.payload.data;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateAdded = true;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.certificateRemoved = true;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Device ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.deviceData = action.payload.data;
                    return {...state, Apple: {...state.Apple, Device: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Device ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.deviceAdded = true;
                    return {...state, Apple: {...state.Apple, Device: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Device ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.deviceToggled = true;
                    return {...state, Apple: {...state.Apple, Device: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.BundleId ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.bundleIdData = action.payload.data;
                    return {...state, Apple: {...state.Apple, BundleId: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.BundleId ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.bundleIdAdded = true;
                    return {...state, Apple: {...state.Apple, BundleId: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.BundleId ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.bundleIdRemoved = true;
                    return {...state, Apple: {...state.Apple, BundleId: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.provisionProfileData = action.payload.data;
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.provisionProfileAdded = true;
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.provisionProfileImported = true;
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = false;
                    tmpState.provisionProfileRemoved = true;
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                default:
                    return { ...state };
            }

        case ManageCredentialsActionTypes.API_RESPONSE_ERROR:
            if(action.payload.error.includes("CRE-E-UV-AUW-"))
            {
                const api = new APICore();
                api.setServiceAccess(services.Credentials, null);
            }
            switch (action.payload.actionType) {
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateData = [];
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateAdded = false;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Certificate ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.certificateRemoved = false;
                    return {...state, Apple: {...state.Apple, Certificate: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Device ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.deviceData = [];
                    return {...state, Apple: {...state.Apple, Device: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Device ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.deviceAdded = false;
                    return {...state, Apple: {...state.Apple, Device: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.Device ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.deviceToggled = false;
                    return {...state, Apple: {...state.Apple, Device: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.BundleId ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.bundleIdData = [];
                    return {...state, Apple: {...state.Apple, BundleId: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.BundleId ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.bundleIdAdded = false;
                    return {...state, Apple: {...state.Apple, BundleId: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.BundleId ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.bundleIdRemoved = false;
                    return {...state, Apple: {...state.Apple, BundleId: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.ProvisionProfileData = [];
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.ProvisionProfileAdded = false;
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.provisionProfileImported = false;
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE: {
                    let tmpState = state.Apple ?? {};
                    tmpState = tmpState.ProvisionProfile ?? {};
                    tmpState.loading = false;
                    tmpState.error = action.payload.error;
                    tmpState.ProvisionProfileRemoved = false;
                    return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
                }
                default:
                    return { ...state };
            }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateData = null;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateAdded = false;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Certificate ?? {};
            tmpState.loading = true;
            tmpState.certificateRemoved = false;
            return {...state, Apple: {...state.Apple, Certificate: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_RESET:
            return {
                ...state,
                Apple: {
                    ...state.Apple,
                    Certificate: {
                        loading: false,
                        error: false,
                        certificateData: null,
                        certificateAdded: false,
                        certificateRemoved: false,
                    },
                },
            };
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_INFO: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Device ?? {};
            tmpState.loading = true;
            tmpState.deviceData = null;
            return {...state, Apple: {...state.Apple, Device: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_ADD: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Device ?? {};
            tmpState.loading = true;
            tmpState.deviceAdded = false;
            return {...state, Apple: {...state.Apple, Device: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.Device ?? {};
            tmpState.loading = true;
            tmpState.deviceToggled = false;
            return {...state, Apple: {...state.Apple, Device: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_RESET:
            return {
                ...state,
                Apple: {
                    ...state.Apple,
                    Device: {
                        loading: false,
                        error: false,
                        deviceData: null,
                        deviceAdded: false,
                        deviceToggled: false,
                    },
                },
            };
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.BundleId ?? {};
            tmpState.loading = true;
            tmpState.bundleIdData = null;
            return {...state, Apple: {...state.Apple, BundleId: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.BundleId ?? {};
            tmpState.loading = true;
            tmpState.bundleIdAdded = false;
            return {...state, Apple: {...state.Apple, BundleId: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.BundleId ?? {};
            tmpState.loading = true;
            tmpState.bundleIdRemoved = false;
            return {...state, Apple: {...state.Apple, BundleId: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_RESET:
            return {
                ...state,
                Apple: {
                    ...state.Apple,
                    BundleId: {
                        loading: false,
                        error: false,
                        bundleIdData: null,
                        bundleIdAdded: false,
                        bundleIdRemoved: false,
                    },
                },
            };
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.ProvisionProfile ?? {};
            tmpState.loading = true;
            tmpState.provisionProfileData = null;
            return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD: {
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.ProvisionProfile ?? {};
            tmpState.loading = true;
            tmpState.provisionProfileAdded = false;
            return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.ProvisionProfile ?? {};
            tmpState.loading = true;
            tmpState.provisionProfileImported = false;
            return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE:{
            let tmpState = state.Apple ?? {};
            tmpState = tmpState.ProvisionProfile ?? {};
            tmpState.loading = true;
            tmpState.provisionProfileRemoved = false;
            return {...state, Apple: {...state.Apple, ProvisionProfile: tmpState}};
        }
        case ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_RESET:
            return {
                ...state,
                Apple: {
                    ...state.Apple,
                    ProvisionProfile: {
                        loading: false,
                        error: false,
                        provisionProfileData: null,
                        provisionProfileAdded: false,
                        provisionProfileImported: false,
                        provisionProfileRemoved: false,
                    },
                },
            };
        case ManageCredentialsActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                Apple: {
                    Certificate: {
                        loading: false,
                        error: false,
                        certificateData: null,
                        certificateAdded: false,
                        certificateRemoved: false,
                    },
                    Device: {
                        loading: false,
                        error: false,
                        deviceData: null,
                        deviceAdded: false,
                        deviceToggled: false,
                    },
                    BundleId: {
                        loading: false,
                        error: false,
                        bundleIdData: null,
                        bundleIdAdded: false,
                        bundleIdRemoved: false,
                    },
                    ProvisionProfile: {
                        loading: false,
                        error: false,
                        provisionProfileData: null,
                        provisionProfileAdded: false,
                        provisionProfileImported: false,
                        provisionProfileRemoved: false,
                    },
                },
            };
        default:
            return { ...state };
    }
};

export default ManageCredentials;
