// @flow
import { APICore } from '../apiCore';
import services from '../../../constants/services';

// Routes
import {BuildManagerAPIConstants} from '../../../constants/urls/api';

const api = new APICore();

// buildManager
function buildManagerValidate(): any {
    return api.post(BuildManagerAPIConstants.ValidateURL, {}, api.getServiceHeaders(services.BuildManager));
}

function buildManagerSettingsInfo(): any {
    return api.post(BuildManagerAPIConstants.SettingsInfoURL, {}, api.getServiceHeaders(services.BuildManager));
}

function buildManagerSettingsGit(): any {
    return api.post(BuildManagerAPIConstants.SettingsGitURL, {}, api.getServiceHeaders(services.BuildManager));
}

function buildManagerSettingsUpdate(params: any): any {
    return api.post(BuildManagerAPIConstants.SettingsUpdateURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerFlowInfo(params: any): any {
    return api.post(BuildManagerAPIConstants.FlowsInfoURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerFlowEssential(params: any): any {
    return api.post(BuildManagerAPIConstants.FlowsEssentialURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerFlowAdd(params: any): any {
    return api.post(BuildManagerAPIConstants.FlowAddURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerFlowEdit(params: any): any {
    return api.post(BuildManagerAPIConstants.FlowEditURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerFlowRemove(params: any): any {
    return api.post(BuildManagerAPIConstants.FlowRemoveURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerBuildInfo(params: any): any {
    return api.post(BuildManagerAPIConstants.BuildsInfoURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerBuildStart(params: any): any {
    return api.post(BuildManagerAPIConstants.BuildStartURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerBuildUnlock(params: any): any {
    return api.post(BuildManagerAPIConstants.BuildUnlockURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerBuildRemove(params: any): any {
    return api.post(BuildManagerAPIConstants.BuildRemoveURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerBuildInfoDetails(params: any): any {
    return api.post(BuildManagerAPIConstants.BuildInfoDetailsURL, params, api.getServiceHeaders(services.BuildManager));
}
function buildManagerBuildInfoDownload(params: any): any {
    return api.post(BuildManagerAPIConstants.BuildInfoDownloadURL, params, api.getServiceHeaders(services.BuildManager));
}
function buildManagerBuildInfoLog(params: any): any {
    return api.post(BuildManagerAPIConstants.BuildInfoLogURL, params, api.getServiceHeaders(services.BuildManager));
}

function buildManagerPublicBuildInfo(params: any): any {
    return api.post(BuildManagerAPIConstants.PublicBuildInfoURL, params);
}

export { buildManagerValidate,
    buildManagerSettingsInfo, buildManagerSettingsGit, buildManagerSettingsUpdate,
    buildManagerFlowInfo, buildManagerFlowEssential, buildManagerFlowAdd, buildManagerFlowEdit, buildManagerFlowRemove,
    buildManagerBuildInfo, buildManagerBuildStart, buildManagerBuildUnlock, buildManagerBuildRemove,
    buildManagerBuildInfoDetails, buildManagerBuildInfoDownload, buildManagerBuildInfoLog,
    buildManagerPublicBuildInfo,
};
