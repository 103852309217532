//@flow
import React from 'react';
import { Navigate } from 'react-router-dom';

import {LoadComponent} from "../LoadComponent";

// Routes
import { DashboardRoutesConstants } from '../../../constants/urls/routes';

// organization
const Organizations = React.lazy(() => import('../../../pages/hub/organizations'));
const OrganizationCreate = React.lazy(() => import('../../../pages/hub/organizations/CreateOrganization'));
const OrganizationEdit = React.lazy(() => import('../../../pages/hub/organizations/EditOrganization'));
const OrganizationDelete = React.lazy(() => import('../../../pages/hub/organizations/DeleteOrganization'));
const OrganizationAdd = React.lazy(() => import('../../../pages/hub/organizations/AddUser'));
const OrganizationRemove = React.lazy(() => import('../../../pages/hub/organizations/RemoveUser'));

const OrganizationRouter = [
    { path: DashboardRoutesConstants.OrganizationsURL, element: <LoadComponent component={Organizations} />, },

    { path: DashboardRoutesConstants.CreateOrganizationURL, element: <LoadComponent component={OrganizationCreate} />, },
    { path: DashboardRoutesConstants.EditOrganizationURL, element: <Navigate to={DashboardRoutesConstants.OrganizationsURL} />, },
    { path: DashboardRoutesConstants.EditOrganizationURL+"/:organizationId", element: <LoadComponent component={OrganizationEdit} />, },
    { path: DashboardRoutesConstants.DeleteOrganizationURL, element: <Navigate to={DashboardRoutesConstants.OrganizationsURL} />, },
    { path: DashboardRoutesConstants.DeleteOrganizationURL+"/:organizationId", element: <LoadComponent component={OrganizationDelete} />, },

    { path: DashboardRoutesConstants.AddUserToOrganizationURL, element: <Navigate to={DashboardRoutesConstants.OrganizationsURL} />, },
    { path: DashboardRoutesConstants.AddUserToOrganizationURL+"/:organizationId", element: <LoadComponent component={OrganizationAdd} />, },
    { path: DashboardRoutesConstants.RemoveUserFromOrganizationURL, element: <Navigate to={DashboardRoutesConstants.OrganizationsURL} />, },
    { path: DashboardRoutesConstants.RemoveUserFromOrganizationURL+"/:organizationId/:userId?", element: <LoadComponent component={OrganizationRemove} />, },
];

export { OrganizationRouter };
