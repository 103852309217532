// @flow
import { SelectionActionTypes } from './constants';
import config from '../../../config';

const INIT_STATE = {
    loading: false,
};

type SelectionAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Selection = (state: State = INIT_STATE, action: SelectionAction): any => {
    if(config.DEBUG === true && Object.values(SelectionActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case SelectionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SelectionActionTypes.SELECT_ORGANIZATION: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        organizationId: action.payload.data,
                    };
                }
                case SelectionActionTypes.SELECT_APPLICATION: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        applicationId: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case SelectionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SelectionActionTypes.SELECT_ORGANIZATION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        organizationId: null,
                    };
                }
                case SelectionActionTypes.SELECT_APPLICATION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        applicationId: null,
                    };
                }
                default:
                    return { ...state };
            }
        case SelectionActionTypes.SELECT_ORGANIZATION:
            return { ...state, loading: true, organizationId: null };
        case SelectionActionTypes.SELECT_APPLICATION:
            return { ...state, loading: true, applicationId: null };
        case SelectionActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                organizationId: null,
                applicationId: null,
            };
        default:
            return { ...state };
    }
};

export default Selection;
