// @flow
import { APICore } from '../apiCore';

// Routes
import { HubAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// services
function servicesInfo(): any {
    const headers = {
        ...api.getSelectionOrganizationHeaders(),
        ...api.getSelectionApplicationHeaders(),
    };
    return api.post(HubAPIConstants.ServicesInfoURL, {}, headers);
}
function serviceAccess(params: any): any {
    const headers = {
        ...api.getSelectionOrganizationHeaders(),
        ...api.getSelectionApplicationHeaders(),
    };
    api.setServiceAccess(params.serviceId, null);
    headers["X-Service"] = params.serviceId;
    delete params.serviceId;
    return api.post(HubAPIConstants.ServiceAccessURL, params, headers);
}

export { servicesInfo, serviceAccess };
