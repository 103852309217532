// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { APICore } from "../../../helpers/api/apiCore";

import {
    servicesInfo as servicesInfoApi,
    serviceAccess as serviceAccessApi,
} from '../../../helpers/api/dashboardActions/service';

import { serviceApiResponseSuccess, serviceApiResponseError } from './actions';
import { ServiceActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* servicesInfo() {
    try {
        const response = yield call(servicesInfoApi);
        yield put(serviceApiResponseSuccess(ServiceActionTypes.SERVICES_INFO, ProcessData(response)));
    } catch (error) {
        yield put(serviceApiResponseError(ServiceActionTypes.SERVICES_INFO, ProcessError(error)));
    }
}
function* serviceAccess({ payload: { serviceId } }) {
    try {
        const response = yield call(serviceAccessApi, { serviceId });
        const data = ProcessData(response);
        const api = new APICore();
        api.setServiceAccess(data.serviceId, data);
        yield put(serviceApiResponseSuccess(ServiceActionTypes.SERVICES_ACCESS, data));
    } catch (error) {
        yield put(serviceApiResponseError(ServiceActionTypes.SERVICES_ACCESS, ProcessError(error)));
    }
}

export function* watchServicesInfo(): any {
    yield takeEvery(ServiceActionTypes.SERVICES_INFO, servicesInfo);
}
export function* watchServiceAccess(): any {
    yield takeEvery(ServiceActionTypes.SERVICES_ACCESS, serviceAccess);
}

function* serviceSage(): any {
    yield all([
        fork(watchServicesInfo),
        fork(watchServiceAccess),
    ]);
}

export default serviceSage;
