import BasePath from "./Base";
const CredentialsRoutes = {
    // Credentials
    CredentialsURL: BasePath + "credentials",

    // + Git
    CredentialsGitURL: BasePath + "credentials/git",
    CredentialsGitAddURL: BasePath + "credentials/git/add",
    CredentialsGitEditURL: BasePath + "credentials/git/edit",
    CredentialsGitSyncURL: BasePath + "credentials/git/sync",
    CredentialsGitRemoveURL: BasePath + "credentials/git/remove",

    // + Unity
    CredentialsUnityURL: BasePath + "credentials/unity",
    CredentialsUnityAddURL: BasePath + "credentials/unity/add",
    CredentialsUnityEditURL: BasePath + "credentials/unity/edit",
    CredentialsUnityRemoveURL: BasePath + "credentials/unity/remove",

    // + Google
    CredentialsGoogleURL: BasePath + "credentials/google",
    // + PlayStore
    CredentialsGooglePlayStoreURL: BasePath + "credentials/google/playstore",
    CredentialsGooglePlayStoreAddURL: BasePath + "credentials/google/playstore/add",
    CredentialsGooglePlayStoreEditURL: BasePath + "credentials/google/playstore/edit",
    CredentialsGooglePlayStoreSyncURL: BasePath + "credentials/google/playstore/sync",
    CredentialsGooglePlayStoreRemoveURL: BasePath + "credentials/google/playstore/remove",
    // + Keystore
    CredentialsGoogleKeystoreURL: BasePath + "credentials/google/keystore",
    CredentialsGoogleKeystoreAddURL: BasePath + "credentials/google/keystore/add",
    CredentialsGoogleKeystoreEditURL: BasePath + "credentials/google/keystore/edit",
    CredentialsGoogleKeystorePasswordsURL: BasePath + "credentials/google/keystore/passwords",
    CredentialsGoogleKeystoreSyncURL: BasePath + "credentials/google/keystore/sync",
    CredentialsGoogleKeystoreRemoveURL: BasePath + "credentials/google/keystore/remove",

    // + Apple
    CredentialsAppleURL: BasePath + "credentials/apple",
    // + AppStore
    CredentialsAppleAppStoreURL: BasePath + "credentials/apple/appstore",
    CredentialsAppleAppStoreAddURL: BasePath + "credentials/apple/appstore/add",
    CredentialsAppleAppStoreEditURL: BasePath + "credentials/apple/appstore/edit",
    CredentialsAppleAppStoreSyncURL: BasePath + "credentials/apple/appstore/sync",
    CredentialsAppleAppStoreRemoveURL: BasePath + "credentials/apple/appstore/remove",
    // + + Manage
    CredentialsAppleAppStoreManageURL: BasePath + "credentials/apple/appstore/manage",
    CredentialsAppleAppStoreManageCertificatesURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/certificates",
    CredentialsAppleAppStoreManageCertificatesAddURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/certificates/add",
    CredentialsAppleAppStoreManageCertificatesRemoveURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/certificates/remove",
    CredentialsAppleAppStoreManageDevicesURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/devices",
    CredentialsAppleAppStoreManageDevicesAddURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/devices/add",
    CredentialsAppleAppStoreManageDevicesToggleURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/devices/toggle",
    CredentialsAppleAppStoreManageBundleIdsURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/bundleIds",
    CredentialsAppleAppStoreManageBundleIdsAddURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/bundleIds/add",
    CredentialsAppleAppStoreManageBundleIdsRemoveURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/bundleIds/remove",
    CredentialsAppleAppStoreManageProvisionProfilesURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/provisionProfiles",
    CredentialsAppleAppStoreManageProvisionProfilesAddURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/provisionProfiles/add",
    CredentialsAppleAppStoreManageProvisionProfilesImportURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/provisionProfiles/import",
    CredentialsAppleAppStoreManageProvisionProfilesRemoveURL: BasePath + "credentials/apple/appstore/manage/:appStoreId/provisionProfiles/remove",

    // + Certificate
    CredentialsAppleCertificateURL: BasePath + "credentials/apple/certificate",
    CredentialsAppleCertificateAddURL: BasePath + "credentials/apple/certificate/add",
    CredentialsAppleCertificateEditURL: BasePath + "credentials/apple/certificate/edit",
    CredentialsAppleCertificatePasswordsURL: BasePath + "credentials/apple/certificate/passwords",
    CredentialsAppleCertificateSyncURL: BasePath + "credentials/apple/certificate/sync",
    CredentialsAppleCertificateRemoveURL: BasePath + "credentials/apple/certificate/remove",
    // + Provisions
    CredentialsAppleProvisionURL: BasePath + "credentials/apple/provision",
    CredentialsAppleProvisionAddURL: BasePath + "credentials/apple/provision/add",
    CredentialsAppleProvisionRemoveURL: BasePath + "credentials/apple/provision/remove",
};

export default CredentialsRoutes;
