import { MailerRouter } from './mailer';
import { RemoteSettingsRouter } from './remote-settings';
import { TranslationsRouter } from './translations';
import { CredentialsRouter } from './credentials';
import { PackageManagerRouter } from "./package-manager";
import { BuildManagerRouter } from "./build-manager";

const routes = [
    ...MailerRouter,
    ...RemoteSettingsRouter,
    ...TranslationsRouter,
    ...CredentialsRouter,
    ...PackageManagerRouter,
    ...BuildManagerRouter,
];

export default routes;
