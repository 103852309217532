import config from '../../../config';

const BaseURL = config.BUILDMANAGER_URL +"/";
const BuildManagerAPIConstants = {
    ValidateURL: BaseURL + "validate",

    SettingsInfoURL: BaseURL + "settings",
    SettingsGitURL: BaseURL + "settings/info",
    SettingsUpdateURL: BaseURL + "settings/update",

    FlowsInfoURL: BaseURL + "flow",
    FlowsEssentialURL: BaseURL + "flow/essential",
    FlowAddURL: BaseURL + "flow/add",
    FlowEditURL: BaseURL + "flow/edit",
    FlowRemoveURL: BaseURL + "flow/remove",

    BuildsInfoURL: BaseURL + "build",
    BuildStartURL: BaseURL + "build/start",
    BuildUnlockURL: BaseURL + "build/unlock",
    BuildRemoveURL: BaseURL + "build/remove",

    BuildInfoDownloadURL: BaseURL + "build/download",
    BuildInfoDetailsURL: BaseURL + "build/details",
    BuildInfoLogURL: BaseURL + "build/log",

    PublicBuildInfoURL: BaseURL + "public/build",
};

export default BuildManagerAPIConstants;
