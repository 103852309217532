import config from '../../../config';

const BaseURL = config.PACKAGEMANAGER_URL +"/";
const PackageManagerAPIConstants = {
    ValidateURL: BaseURL + "validate",

    APIKeysInfoURL: BaseURL + "keys",
    APIKeyAddURL: BaseURL + "keys/add",
    APIKeyRemoveURL: BaseURL + "keys/remove",

    PackagesInfoURL: BaseURL + "packages",
    PackageGitURL: BaseURL + "packages/git",
    PackageAddURL: BaseURL + "packages/add",
    PackageEditURL: BaseURL + "packages/edit",
    PackageSyncURL: BaseURL + "packages/sync",
    PackageRemoveURL: BaseURL + "packages/remove",
};

export default PackageManagerAPIConstants;
