import config from '../../../config';

const BaseURL = config.REMOTESETTINGS_URL +"/";
const RemoteSettingsAPIConstants = {
    ValidateURL: BaseURL + "validate",

    APIKeysInfoURL: BaseURL + "keys",
    APIKeyAddURL: BaseURL + "keys/add",
    APIKeyRemoveURL: BaseURL + "keys/remove",

    SettingsInfoURL: BaseURL + "settings",
    SettingsAddURL: BaseURL + "settings/add",
    SettingsRemoveURL: BaseURL + "settings/remove",

    ValuesInfoURL: BaseURL + "value",
    ValuesAddURL: BaseURL + "value/add",
    ValuesUpdateURL: BaseURL + "value/update",
    ValuesRemoveURL: BaseURL + "value/remove",
    ValuesImportURL: BaseURL + "value/import",
};

export default RemoteSettingsAPIConstants;
