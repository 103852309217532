// @flow
import { TranslationsActionTypes } from './constants';
import config from '../../../config';
import { APICore } from "../../../helpers/api/apiCore";
import services from "../../../constants/services";

const INIT_STATE = {
    loading: false,
    Key: {
        loading: false,
    },
    Languages: {
        loading: false,
    },
    Values: {
        loading: false,
    },
    Flags: {
        loading: false,
    },
};

type TranslationsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Translations = (state: State = INIT_STATE, action: TranslationsAction): any => {
    if(config.DEBUG === true && Object.values(TranslationsActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case TranslationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TranslationsActionTypes.TRANSLATIONS_VALIDATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        validated: true,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keysData = action.payload.data;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyAdded = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyRemoved = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_INFO: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = false;
                    languageState.languagesData = action.payload.data;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_ADD: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = false;
                    languageState.languageAdded = true;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_REMOVE: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = false;
                    languageState.languageRemoved = true;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_DEFAULT: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = false;
                    languageState.languageDefaulted = true;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_SYNC: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = false;
                    languageState.languageSynced = true;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_INFO: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valuesData = action.payload.data;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_ADD: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueAdded = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_UPDATE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueUpdated = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_REMOVE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueRemoved = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_IMPORT: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = false;
                    valuesState.valueImported = true;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_FLAGS: {
                    const flagsState = state.Flags ?? {};
                    flagsState.loading = false;
                    flagsState.error = false;
                    flagsState.flagsData = action.payload.data;
                    return {
                        ...state,
                        Flags: flagsState,
                    };
                }
                default:
                    return { ...state };
            }

        case TranslationsActionTypes.API_RESPONSE_ERROR:
            if(action.payload.error.includes("TRA-E-UV-AUW-"))
            {
                const api = new APICore();
                api.setServiceAccess(services.Translations, null);
            }
            switch (action.payload.actionType) {
                case TranslationsActionTypes.TRANSLATIONS_VALIDATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        validated: false,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keysData = [];
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyAdded = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyRemoved = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_INFO: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = action.payload.error;
                    languageState.languagesData = [];
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_ADD: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = action.payload.error;
                    languageState.languageAdded = false;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_REMOVE: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = action.payload.error;
                    languageState.languageRemoved = false;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_DEFAULT: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = action.payload.error;
                    languageState.languageDefaulted = false;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_SYNC: {
                    const languageState = state.Languages ?? {};
                    languageState.loading = false;
                    languageState.error = action.payload.error;
                    languageState.languageSynced = false;
                    return {
                        ...state,
                        Languages: languageState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_INFO: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valuesData = [];
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_ADD: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueAdded = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_UPDATE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueUpdated = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_REMOVE: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueRemoved = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_VALUES_IMPORT: {
                    const valuesState = state.Values ?? {};
                    valuesState.loading = false;
                    valuesState.error = action.payload.error;
                    valuesState.valueImported = false;
                    return {
                        ...state,
                        Values: valuesState,
                    };
                }
                case TranslationsActionTypes.TRANSLATIONS_FLAGS: {
                    const flagsState = state.Flags ?? {};
                    flagsState.loading = false;
                    flagsState.error = action.payload.error;
                    flagsState.flagsData = null;
                    return {
                        ...state,
                        Flags: flagsState,
                    };
                }
                default:
                    return { ...state };
            }
        case TranslationsActionTypes.TRANSLATIONS_VALIDATE:
            return { ...state, loading: true, validated: false };
        case TranslationsActionTypes.TRANSLATIONS_KEY_INFO: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keysData = null;
            return {...state, Key: keyState};
        }
        case TranslationsActionTypes.TRANSLATIONS_KEY_ADD: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyAdded = false;
            return {...state, Key: keyState};
        }
        case TranslationsActionTypes.TRANSLATIONS_KEY_REMOVE:{
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyRemoved = false;
            return {...state, Key: keyState};
        }
        case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_INFO: {
            const languageState = state.Languages ?? {};
            languageState.loading = true;
            languageState.languagesData = null;
            return {...state, Languages: languageState};
        }
        case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_ADD: {
            const languageState = state.Languages ?? {};
            languageState.loading = true;
            languageState.languageAdded = false;
            return {...state, Languages: languageState};
        }
        case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_REMOVE:{
            const languageState = state.Languages ?? {};
            languageState.loading = true;
            languageState.languageRemoved = false;
            return {...state, Languages: languageState};
        }
        case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_DEFAULT:{
            const languageState = state.Languages ?? {};
            languageState.loading = true;
            languageState.languageDefaulted = false;
            return {...state, Languages: languageState};
        }
        case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_SYNC:{
            const languageState = state.Languages ?? {};
            languageState.loading = true;
            languageState.languageSynced = false;
            return {...state, Languages: languageState};
        }
        case TranslationsActionTypes.TRANSLATIONS_VALUES_INFO: {
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valuesData = null;
            return {...state, Values: valuesState};
        }
        case TranslationsActionTypes.TRANSLATIONS_VALUES_ADD: {
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueAdded = false;
            return {...state, Values: valuesState};
        }
        case TranslationsActionTypes.TRANSLATIONS_VALUES_UPDATE: {
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueUpdated = false;
            return {...state, Values: valuesState};
        }
        case TranslationsActionTypes.TRANSLATIONS_VALUES_REMOVE:{
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueRemoved = false;
            return {...state, Values: valuesState};
        }
        case TranslationsActionTypes.TRANSLATIONS_VALUES_IMPORT:{
            const valuesState = state.Values ?? {};
            valuesState.loading = true;
            valuesState.valueImported = false;
            return {...state, Values: valuesState};
        }
        case TranslationsActionTypes.TRANSLATIONS_FLAGS:{
            const flagsState = state.Flags ?? {};
            flagsState.loading = true;
            flagsState.flagsData = null;
            return {...state, Flags: flagsState};
        }
        case TranslationsActionTypes.TRANSLATIONS_KEY_RESET:
            return {
                ...state,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
            };
        case TranslationsActionTypes.TRANSLATIONS_LANGUAGES_RESET:
            return {
                ...state,
                Languages: {
                    loading: false,
                    error: false,
                    languagesData: null,
                    languageAdded: false,
                    languageRemoved: false,
                    languageDefaulted: false,
                    languageSynced: false,
                },
            };
        case TranslationsActionTypes.TRANSLATIONS_VALUES_RESET:
            return {
                ...state,
                Values: {
                    loading: false,
                    error: false,
                    valuesData: null,
                    valueAdded: false,
                    valueUpdated: false,
                    valueRemoved: false,
                    valueImported: false,
                },
            };
        case TranslationsActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                validated: false,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
                Languages: {
                    loading: false,
                    error: false,
                    languagesData: null,
                    languageAdded: false,
                    languageRemoved: false,
                    languageDefaulted: false,
                    languageSynced: false,
                },
                Values: {
                    loading: false,
                    error: false,
                    valuesData: null,
                    valueAdded: false,
                    valueUpdated: false,
                    valueRemoved: false,
                    valueImported: false,
                },
                Flags: {
                    loading: false,
                    error: false,
                    flagsData: null,
                },
            };
        default:
            return { ...state };
    }
};

export default Translations;
