// @flow
import authSaga from './auth/saga';
import userSaga from './user/saga';
import organizationSaga from './organization/saga';
import applicationSaga from './application/saga';
import serviceSage from './service/saga';
import helpSage from './help/saga';
import selectionSage from './selection/saga';

const dashboardSagas = [authSaga(), userSaga(), organizationSaga(), applicationSaga(), serviceSage(), helpSage(), selectionSage()];

export { dashboardSagas };
