import config from "../config";
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

const getLayout = (layoutType) => {
    let layoutCls = DefaultLayout;
    if(config.MAINTENANCE === true)
    {
        return layoutCls;
    }

    switch (layoutType) {
        case layoutConstants.LAYOUT_HORIZONTAL:
            layoutCls = HorizontalLayout;
            break;
        case layoutConstants.LAYOUT_DETACHED:
            layoutCls = DetachedLayout;
            break;
        case layoutConstants.LAYOUT_FULL:
            layoutCls = FullLayout;
            break;
        default:
            layoutCls = VerticalLayout;
            break;
    }
    return layoutCls;
};

export { DefaultLayout, getLayout };
