// @flow
import { APICore } from '../apiCore';
import services from '../../../constants/services';

// Routes
import { CredentialsManageAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// Credentials

// Certificate
function manageCredentialsAppleCertificateInfo(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleCertificateInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleCertificateAdd(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleCertificateAddURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleCertificateRemove(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleCertificateRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// Device
function manageCredentialsAppleDeviceInfo(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleDeviceInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleDeviceAdd(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleDeviceAddURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleDeviceToggle(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleDeviceToggleURL, params, api.getServiceHeaders(services.Credentials));
}

// Bundle Id
function manageCredentialsAppleBundleIdInfo(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleBundleIdInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleBundleIdAdd(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleBundleIdAddURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleBundleIdRemove(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleBundleIdRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// Provision Profile
function manageCredentialsAppleProvisionProfileInfo(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleProvisionProfileInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleProvisionProfileAdd(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleProvisionProfileAddURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleProvisionProfileImport(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleProvisionProfileImportURL, params, api.getServiceHeaders(services.Credentials));
}
function manageCredentialsAppleProvisionProfileRemove(params: any): any {
    return api.post(CredentialsManageAPIConstants.ManageAppleProvisionProfileRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

export {
    manageCredentialsAppleCertificateInfo, manageCredentialsAppleCertificateAdd, manageCredentialsAppleCertificateRemove,
    manageCredentialsAppleDeviceInfo, manageCredentialsAppleDeviceAdd, manageCredentialsAppleDeviceToggle,
    manageCredentialsAppleBundleIdInfo, manageCredentialsAppleBundleIdAdd, manageCredentialsAppleBundleIdRemove,
    manageCredentialsAppleProvisionProfileInfo, manageCredentialsAppleProvisionProfileAdd, manageCredentialsAppleProvisionProfileImport, manageCredentialsAppleProvisionProfileRemove,
};
