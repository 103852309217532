// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    manageCredentialsAppleCertificateInfo as manageCredentialsAppleCertificateInfoApi,
    manageCredentialsAppleCertificateAdd as manageCredentialsAppleCertificateAddApi,
    manageCredentialsAppleCertificateRemove as manageCredentialsAppleCertificateRemoveApi,

    manageCredentialsAppleDeviceInfo as manageCredentialsAppleDeviceInfoApi,
    manageCredentialsAppleDeviceAdd as manageCredentialsAppleDeviceAddApi,
    manageCredentialsAppleDeviceToggle as manageCredentialsAppleDeviceToggleApi,

    manageCredentialsAppleBundleIdInfo as manageCredentialsAppleBundleIdInfoApi,
    manageCredentialsAppleBundleIdAdd as manageCredentialsAppleBundleIdAddApi,
    manageCredentialsAppleBundleIdRemove as manageCredentialsAppleBundleIdRemoveApi,

    manageCredentialsAppleProvisionProfileInfo as manageCredentialsAppleProvisionProfileInfoApi,
    manageCredentialsAppleProvisionProfileAdd as manageCredentialsAppleProvisionProfileAddApi,
    manageCredentialsAppleProvisionProfileImport as manageCredentialsAppleProvisionProfileImportApi,
    manageCredentialsAppleProvisionProfileRemove as manageCredentialsAppleProvisionProfileRemoveApi,
} from '../../../../helpers/api/servicesActions/credentials-manage';

import { manageCredentialsApiResponseSuccess, manageCredentialsApiResponseError } from './actions';
import { ManageCredentialsActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../../generic';

function* manageCredentialsAppleCertificateInfo({ payload: { appStoreId, certificateIds } }) {
    try {
        const response = yield call(manageCredentialsAppleCertificateInfoApi, { appStoreId, certificateIds });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO, ProcessError(error)));
    }
}
function* manageCredentialsAppleCertificateAdd({ payload: { appStoreId, certName, certType } }) {
    try {
        const response = yield call(manageCredentialsAppleCertificateAddApi, { appStoreId, certName, certType });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD, ProcessError(error)));
    }
}
function* manageCredentialsAppleCertificateRemove({ payload: { appStoreId, appleCertificateId } }) {
    try {
        const response = yield call(manageCredentialsAppleCertificateRemoveApi, { appStoreId, appleCertificateId });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE, ProcessError(error)));
    }
}
function* manageCredentialsAppleDeviceInfo({ payload: { appStoreId, deviceIds } }) {
    try {
        const response = yield call(manageCredentialsAppleDeviceInfoApi, { appStoreId, deviceIds });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_INFO, ProcessError(error)));
    }
}
function* manageCredentialsAppleDeviceAdd({ payload: { appStoreId, deviceName, deviceUdid } }) {
    try {
        const response = yield call(manageCredentialsAppleDeviceAddApi, { appStoreId, deviceName, deviceUdid });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_ADD, ProcessError(error)));
    }
}
function* manageCredentialsAppleDeviceToggle({ payload: { appStoreId, appleDeviceId, status } }) {
    try {
        const response = yield call(manageCredentialsAppleDeviceToggleApi, { appStoreId, appleDeviceId, status });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE, ProcessError(error)));
    }
}
function* manageCredentialsAppleBundleIdInfo({ payload: { appStoreId, bundleIds } }) {
    try {
        const response = yield call(manageCredentialsAppleBundleIdInfoApi, { appStoreId, bundleIds });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO, ProcessError(error)));
    }
}
function* manageCredentialsAppleBundleIdAdd({ payload: { appStoreId, bundleName, bundleId } }) {
    try {
        const response = yield call(manageCredentialsAppleBundleIdAddApi, { appStoreId, bundleName, bundleId });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD, ProcessError(error)));
    }
}
function* manageCredentialsAppleBundleIdRemove({ payload: { appStoreId, appleBundleId } }) {
    try {
        const response = yield call(manageCredentialsAppleBundleIdRemoveApi, { appStoreId, appleBundleId });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE, ProcessError(error)));
    }
}
function* manageCredentialsAppleProvisionProfileInfo({ payload: { appStoreId, provisionProfileIds } }) {
    try {
        const response = yield call(manageCredentialsAppleProvisionProfileInfoApi, { appStoreId, provisionProfileIds });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO, ProcessError(error)));
    }
}
function* manageCredentialsAppleProvisionProfileAdd({ payload: { appStoreId, profileName, bundleId, profileType, certificates, devices } }) {
    try {
        const response = yield call(manageCredentialsAppleProvisionProfileAddApi, { appStoreId, profileName, bundleId, profileType, certificates, devices });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD, ProcessError(error)));
    }
}
function* manageCredentialsAppleProvisionProfileImport({ payload: { appStoreId, appStoreProvisionProfileId } }) {
    try {
        const response = yield call(manageCredentialsAppleProvisionProfileImportApi, { appStoreId, appStoreProvisionProfileId });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT, ProcessError(error)));
    }
}
function* manageCredentialsAppleProvisionProfileRemove({ payload: { appStoreId, appStoreProvisionProfileId } }) {
    try {
        const response = yield call(manageCredentialsAppleProvisionProfileRemoveApi, { appStoreId, appStoreProvisionProfileId });
        yield put(manageCredentialsApiResponseSuccess(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(manageCredentialsApiResponseError(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE, ProcessError(error)));
    }
}

export function* watchManageCredentialsAppleCertificateInfo(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO, manageCredentialsAppleCertificateInfo);
}
export function* watchManageCredentialsAppleCertificateAdd(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD, manageCredentialsAppleCertificateAdd);
}
export function* watchManageCredentialsAppleCertificateRemove(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE, manageCredentialsAppleCertificateRemove);
}
export function* watchManageCredentialsAppleDeviceInfo(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_INFO, manageCredentialsAppleDeviceInfo);
}
export function* watchManageCredentialsAppleDeviceAdd(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_ADD, manageCredentialsAppleDeviceAdd);
}
export function* watchManageCredentialsAppleDeviceToggle(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE, manageCredentialsAppleDeviceToggle);
}
export function* watchManageCredentialsAppleBundleIdInfo(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO, manageCredentialsAppleBundleIdInfo);
}
export function* watchManageCredentialsAppleBundleIdAdd(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD, manageCredentialsAppleBundleIdAdd);
}
export function* watchManageCredentialsAppleBundleIdRemove(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE, manageCredentialsAppleBundleIdRemove);
}
export function* watchManageCredentialsAppleProvisionProfileInfo(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO, manageCredentialsAppleProvisionProfileInfo);
}
export function* watchManageCredentialsAppleProvisionProfileAdd(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD, manageCredentialsAppleProvisionProfileAdd);
}
export function* watchManageCredentialsAppleProvisionProfileImport(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT, manageCredentialsAppleProvisionProfileImport);
}
export function* watchManageCredentialsAppleProvisionProfileRemove(): any {
    yield takeEvery(ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE, manageCredentialsAppleProvisionProfileRemove);
}

function* manageCredentialsSage(): any {
    yield all([
        fork(watchManageCredentialsAppleCertificateInfo),
        fork(watchManageCredentialsAppleCertificateAdd),
        fork(watchManageCredentialsAppleCertificateRemove),

        fork(watchManageCredentialsAppleDeviceInfo),
        fork(watchManageCredentialsAppleDeviceAdd),
        fork(watchManageCredentialsAppleDeviceToggle),

        fork(watchManageCredentialsAppleBundleIdInfo),
        fork(watchManageCredentialsAppleBundleIdAdd),
        fork(watchManageCredentialsAppleBundleIdRemove),

        fork(watchManageCredentialsAppleProvisionProfileInfo),
        fork(watchManageCredentialsAppleProvisionProfileAdd),
        fork(watchManageCredentialsAppleProvisionProfileImport),
        fork(watchManageCredentialsAppleProvisionProfileRemove),
    ]);
}

export default manageCredentialsSage;
