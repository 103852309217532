// @flow
import { SelectionActionTypes } from './constants';

type SelectionAction = { type: string, payload: {} | string };

// common success
export const selectionApiResponseSuccess = (actionType: string, data: any): SelectionAction => ({
    type: SelectionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const selectionApiResponseError = (actionType: string, error: string): SelectionAction => ({
    type: SelectionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Account
export const selectOrganization = (organizationId: string): SelectionAction => ({
    type: SelectionActionTypes.SELECT_ORGANIZATION,
    payload: { organizationId: organizationId },
});
export const selectApplication = (applicationId: string): SelectionAction => ({
    type: SelectionActionTypes.SELECT_APPLICATION,
    payload: { applicationId: applicationId },
});

export const resetSelection = (): SelectionAction => ({
    type: SelectionActionTypes.RESET,
    payload: {},
});
