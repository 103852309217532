// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { APICore } from "../../../helpers/api/apiCore";

import {
    applicationInfo as applicationInfoApi,
    createApplication as createApplicationApi,
    editApplication as editApplicationApi,
    deleteApplication as deleteApplicationApi,
} from '../../../helpers/api/dashboardActions/application';

import { applicationApiResponseSuccess, applicationApiResponseError } from './actions';
import { ApplicationActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* applicationInfo({ payload: { applications } }) {
    try {
        const response = yield call(applicationInfoApi, { applications });
        yield put(applicationApiResponseSuccess(ApplicationActionTypes.APPLICATION_INFO, ProcessData(response)));
    } catch (error) {
        yield put(applicationApiResponseError(ApplicationActionTypes.APPLICATION_INFO, ProcessError(error)));
    }
}

function* createApplication({ payload: { applicationName } }) {
    try {
        const response = yield call(createApplicationApi, { applicationName });

        const data = ProcessData(response);
        const api = new APICore();
        const loggedInUser = api.getLoggedInUser();
        const selection = api.getSelection();
        if(loggedInUser.Organizations === null || loggedInUser.Organizations === undefined)
        {
            loggedInUser.Organizations = [];
        }
        let index = -1;
        for(let i = 0; i < loggedInUser.Organizations.length; i++)
        {
            if(loggedInUser.Organizations[i].Id !== selection.organizationId)
                continue;
            index = i;
            break;
        }
        if (index > -1)
        {
            if(loggedInUser.Organizations[index].Applications === null || loggedInUser.Organizations[index].Applications === undefined)
            {
                loggedInUser.Organizations[index].Applications = [];
            }
            loggedInUser.Organizations[index].Applications.push(data);
            api.setLoggedInUser(loggedInUser);
        }

        yield put(applicationApiResponseSuccess(ApplicationActionTypes.APPLICATION_CREATE, data));
    } catch (error) {
        yield put(applicationApiResponseError(ApplicationActionTypes.APPLICATION_CREATE, ProcessError(error)));
    }
}

function* editApplication({ payload: { applicationId, applicationName } }) {
    try {
        const response = yield call(editApplicationApi, { applicationId, applicationName });

        const api = new APICore();
        const loggedInUser = api.getLoggedInUser();
        const selection = api.getSelection();
        if(loggedInUser.Organizations !== null && loggedInUser.Organizations !== undefined && Array.isArray(loggedInUser.Organizations))
        {
            let index = -1;
            for(let i = 0; i < loggedInUser.Organizations.length; i++)
            {
                if(loggedInUser.Organizations[i].Id !== selection.organizationId)
                    continue;
                index = i;
                break;
            }
            if (index > -1)
            {
                if(loggedInUser.Organizations[index].Applications !== null && loggedInUser.Organizations[index].Applications !== undefined && Array.isArray(loggedInUser.Organizations[index].Applications))
                {
                    let appIndex = -1;
                    for(let i = 0; i < loggedInUser.Organizations[index].Applications.length; i++)
                    {
                        if(loggedInUser.Organizations[index].Applications[i].Id !== applicationId)
                            continue;
                        loggedInUser.Organizations[index].Applications[i].Name = applicationName;
                        appIndex = i;
                        break;
                    }
                    if(appIndex > -1)
                    {
                        api.setLoggedInUser(loggedInUser);
                    }
                }
            }
        }
        yield put(applicationApiResponseSuccess(ApplicationActionTypes.APPLICATION_EDIT, ProcessData(response)));
    } catch (error) {;
        yield put(applicationApiResponseError(ApplicationActionTypes.APPLICATION_EDIT, ProcessError(error)));
    }
}

function* deleteApplication({ payload: { applicationId } }) {
    try {
        const response = yield call(deleteApplicationApi, { applicationId });

        const api = new APICore();
        const loggedInUser = api.getLoggedInUser();
        const selection = api.getSelection();
        if(loggedInUser.Organizations !== null && loggedInUser.Organizations !== undefined && Array.isArray(loggedInUser.Organizations))
        {
            let index = -1;
            for(let i = 0; i < loggedInUser.Organizations.length; i++)
            {
                if(loggedInUser.Organizations[i].Id !== selection.organizationId)
                    continue;
                index = i;
                break;
            }
            if (index > -1)
            {
                if(loggedInUser.Organizations[index].Applications !== null && loggedInUser.Organizations[index].Applications !== undefined && Array.isArray(loggedInUser.Organizations[index].Applications))
                {
                    let appIndex = -1;
                    for(let i = 0; i < loggedInUser.Organizations[index].Applications.length; i++)
                    {
                        if(loggedInUser.Organizations[index].Applications[i].Id !== applicationId)
                            continue;
                        appIndex = i;
                        break;
                    }
                    if(appIndex > -1)
                    {
                        loggedInUser.Organizations[index].Applications.splice(appIndex, 1);
                        api.setLoggedInUser(loggedInUser);
                    }
                }
            }
        }

        if(selection !== null && selection !== undefined && selection.applicationId === applicationId)
        {
            selection.applicationId = null;
            api.setSelection(selection);
        }

        yield put(applicationApiResponseSuccess(ApplicationActionTypes.APPLICATION_DELETE, ProcessData(response)));
    } catch (error) {
        yield put(applicationApiResponseError(ApplicationActionTypes.APPLICATION_DELETE, ProcessError(error)));
    }
}

export function* watchApplicationInfo(): any {
    yield takeEvery(ApplicationActionTypes.APPLICATION_INFO, applicationInfo);
}
export function* watchCreateApplication(): any {
    yield takeEvery(ApplicationActionTypes.APPLICATION_CREATE, createApplication);
}
export function* watchEditApplication(): any {
    yield takeEvery(ApplicationActionTypes.APPLICATION_EDIT, editApplication);
}
export function* watchDeleteApplication(): any {
    yield takeEvery(ApplicationActionTypes.APPLICATION_DELETE, deleteApplication);
}

function* applicationSage(): any {
    yield all([
        fork(watchApplicationInfo),
        fork(watchCreateApplication),
        fork(watchEditApplication),
        fork(watchDeleteApplication),
    ]);
}

export default applicationSage;
