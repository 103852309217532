import Languages from "../locales/options";

const LANGUAGE_SESSION_KEY = 'selectedLanguage';
function setLanguage(language) {
    sessionStorage.setItem(LANGUAGE_SESSION_KEY, language);
}
function getLanguage() {
    let selectedLanguage = sessionStorage.getItem(LANGUAGE_SESSION_KEY);
    if(selectedLanguage === null || selectedLanguage === undefined || selectedLanguage === "" || Languages[selectedLanguage] === null || Languages[selectedLanguage] === undefined)
    {
        selectedLanguage = "en";
        setLanguage(selectedLanguage);
    }
    return selectedLanguage;
}

export { setLanguage, getLanguage, Languages };
