import config from '../../../config';

const BaseURL = config.HUB_URL +"/";
const HubAPIConstants = {
    RegisterURL: BaseURL + "users/register/email",
    ValidateEmailURL: BaseURL + "users/register/email/validate",
    LoginURL: BaseURL + "users/login/email",
    LogoutURL: BaseURL + "users/logout",
    ForgetPasswordURL: BaseURL + "users/login/email/forget",
    ResetPasswordURL: BaseURL + "users/login/email/reset",

    VerifyURL: BaseURL + "users/validate",

    UserUpdateURL: BaseURL + "users/update",
    UserInfoURL: BaseURL + "users/info",

    OrganizationInfoURL: BaseURL + "organizations/info",
    OrganizationCreateURL: BaseURL + "organizations/create",
    OrganizationEditURL: BaseURL + "organizations/edit",
    OrganizationDeleteURL: BaseURL + "organizations/delete",
    OrganizationAddUserURL: BaseURL + "organizations/add",
    OrganizationRemoveUserURL: BaseURL + "organizations/remove",

    ApplicationInfoURL: BaseURL + "applications/info",
    ApplicationCreateURL: BaseURL + "applications/create",
    ApplicationEditURL: BaseURL + "applications/edit",
    ApplicationDeleteURL: BaseURL + "applications/delete",

    ServicesInfoURL: BaseURL + "services",
    ServiceAccessURL: BaseURL + "services/access",

    HelpSupportURL: BaseURL + "support",
};

export default HubAPIConstants;
