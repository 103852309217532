// @flow
import { ApplicationActionTypes } from './constants';

type ApplicationAction = { type: string, payload: {} | string };

// common success
export const applicationApiResponseSuccess = (actionType: string, data: any): ApplicationAction => ({
    type: ApplicationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const applicationApiResponseError = (actionType: string, error: string): ApplicationAction => ({
    type: ApplicationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Organization
export const applicationInfo = (applications: Array): ApplicationAction => ({
    type: ApplicationActionTypes.APPLICATION_INFO,
    payload: { applications: applications },
});
export const createApplication = (applicationName: string): ApplicationAction => ({
    type: ApplicationActionTypes.APPLICATION_CREATE,
    payload: { applicationName: applicationName },
});
export const editApplication = (applicationId: string, applicationName: string): ApplicationAction => ({
    type: ApplicationActionTypes.APPLICATION_EDIT,
    payload: { applicationId: applicationId, applicationName: applicationName },
});
export const deleteApplication = (applicationId: string): ApplicationAction => ({
    type: ApplicationActionTypes.APPLICATION_DELETE,
    payload: { applicationId: applicationId },
});

export const resetApplication = (): ApplicationAction => ({
    type: ApplicationActionTypes.RESET,
    payload: {},
});
