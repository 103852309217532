export const TranslationsActionTypes = {
    API_RESPONSE_SUCCESS: '@@services/translations/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@services/translations/API_RESPONSE_ERROR',

    TRANSLATIONS_VALIDATE: '@@services/translations/VALIDATE',

    TRANSLATIONS_KEY_INFO: '@@services/translations/KEY_INFO',
    TRANSLATIONS_KEY_ADD: '@@services/translations/KEY_ADD',
    TRANSLATIONS_KEY_REMOVE: '@@services/translations/KEY_REMOVE',
    TRANSLATIONS_KEY_RESET: '@@services/translations/KEY_RESET',

    TRANSLATIONS_LANGUAGES_INFO: '@@services/translations/LANGUAGES_INFO',
    TRANSLATIONS_LANGUAGES_ADD: '@@services/translations/LANGUAGES_ADD',
    TRANSLATIONS_LANGUAGES_REMOVE: '@@services/translations/LANGUAGES_REMOVE',
    TRANSLATIONS_LANGUAGES_DEFAULT: '@@services/translations/LANGUAGES_DEFAULT',
    TRANSLATIONS_LANGUAGES_SYNC: '@@services/translations/LANGUAGES_SYNC',
    TRANSLATIONS_LANGUAGES_RESET: '@@services/translations/LANGUAGES_RESET',

    TRANSLATIONS_VALUES_INFO: '@@services/translations/VALUES_INFO',
    TRANSLATIONS_VALUES_ADD: '@@services/translations/VALUES_ADD',
    TRANSLATIONS_VALUES_UPDATE: '@@services/translations/VALUES_UPDATE',
    TRANSLATIONS_VALUES_REMOVE: '@@services/translations/VALUES_REMOVE',
    TRANSLATIONS_VALUES_IMPORT: '@@services/translations/VALUES_IMPORT',
    TRANSLATIONS_VALUES_RESET: '@@services/translations/VALUES_RESET',

    TRANSLATIONS_FLAGS: '@@services/translations/FLAGS',

    RESET: '@@services/translations/RESET',
};
