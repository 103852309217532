// @flow
import { combineReducers } from 'redux';

import { dashboardReducers } from './dashboard/reducers';
import { servicesReducers } from './services/reducers';
import Layout from './layout/reducers';

export default (combineReducers({
    ...dashboardReducers,
    ...servicesReducers,
    Layout,
}): any);
