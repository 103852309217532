export const MailerActionTypes = {
    API_RESPONSE_SUCCESS: '@@services/mailer/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@services/mailer/API_RESPONSE_ERROR',

    MAILER_VALIDATE: '@@services/mailer/VALIDATE',

    MAILER_KEY_INFO: '@@services/mailer/KEY_INFO',
    MAILER_KEY_ADD: '@@services/mailer/KEY_ADD',
    MAILER_KEY_REMOVE: '@@services/mailer/KEY_REMOVE',
    MAILER_KEY_RESET: '@@services/mailer/KEY_RESET',

    MAILER_TOKEN_INFO: '@@services/mailer/TOKEN_INFO',
    MAILER_TOKEN_ADD: '@@services/mailer/TOKEN_ADD',
    MAILER_TOKEN_REMOVE: '@@services/mailer/TOKEN_REMOVE',
    MAILER_TOKEN_RESET: '@@services/mailer/TOKEN_RESET',

    MAILER_MAIL_SEND: '@@services/mailer/MAIL_SEND',
    MAILER_MAIL_RESET: '@@services/mailer/MAIL_RESET',

    RESET: '@@services/mailer/RESET',
};
