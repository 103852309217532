export const CredentialsActionTypes = {
    API_RESPONSE_SUCCESS: '@@services/credentials/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@services/credentials/API_RESPONSE_ERROR',

    CREDENTIALS_VALIDATE: '@@services/credentials/VALIDATE',

    CREDENTIALS_GIT_INFO: '@@services/credentials/GIT_INFO',
    CREDENTIALS_GIT_ADD: '@@services/credentials/GIT_ADD',
    CREDENTIALS_GIT_EDIT: '@@services/credentials/GIT_EDIT',
    CREDENTIALS_GIT_SYNC: '@@services/credentials/GIT_SYNC',
    CREDENTIALS_GIT_REMOVE: '@@services/credentials/GIT_REMOVE',
    CREDENTIALS_GIT_RESET: '@@services/credentials/GIT_RESET',

    CREDENTIALS_UNITY_INFO: '@@services/credentials/UNITY_INFO',
    CREDENTIALS_UNITY_ADD: '@@services/credentials/UNITY_ADD',
    CREDENTIALS_UNITY_EDIT: '@@services/credentials/UNITY_EDIT',
    CREDENTIALS_UNITY_REMOVE: '@@services/credentials/UNITY_REMOVE',
    CREDENTIALS_UNITY_RESET: '@@services/credentials/UNITY_RESET',

    CREDENTIALS_GOOGLE_PLAYSTORE_INFO: '@@services/credentials/GOOGLE_PLAYSTORE_INFO',
    CREDENTIALS_GOOGLE_PLAYSTORE_ADD: '@@services/credentials/GOOGLE_PLAYSTORE_ADD',
    CREDENTIALS_GOOGLE_PLAYSTORE_EDIT: '@@services/credentials/GOOGLE_PLAYSTORE_EDIT',
    CREDENTIALS_GOOGLE_PLAYSTORE_SYNC: '@@services/credentials/GOOGLE_PLAYSTORE_SYNC',
    CREDENTIALS_GOOGLE_PLAYSTORE_REMOVE: '@@services/credentials/GOOGLE_PLAYSTORE_REMOVE',
    CREDENTIALS_GOOGLE_PLAYSTORE_RESET: '@@services/credentials/GOOGLE_PLAYSTORE_RESET',

    CREDENTIALS_GOOGLE_KEYSTORE_INFO: '@@services/credentials/GOOGLE_KEYSTORE_INFO',
    CREDENTIALS_GOOGLE_KEYSTORE_ADD: '@@services/credentials/GOOGLE_KEYSTORE_ADD',
    CREDENTIALS_GOOGLE_KEYSTORE_EDIT: '@@services/credentials/GOOGLE_KEYSTORE_EDIT',
    CREDENTIALS_GOOGLE_KEYSTORE_PASSWORD: '@@services/credentials/GOOGLE_KEYSTORE_PASSWORD',
    CREDENTIALS_GOOGLE_KEYSTORE_SYNC: '@@services/credentials/GOOGLE_KEYSTORE_SYNC',
    CREDENTIALS_GOOGLE_KEYSTORE_REMOVE: '@@services/credentials/GOOGLE_KEYSTORE_REMOVE',
    CREDENTIALS_GOOGLE_KEYSTORE_RESET: '@@services/credentials/GOOGLE_KEYSTORE_RESET',

    CREDENTIALS_APPLE_APPSTORE_INFO: '@@services/credentials/APPLE_APPSTORE_INFO',
    CREDENTIALS_APPLE_APPSTORE_ADD: '@@services/credentials/APPLE_APPSTORE_ADD',
    CREDENTIALS_APPLE_APPSTORE_EDIT: '@@services/credentials/APPLE_APPSTORE_EDIT',
    CREDENTIALS_APPLE_APPSTORE_SYNC: '@@services/credentials/APPLE_APPSTORE_SYNC',
    CREDENTIALS_APPLE_APPSTORE_REMOVE: '@@services/credentials/APPLE_APPSTORE_REMOVE',
    CREDENTIALS_APPLE_APPSTORE_RESET: '@@services/credentials/APPLE_APPSTORE_RESET',

    CREDENTIALS_APPLE_CERTIFICATE_INFO: '@@services/credentials/APPLE_CERTIFICATE_INFO',
    CREDENTIALS_APPLE_CERTIFICATE_ADD: '@@services/credentials/APPLE_CERTIFICATE_ADD',
    CREDENTIALS_APPLE_CERTIFICATE_EDIT: '@@services/credentials/APPLE_CERTIFICATE_EDIT',
    CREDENTIALS_APPLE_CERTIFICATE_SYNC: '@@services/credentials/APPLE_CERTIFICATE_SYNC',
    CREDENTIALS_APPLE_CERTIFICATE_REMOVE: '@@services/credentials/APPLE_CERTIFICATE_REMOVE',
    CREDENTIALS_APPLE_CERTIFICATE_RESET: '@@services/credentials/APPLE_CERTIFICATE_RESET',

    CREDENTIALS_APPLE_PROVISION_INFO: '@@services/credentials/APPLE_PROVISION_INFO',
    CREDENTIALS_APPLE_PROVISION_ADD: '@@services/credentials/APPLE_PROVISION_ADD',
    CREDENTIALS_APPLE_PROVISION_REMOVE: '@@services/credentials/APPLE_PROVISION_REMOVE',
    CREDENTIALS_APPLE_PROVISION_RESET: '@@services/credentials/APPLE_PROVISION_RESET',

    RESET: '@@services/credentials/RESET',
};
