// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    buildManagerValidate as buildManagerValidateApi,

    buildManagerSettingsInfo as buildManagerSettingsInfoApi,
    buildManagerSettingsGit as buildManagerSettingsGitApi,
    buildManagerSettingsUpdate as buildManagerSettingsUpdateApi,

    buildManagerFlowInfo as buildManagerFlowInfoApi,
    buildManagerFlowEssential as buildManagerFlowEssentialApi,
    buildManagerFlowAdd as buildManagerFlowAddApi,
    buildManagerFlowEdit as buildManagerFlowEditApi,
    buildManagerFlowRemove as buildManagerFlowRemoveApi,

    buildManagerBuildInfo as buildManagerBuildInfoApi,
    buildManagerBuildStart as buildManagerBuildStartApi,
    buildManagerBuildUnlock as buildManagerBuildUnlockApi,
    buildManagerBuildRemove as buildManagerBuildRemoveApi,

    buildManagerBuildInfoDetails as buildManagerBuildInfoDetailsApi,
    buildManagerBuildInfoDownload as buildManagerBuildInfoDownloadApi,
    buildManagerBuildInfoLog as buildManagerBuildInfoLogApi,

    buildManagerPublicBuildInfo as buildManagerPublicBuildInfoApi,
} from '../../../helpers/api/servicesActions/build-manager';

import { buildManagerApiResponseSuccess, buildManagerApiResponseError } from './actions';
import { BuildManagerActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* buildManagerValidate() {
    try {
        const response = yield call(buildManagerValidateApi);
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_VALIDATE, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_VALIDATE, ProcessError(error)));
    }
}
function* buildManagerSettingsInfo() {
    try {
        const response = yield call(buildManagerSettingsInfoApi);
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_INFO, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_INFO, ProcessError(error)));
    }
}
function* buildManagerSettingsGit() {
    try {
        const response = yield call(buildManagerSettingsGitApi);
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_GIT, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_GIT, ProcessError(error)));
    }
}
function* buildManagerSettingsUpdate({ payload: { gitId, gitRepo, unityId, unityVersion } }) {
    try {
        const response = yield call(buildManagerSettingsUpdateApi, { gitId, gitRepo, unityId, unityVersion });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_UPDATE, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_UPDATE, ProcessError(error)));
    }
}
function* buildManagerFlowInfo({ payload: { flowIds } }) {
    try {
        const response = yield call(buildManagerFlowInfoApi, { flowIds });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_FLOW_INFO, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_FLOW_INFO, ProcessError(error)));
    }
}
function* buildManagerFlowEssential() {
    try {
        const response = yield call(buildManagerFlowEssentialApi);
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_FLOW_ESSENTIAL, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_FLOW_ESSENTIAL, ProcessError(error)));
    }
}
function* buildManagerFlowAdd({ payload: { flowName, gitBranch, bundleId, platform, environment, credentialId, credentialSelection, storeId, password,
    settingsBuildAddressables, settingsAddressablesProfile, settingsPrebuildMethod } }) {
    try {
        const response = yield call(buildManagerFlowAddApi, { flowName, gitBranch, bundleId, platform, environment, credentialId, credentialSelection, storeId, password,
            settingsBuildAddressables, settingsAddressablesProfile, settingsPrebuildMethod });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_FLOW_ADD, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_FLOW_ADD, ProcessError(error)));
    }
}
function* buildManagerFlowEdit({ payload: { flowId, flowName, gitBranch, bundleId, platform, environment, credentialId, credentialSelection, storeId, password,
    settingsBuildAddressables, settingsAddressablesProfile, settingsPrebuildMethod } }) {
    try {
        const response = yield call(buildManagerFlowEditApi, { flowId, flowName, gitBranch, bundleId, platform, environment, credentialId, credentialSelection, storeId, password,
            settingsBuildAddressables, settingsAddressablesProfile, settingsPrebuildMethod });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_FLOW_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_FLOW_EDIT, ProcessError(error)));
    }
}
function* buildManagerFlowRemove({ payload: { flowId } }) {
    try {
        const response = yield call(buildManagerFlowRemoveApi, { flowId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_FLOW_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_FLOW_REMOVE, ProcessError(error)));
    }
}
function* buildManagerBuildInfo({ payload: { buildIds, flowId } }) {
    try {
        const response = yield call(buildManagerBuildInfoApi, { buildIds, flowId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO, ProcessError(error)));
    }
}
function* buildManagerBuildStart({ payload: { flowId } }) {
    try {
        const response = yield call(buildManagerBuildStartApi, { flowId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_BUILD_START, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_BUILD_START, ProcessError(error)));
    }
}
function* buildManagerBuildRemove({ payload: { buildId } }) {
    try {
        const response = yield call(buildManagerBuildRemoveApi, { buildId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_BUILD_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_BUILD_REMOVE, ProcessError(error)));
    }
}
function* buildManagerBuildUnlock({ payload: { buildId } }) {
    try {
        const response = yield call(buildManagerBuildUnlockApi, { buildId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_BUILD_UNLOCK, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_BUILD_UNLOCK, ProcessError(error)));
    }
}
function* buildManagerPublicBuildInfo({ payload: { buildId, password } }) {
    try {
        const response = yield call(buildManagerPublicBuildInfoApi, { buildId, password });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_INFO, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_INFO, ProcessError(error)));
    }
}
function* buildManagerBuildInfoDetails({ payload: { buildId } }) {
    try {
        const response = yield call(buildManagerBuildInfoDetailsApi, { buildId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DETAILS, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DETAILS, ProcessError(error)));
    }
}
function* buildManagerBuildInfoDownload({ payload: { buildId } }) {
    try {
        const response = yield call(buildManagerBuildInfoDownloadApi, { buildId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DOWNLOAD, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DOWNLOAD, ProcessError(error)));
    }
}
function* buildManagerBuildInfoLog({ payload: { buildId } }) {
    try {
        const response = yield call(buildManagerBuildInfoLogApi, { buildId });
        yield put(buildManagerApiResponseSuccess(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_LOG, ProcessData(response)));
    } catch (error) {
        yield put(buildManagerApiResponseError(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_LOG, ProcessError(error)));
    }
}

export function* watchBuildManagerValidate(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_VALIDATE, buildManagerValidate);
}
export function* watchBuildManagerSettingsInfo(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_INFO, buildManagerSettingsInfo);
}
export function* watchBuildManagerSettingsGit(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_GIT, buildManagerSettingsGit);
}
export function* watchBuildManagerSettingsUpdate(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_SETTINGS_UPDATE, buildManagerSettingsUpdate);
}
export function* watchBuildManagerFlowInfo(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_FLOW_INFO, buildManagerFlowInfo);
}
export function* watchBuildManagerFlowEssential(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_FLOW_ESSENTIAL, buildManagerFlowEssential);
}
export function* watchBuildManagerFlowAdd(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_FLOW_ADD, buildManagerFlowAdd);
}
export function* watchBuildManagerFlowEdit(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_FLOW_EDIT, buildManagerFlowEdit);
}
export function* watchBuildManagerFlowRemove(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_FLOW_REMOVE, buildManagerFlowRemove);
}
export function* watchBuildManagerBuildInfo(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO, buildManagerBuildInfo);
}
export function* watchBuildManagerBuildStart(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_BUILD_START, buildManagerBuildStart);
}
export function* watchBuildManagerBuildUnlock(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_BUILD_UNLOCK, buildManagerBuildUnlock);
}
export function* watchBuildManagerBuildRemove(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_BUILD_REMOVE, buildManagerBuildRemove);
}
export function* watchBuildManagerPublicBuildInfo(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_INFO, buildManagerPublicBuildInfo);
}
export function* watchBuildManagerBuildInfoDetails(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DETAILS, buildManagerBuildInfoDetails);
}
export function* watchBuildManagerBuildInfoDownload(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DOWNLOAD, buildManagerBuildInfoDownload);
}
export function* watchBuildManagerBuildInfoLog(): any {
    yield takeEvery(BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_LOG, buildManagerBuildInfoLog);
}

function* packageManagerSage(): any {
    yield all([
        fork(watchBuildManagerValidate),
        fork(watchBuildManagerSettingsInfo),
        fork(watchBuildManagerSettingsGit),
        fork(watchBuildManagerSettingsUpdate),

        fork(watchBuildManagerFlowInfo),
        fork(watchBuildManagerFlowEssential),
        fork(watchBuildManagerFlowAdd),
        fork(watchBuildManagerFlowEdit),
        fork(watchBuildManagerFlowRemove),

        fork(watchBuildManagerBuildInfo),
        fork(watchBuildManagerBuildStart),
        fork(watchBuildManagerBuildUnlock),
        fork(watchBuildManagerBuildRemove),

        fork(watchBuildManagerBuildInfoDetails),
        fork(watchBuildManagerBuildInfoDownload),
        fork(watchBuildManagerBuildInfoLog),

        fork(watchBuildManagerPublicBuildInfo),
    ]);
}

export default packageManagerSage;
