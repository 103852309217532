// @flow
import { APICore } from '../apiCore';
import services from '../../../constants/services';

// Routes
import { PackageManagerAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// packageManager
function packageManagerValidate(): any {
    return api.post(PackageManagerAPIConstants.ValidateURL, {}, api.getServiceHeaders(services.PackageManager));
}

function packageManagerKeysInfo(params: any): any {
    return api.post(PackageManagerAPIConstants.APIKeysInfoURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerAddKey(params: any): any {
    return api.post(PackageManagerAPIConstants.APIKeyAddURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerRemoveKey(params: any): any {
    return api.post(PackageManagerAPIConstants.APIKeyRemoveURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerPackagesInfo(params: any): any {
    return api.post(PackageManagerAPIConstants.PackagesInfoURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerPackageGit(params: any): any {
    return api.post(PackageManagerAPIConstants.PackageGitURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerAddPackage(params: any): any {
    return api.post(PackageManagerAPIConstants.PackageAddURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerEditPackage(params: any): any {
    return api.post(PackageManagerAPIConstants.PackageEditURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerSyncPackage(params: any): any {
    return api.post(PackageManagerAPIConstants.PackageSyncURL, params, api.getServiceHeaders(services.PackageManager));
}

function packageManagerRemovePackage(params: any): any {
    return api.post(PackageManagerAPIConstants.PackageRemoveURL, params, api.getServiceHeaders(services.PackageManager));
}

export { packageManagerValidate,
    packageManagerKeysInfo, packageManagerAddKey, packageManagerRemoveKey,
    packageManagerPackagesInfo, packageManagerPackageGit, packageManagerAddPackage, packageManagerEditPackage, packageManagerSyncPackage, packageManagerRemovePackage,
};
