import config from '../../../config';

const BaseURL = config.TRANSLATIONS_URL +"/";
const TranslationsAPIConstants = {
    ValidateURL: BaseURL + "validate",

    APIKeysInfoURL: BaseURL + "keys",
    APIKeyAddURL: BaseURL + "keys/add",
    APIKeyRemoveURL: BaseURL + "keys/remove",

    LanguagesInfoURL: BaseURL + "languages",
    LanguagesAddURL: BaseURL + "languages/add",
    LanguagesRemoveURL: BaseURL + "languages/remove",
    LanguagesDefaultURL: BaseURL + "languages/default",
    LanguagesSyncURL: BaseURL + "languages/sync",

    ValuesInfoURL: BaseURL + "value",
    ValuesAddURL: BaseURL + "value/add",
    ValuesUpdateURL: BaseURL + "value/update",
    ValuesRemoveURL: BaseURL + "value/remove",
    ValuesImportURL: BaseURL + "value/import",

    FlagsURL: BaseURL + "flags",
};

export default TranslationsAPIConstants;
