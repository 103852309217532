// @flow
import { MailerActionTypes } from './constants';
import config from '../../../config';
import { APICore } from "../../../helpers/api/apiCore";
import services from "../../../constants/services";

const INIT_STATE = {
    loading: false,
    Key: {
        loading: false,
    },
    Token: {
        loading: false,
    },
    Mail: {
        loading: false,
    },
};

type MailerAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Mailer = (state: State = INIT_STATE, action: MailerAction): any => {
    if(config.DEBUG === true && Object.values(MailerActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case MailerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MailerActionTypes.MAILER_VALIDATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        validated: true,
                    };
                }
                case MailerActionTypes.MAILER_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keysData = action.payload.data;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case MailerActionTypes.MAILER_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyAdded = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case MailerActionTypes.MAILER_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyRemoved = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case MailerActionTypes.MAILER_TOKEN_INFO: {
                    const tokenState = state.Token ?? {};
                    tokenState.loading = false;
                    tokenState.error = false;
                    tokenState.tokensData = action.payload.data;
                    return {
                        ...state,
                        Token: tokenState,
                    };
                }
                case MailerActionTypes.MAILER_TOKEN_ADD: {
                    const tokenState = state.Token ?? {};
                    tokenState.loading = false;
                    tokenState.error = false;
                    tokenState.tokenAdded = true;
                    return {
                        ...state,
                        Token: tokenState,
                    };
                }
                case MailerActionTypes.MAILER_TOKEN_REMOVE: {
                    const tokenState = state.Token ?? {};
                    tokenState.loading = false;
                    tokenState.error = false;
                    tokenState.tokenRemoved = true;
                    return {
                        ...state,
                        Token: tokenState,
                    };
                }
                case MailerActionTypes.MAILER_MAIL_SEND: {
                    const tokenState = state.Mail ?? {};
                    tokenState.loading = false;
                    tokenState.error = false;
                    tokenState.mailSent = true;
                    return {
                        ...state,
                        Mail: tokenState,
                    };
                }
                default:
                    return { ...state };
            }

        case MailerActionTypes.API_RESPONSE_ERROR:
            if(action.payload.error.includes("MAI-E-UV-AUW-"))
            {
                const api = new APICore();
                api.setServiceAccess(services.Mailer, null);
            }
            switch (action.payload.actionType) {
                case MailerActionTypes.MAILER_VALIDATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        validated: false,
                    };
                }
                case MailerActionTypes.MAILER_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keysData = [];
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case MailerActionTypes.MAILER_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyAdded = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case MailerActionTypes.MAILER_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyRemoved = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case MailerActionTypes.MAILER_TOKEN_INFO: {
                    const tokenState = state.Token ?? {};
                    tokenState.loading = false;
                    tokenState.error = action.payload.error;
                    tokenState.tokensData = [];
                    return {
                        ...state,
                        Token: tokenState,
                    };
                }
                case MailerActionTypes.MAILER_TOKEN_ADD: {
                    const tokenState = state.Token ?? {};
                    tokenState.loading = false;
                    tokenState.error = action.payload.error;
                    tokenState.tokenAdded = false;
                    return {
                        ...state,
                        Token: tokenState,
                    };
                }
                case MailerActionTypes.MAILER_TOKEN_REMOVE: {
                    const tokenState = state.Token ?? {};
                    tokenState.loading = false;
                    tokenState.error = action.payload.error;
                    tokenState.tokenRemoved = false;
                    return {
                        ...state,
                        Token: tokenState,
                    };
                }
                case MailerActionTypes.MAILER_MAIL_SEND: {
                    const tokenState = state.Mail ?? {};
                    tokenState.loading = false;
                    tokenState.error = action.payload.error;
                    tokenState.mailSent = false;
                    return {
                        ...state,
                        Mail: tokenState,
                    };
                }
                default:
                    return { ...state };
            }
        case MailerActionTypes.MAILER_VALIDATE:
            return { ...state, loading: true, validated: false };
        case MailerActionTypes.MAILER_KEY_INFO: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keysData = null;
            return {...state, Key: keyState};
        }
        case MailerActionTypes.MAILER_KEY_ADD: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyAdded = false;
            return {...state, Key: keyState};
        }
        case MailerActionTypes.MAILER_KEY_REMOVE:{
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyRemoved = false;
            return {...state, Key: keyState};
        }
        case MailerActionTypes.MAILER_TOKEN_INFO: {
            const tokenState = state.Token ?? {};
            tokenState.loading = true;
            tokenState.tokensData = null;
            return {...state, Token: tokenState};
        }
        case MailerActionTypes.MAILER_TOKEN_ADD: {
            const tokenState = state.Token ?? {};
            tokenState.loading = true;
            tokenState.tokenAdded = false;
            return {...state, Token: tokenState};
        }
        case MailerActionTypes.MAILER_TOKEN_REMOVE:{
            const tokenState = state.Token ?? {};
            tokenState.loading = true;
            tokenState.tokenRemoved = false;
            return {...state, Token: tokenState};
        }
        case MailerActionTypes.MAILER_MAIL_SEND:{
            const tokenState = state.Mail ?? {};
            tokenState.loading = true;
            tokenState.mailSent = false;
            return {...state, Mail: tokenState};
        }
        case MailerActionTypes.MAILER_KEY_RESET:
            return {
                ...state,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
            };
        case MailerActionTypes.MAILER_TOKEN_RESET:
            return {
                ...state,
                Token: {
                    loading: false,
                    error: false,
                    tokensData: null,
                    tokenAdded: false,
                    tokenRemoved: false,
                },
            };
        case MailerActionTypes.MAILER_MAIL_RESET:
            return {
                ...state,
                Mail: {
                    loading: false,
                    error: false,
                    mailSent: false,
                },
            };
        case MailerActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                validated: false,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
                Token: {
                    loading: false,
                    error: false,
                    tokensData: null,
                    tokenAdded: false,
                    tokenRemoved: false,
                },
                Mail: {
                    loading: false,
                    error: false,
                    mailSent: false,
                },
            };
        default:
            return { ...state };
    }
};

export default Mailer;
