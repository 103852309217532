// @flow
import { PackageManagerActionTypes } from './constants';

type PackageManagerAction = { type: string, payload: {} | string };

// common success
export const packageManagerApiResponseSuccess = (actionType: string, data: any): PackageManagerAction => ({
    type: PackageManagerActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const packageManagerApiResponseError = (actionType: string, error: string): PackageManagerAction => ({
    type: PackageManagerActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Package Manager
export const packageManagerValidate = (): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_VALIDATE,
    payload: {},
});

export const resetPackageManager = (): PackageManagerAction => ({
    type: PackageManagerActionTypes.RESET,
    payload: {},
});

// Keys
export const packageManagerKeyInfo = (keys: any): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_KEY_INFO,
    payload: { keysIds: keys },
});
export const packageManagerAddKey = (keyName: string): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_KEY_ADD,
    payload: { keyName: keyName },
});
export const packageManagerRemoveKey = (keyId: string): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_KEY_REMOVE,
    payload: { keyId: keyId },
});
export const resetPackageManagerKey = (): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_KEY_RESET,
    payload: {},
});

// Packages
export const packageManagerPackagesInfo = (packagesIds: any): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_INFO,
    payload: { packagesIds: packagesIds },
});
export const packageManagerGitPackage = (): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_GIT,
    payload: {},
});
export const packageManagerAddPackage = (gitId: string, gitRepo: string, gitBranch: string, isPublic: boolean): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_ADD,
    payload: { gitId: gitId, gitRepo: gitRepo, gitBranch: gitBranch, isPublic: isPublic },
});
export const packageManagerEditPackage = (packageId: string, gitId: string, gitRepo: string, gitBranch: string, isPublic: boolean): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_EDIT,
    payload: { packageId: packageId, gitId: gitId, gitRepo: gitRepo, gitBranch: gitBranch, isPublic: isPublic },
});
export const packageManagerSyncPackage = (packageId: string): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_SYNC,
    payload: { packageId: packageId },
});
export const packageManagerRemovePackage = (packageId: string, packageVersion: string): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_REMOVE,
    payload: { packageId: packageId, packageVersion: packageVersion },
});
export const resetPackageManagerPackages = (): PackageManagerAction => ({
    type: PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_RESET,
    payload: {},
});
