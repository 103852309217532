import Mailer from './mailer/reducers';
import RemoteSettings from './remote-settings/reducers';
import Translations from './translations/reducers';
import Credentials from './credentials/reducers';
import ManageCredentials from './credentials/manage/reducers';
import PackageManager from './package-manager/reducers';
import BuildManager from './build-manager/reducers';

const servicesReducers = {
    Mailer,
    RemoteSettings,
    Translations,
    Credentials,
    ManageCredentials,
    PackageManager,
    BuildManager,
};

export { servicesReducers };
