import main from './main.json';
import languagesList from './languagesList.json';
import addLanguage from './addLanguage.json';
import removeLanguage from './removeLanguage.json';
import defaultLanguage from './defaultLanguage.json';
import syncLanguage from './syncLanguage.json';

const output = Object.assign({}, main, languagesList, addLanguage, removeLanguage, defaultLanguage, syncLanguage);

export default output;
