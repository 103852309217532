// @flow
import { HelpActionTypes } from './constants';

type HelpAction = { type: string, payload: {} | string };

// common success
export const helpApiResponseSuccess = (actionType: string, data: any): HelpAction => ({
    type: HelpActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const helpApiResponseError = (actionType: string, error: string): HelpAction => ({
    type: HelpActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Help
export const helpSupport = (subject: string, message: string): HelpAction => ({
    type: HelpActionTypes.HELP_SUPPORT,
    payload: { subject: subject, message: message },
});

export const resetHelp = (): HelpAction => ({
    type: HelpActionTypes.RESET,
    payload: {},
});
