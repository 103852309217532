import config from '../../../config';

const BaseURL = config.CREDENTIALS_URL +"/";
const CredentialsManageAPIConstants = {
    ManageAppleCertificateInfoURL: BaseURL + "apple/manage/certificate",
    ManageAppleCertificateAddURL: BaseURL + "apple/manage/certificate/add",
    ManageAppleCertificateRemoveURL: BaseURL + "apple/manage/certificate/remove",

    ManageAppleDeviceInfoURL: BaseURL + "apple/manage/device",
    ManageAppleDeviceAddURL: BaseURL + "apple/manage/device/add",
    ManageAppleDeviceToggleURL: BaseURL + "apple/manage/device/status",

    ManageAppleBundleIdInfoURL: BaseURL + "apple/manage/bundleId",
    ManageAppleBundleIdAddURL: BaseURL + "apple/manage/bundleId/add",
    ManageAppleBundleIdRemoveURL: BaseURL + "apple/manage/bundleId/remove",

    ManageAppleProvisionProfileInfoURL: BaseURL + "apple/manage/provisionProfile",
    ManageAppleProvisionProfileAddURL: BaseURL + "apple/manage/provisionProfile/add",
    ManageAppleProvisionProfileImportURL: BaseURL + "apple/manage/provisionProfile/import",
    ManageAppleProvisionProfileRemoveURL: BaseURL + "apple/manage/provisionProfile/remove",
};

export default CredentialsManageAPIConstants;
