import mailer from './mailer';
import remoteSettings from './remote-settings';
import translations from './translations';
import credentials from './credentials';
import packageManager from './package-manager';
import buildManager from './build-manager';

const output = Object.assign(
    {}, mailer,
    remoteSettings,
    translations,
    credentials,
    packageManager,
    buildManager,
);

export default output;
