export const OrganizationActionTypes = {
    API_RESPONSE_SUCCESS: '@@organization/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@organization/API_RESPONSE_ERROR',

    ORGANIZATION_INFO: '@@organization/ORGANIZATION_INFO',
    ORGANIZATION_CREATE: '@@organization/ORGANIZATION_CREATE',
    ORGANIZATION_EDIT: '@@organization/ORGANIZATION_EDIT',
    ORGANIZATION_DELETE: '@@organization/ORGANIZATION_DELETE',

    ORGANIZATION_ADD_USER: '@@organization/ORGANIZATION_ADD_USER',
    ORGANIZATION_REMOVE_USER: '@@organization/ORGANIZATION_REMOVE_USER',

    RESET: '@@organization/RESET',
};
