// @flow
import { APICore } from '../apiCore';

// Routes
import { HubAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// organizations
function organizationInfo(params: any): any {
    return api.post(HubAPIConstants.OrganizationInfoURL, params);
}

function createOrganization(params: any): any {
    return api.post(HubAPIConstants.OrganizationCreateURL, params);
}

function editOrganization(params: any): any {
    const headers = {
        "X-Organization": params.organizationId,
    };
    delete params.organizationId;
    return api.post(HubAPIConstants.OrganizationEditURL, params, headers);
}

function deleteOrganization(params: any): any {
    const headers = {
        "X-Organization": params.organizationId,
    };
    delete params.organizationId;
    return api.post(HubAPIConstants.OrganizationDeleteURL, params, headers);
}

// users
function addUserToOrganization(params: any): any {
    const headers = {
        "X-Organization": params.organizationId,
    };
    delete params.organizationId;
    return api.post(HubAPIConstants.OrganizationAddUserURL, params, headers);
}

function removeUserFromOrganization(params: any): any {
    const headers = {
        "X-Organization": params.organizationId,
    };
    delete params.organizationId;
    return api.post(HubAPIConstants.OrganizationRemoveUserURL, params, headers);
}

export { organizationInfo, createOrganization, editOrganization, deleteOrganization, addUserToOrganization, removeUserFromOrganization };
