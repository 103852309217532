// @flow
import { ApplicationActionTypes } from './constants';
import config from "../../../config";

const INIT_STATE = {
    loading: false,
};

type UserAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Application = (state: State = INIT_STATE, action: UserAction): any => {
    if(config.DEBUG === true && Object.values(ApplicationActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case ApplicationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ApplicationActionTypes.APPLICATION_INFO: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        applicationsData: action.payload.data,
                    };
                }
                case ApplicationActionTypes.APPLICATION_CREATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        applicationCreated: true,
                    };
                }
                case ApplicationActionTypes.APPLICATION_EDIT: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        applicationEdited: true,
                    };
                }
                case ApplicationActionTypes.APPLICATION_DELETE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        applicationDeleted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case ApplicationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ApplicationActionTypes.APPLICATION_INFO: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        applicationsData: [],
                    };
                }
                case ApplicationActionTypes.APPLICATION_CREATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        applicationCreated: false,
                    };
                }
                case ApplicationActionTypes.APPLICATION_EDIT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        applicationEdited: false,
                    };
                }
                case ApplicationActionTypes.APPLICATION_DELETE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        applicationDeleted: false,
                    };
                }
                default:
                    return { ...state };
            }
        case ApplicationActionTypes.APPLICATION_INFO:
            return { ...state, loading: true, applicationsData: [] };
        case ApplicationActionTypes.APPLICATION_CREATE:
            return { ...state, loading: true, applicationCreated: false };
        case ApplicationActionTypes.APPLICATION_EDIT:
            return { ...state, loading: true, applicationEdited: false };
        case ApplicationActionTypes.APPLICATION_DELETE:
            return { ...state, loading: true, applicationDeleted: false };
        case ApplicationActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                applicationCreated: false,
                applicationEdited: false,
                applicationDeleted: false,
                applicationsData: [],
            };
        default:
            return { ...state };
    }
};

export default Application;
