// @flow
import { APICore } from '../apiCore';
import services from '../../../constants/services';

// Routes
import { TranslationsAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// translations
function translationsValidate(): any {
    return api.post(TranslationsAPIConstants.ValidateURL, {}, api.getServiceHeaders(services.Translations));
}

function translationsKeysInfo(params: any): any {
    return api.post(TranslationsAPIConstants.APIKeysInfoURL, params, api.getServiceHeaders(services.Translations));
}

function translationsAddKey(params: any): any {
    return api.post(TranslationsAPIConstants.APIKeyAddURL, params, api.getServiceHeaders(services.Translations));
}

function translationsRemoveKey(params: any): any {
    return api.post(TranslationsAPIConstants.APIKeyRemoveURL, params, api.getServiceHeaders(services.Translations));
}

function translationsLanguagesInfo(params: any): any {
    return api.post(TranslationsAPIConstants.LanguagesInfoURL, params, api.getServiceHeaders(services.Translations));
}

function translationsAddLanguage(params: any): any {
    return api.post(TranslationsAPIConstants.LanguagesAddURL, params, api.getServiceHeaders(services.Translations));
}

function translationsRemoveLanguage(params: any): any {
    return api.post(TranslationsAPIConstants.LanguagesRemoveURL, params, api.getServiceHeaders(services.Translations));
}

function translationsDefaultLanguage(params: any): any {
    return api.post(TranslationsAPIConstants.LanguagesDefaultURL, params, api.getServiceHeaders(services.Translations));
}

function translationsSyncLanguage(params: any): any {
    return api.post(TranslationsAPIConstants.LanguagesSyncURL, params, api.getServiceHeaders(services.Translations));
}

function translationsValuesInfo(params: any): any {
    return api.post(TranslationsAPIConstants.ValuesInfoURL, params, api.getServiceHeaders(services.Translations));
}

function translationsAddValues(params: any): any {
    return api.post(TranslationsAPIConstants.ValuesAddURL, params, api.getServiceHeaders(services.Translations));
}

function translationsUpdateValues(params: any): any {
    return api.post(TranslationsAPIConstants.ValuesUpdateURL, params, api.getServiceHeaders(services.Translations));
}

function translationsRemoveValues(params: any): any {
    return api.post(TranslationsAPIConstants.ValuesRemoveURL, params, api.getServiceHeaders(services.Translations));
}

function translationsImportValues(params: any): any {
    return api.postWithFile(TranslationsAPIConstants.ValuesImportURL,
        {languageId: params.languageId, newValues: params.newValues, missingValues: params.missingValues, existingValues: params.existingValues},
        "file", params.file,
        api.getServiceHeaders(services.Translations));
}

function translationsFlags(params: any): any {
    return api.post(TranslationsAPIConstants.FlagsURL, params, api.getServiceHeaders(services.Translations));
}

export { translationsValidate,
    translationsKeysInfo, translationsAddKey, translationsRemoveKey,
    translationsLanguagesInfo, translationsAddLanguage, translationsRemoveLanguage, translationsDefaultLanguage, translationsSyncLanguage,
    translationsValuesInfo, translationsAddValues, translationsUpdateValues, translationsRemoveValues,
    translationsImportValues, translationsFlags,
};
