const config = {
    DEBUG: process.env.REACT_APP_DEBUG === 'true',

    MAINTENANCE: process.env.REACT_APP_MAINTENANCE === 'true',
    ALLOW_REGISTER: process.env.REACT_APP_ALLOW_REGISTER === 'true',

    BASE_URL: process.env.REACT_APP_BASE_URL,
    HUB_URL: process.env.REACT_APP_HUB_URL,
    MAILER_URL: process.env.REACT_APP_MAILER_URL,
    REMOTESETTINGS_URL: process.env.REACT_APP_REMOTESETTINGS_URL,
    TRANSLATIONS_URL: process.env.REACT_APP_TRANSLATIONS_URL,
    CREDENTIALS_URL: process.env.REACT_APP_CREDENTIALS_URL,
    PACKAGEMANAGER_URL: process.env.REACT_APP_PACKAGEMANAGER_URL,
    BUILDMANAGER_URL: process.env.REACT_APP_BUILDMANAGER_URL,

    MAILER_ID: process.env.REACT_APP_MAILER_ID,
    REMOTESETTINGS_ID: process.env.REACT_APP_REMOTESETTINGS_ID,
    TRANSLATIONS_ID: process.env.REACT_APP_TRANSLATIONS_ID,
    CREDENTIALS_ID: process.env.REACT_APP_CREDENTIALS_ID,
    PACKAGEMANAGER_ID: process.env.REACT_APP_PACKAGEMANAGER_ID,
    BUILDMANAGER_ID: process.env.REACT_APP_BUILDMANAGER_ID,
};

export default config;
