// @flow
import { APICore } from '../apiCore';

// Routes
import { HubAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// user
function userUpdate(params: any): any {
    return api.post(HubAPIConstants.UserUpdateURL, params);
}
function userInfo(params: any): any {
    return api.post(HubAPIConstants.UserInfoURL, params);
}

export { userUpdate, userInfo };
