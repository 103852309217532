import main from './main.json';
import error from './error';
import components from './components';
import auth from './auth';
import hub from './hub';
import services from './services';

const output = Object.assign({}, main, error, components, auth, hub, services);

export default output;
