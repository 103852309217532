// @flow
import { BuildManagerActionTypes } from './constants';
import config from '../../../config';
import { APICore } from "../../../helpers/api/apiCore";
import services from "../../../constants/services";

const INIT_STATE = {
    loading: false,
    Settings: {
        loading: false,
    },
    Flow: {
        loading: false,
    },
    Build: {
        loading: false,
        Info: {},
    },
    Public: {
        Build: {
            loading: false,
        },
    },
};

type BuildManagerAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const BuildManager = (state: State = INIT_STATE, action: BuildManagerAction): any => {
    if(config.DEBUG === true && Object.values(BuildManagerActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case BuildManagerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BuildManagerActionTypes.BUILDMANAGER_VALIDATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        validated: true,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_INFO: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = false;
                    settingsState.settingsData = action.payload.data;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_GIT: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = false;
                    settingsState.settingsGit = action.payload.data;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_UPDATE: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = false;
                    settingsState.settingsUpdated = true;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_INFO: {
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = false;
                    flowState.flowData = action.payload.data;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_ESSENTIAL: {
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = false;
                    flowState.flowEssentials = action.payload.data;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_ADD:{
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = false;
                    flowState.flowAdded = true;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_EDIT:{
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = false;
                    flowState.flowEdited = true;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_REMOVE:{
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = false;
                    flowState.flowRemoved = true;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    buildState.buildData = action.payload.data;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_START: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    buildState.buildStarted = true;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_UNLOCK: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    buildState.buildUnlocked = true;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_REMOVE: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    buildState.buildRemoved = true;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DETAILS: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    const infoState = buildState.Info ?? {};
                    infoState["Details"] = action.payload.data;
                    buildState.Info = infoState;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DOWNLOAD: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    const infoState = buildState.Info ?? {};
                    infoState["Download"] = action.payload.data;
                    buildState.Info = infoState;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_LOG: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    const infoState = buildState.Info ?? {};
                    infoState["Log"] = action.payload.data;
                    buildState.Info = infoState;
                    return {...state, Build: buildState};
                }
                // PUBLIC
                case BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_INFO: {
                    const publicState = state.Public ?? {};
                    const buildState = publicState.Build ?? {};
                    buildState.loading = false;
                    buildState.error = false;
                    buildState.buildData = action.payload.data;
                    publicState.Build = buildState;
                    return {...state, Public: publicState};
                }
                default:
                    return { ...state };
            }

        case BuildManagerActionTypes.API_RESPONSE_ERROR:
            if(action.payload.error.includes("BMG-E-UV-AUW-"))
            {
                const api = new APICore();
                api.setServiceAccess(services.BuildManager, null);
            }
            switch (action.payload.actionType) {
                case BuildManagerActionTypes.BUILDMANAGER_VALIDATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        validated: false,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_INFO: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = action.payload.error;
                    settingsState.settingsData = {};
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_GIT: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = action.payload.error;
                    settingsState.settingsGit = [];
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_UPDATE: {
                    const settingsState = state.Settings ?? {};
                    settingsState.loading = false;
                    settingsState.error = action.payload.error;
                    settingsState.settingsUpdated = false;
                    return {
                        ...state,
                        Settings: settingsState,
                    };
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_INFO: {
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = action.payload.error;
                    flowState.flowData = [];
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_ESSENTIAL: {
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = action.payload.error;
                    flowState.flowEssentials = {};
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_ADD:{
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = action.payload.error;
                    flowState.flowAdded = false;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_EDIT:{
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = action.payload.error;
                    flowState.flowEdited = false;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_FLOW_REMOVE:{
                    const flowState = state.Flow ?? {};
                    flowState.loading = false;
                    flowState.error = action.payload.error;
                    flowState.flowRemoved = false;
                    return {...state, Flow: flowState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    buildState.buildData = [];
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_START: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    buildState.buildStarted = false;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_UNLOCK: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    buildState.buildUnlocked = false;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_REMOVE: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    buildState.buildRemoved = false;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DETAILS: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    const infoState = buildState.Info ?? {};
                    infoState["Details"] = [];
                    buildState.Info = infoState;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DOWNLOAD: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    const infoState = buildState.Info ?? {};
                    infoState["Download"] = {};
                    buildState.Info = infoState;
                    return {...state, Build: buildState};
                }
                case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_LOG: {
                    const buildState = state.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    const infoState = buildState.Info ?? {};
                    infoState["Log"] = [];
                    buildState.Info = infoState;
                    return {...state, Build: buildState};
                }
                // PUBLIC
                case BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_INFO: {
                    const publicState = state.Public ?? {};
                    const buildState = publicState.Build ?? {};
                    buildState.loading = false;
                    buildState.error = action.payload.error;
                    buildState.buildData = {};
                    publicState.Build = buildState;
                    return {...state, Public: publicState};
                }
                default:
                    return { ...state };
            }
        case BuildManagerActionTypes.BUILDMANAGER_VALIDATE:
            return { ...state, loading: true, validated: false };
        case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_INFO: {
            const settingsState = state.Settings ?? {};
            settingsState.loading = true;
            settingsState.settingsData = null;
            return {...state, Settings: settingsState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_GIT: {
            const settingsState = state.Settings ?? {};
            settingsState.loading = true;
            settingsState.settingsGit = null;
            return {...state, Settings: settingsState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_UPDATE:{
            const settingsState = state.Settings ?? {};
            settingsState.loading = true;
            settingsState.settingsUpdated = false;
            return {...state, Settings: settingsState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_FLOW_INFO: {
            const flowState = state.Flow ?? {};
            flowState.loading = true;
            flowState.flowData = null;
            return {...state, Flow: flowState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_FLOW_ESSENTIAL: {
            const flowState = state.Flow ?? {};
            flowState.loading = true;
            flowState.flowEssentials = null;
            return {...state, Flow: flowState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_FLOW_ADD:{
            const flowState = state.Flow ?? {};
            flowState.loading = true;
            flowState.flowAdded = false;
            return {...state, Flow: flowState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_FLOW_EDIT:{
            const flowState = state.Flow ?? {};
            flowState.loading = true;
            flowState.flowEdited = false;
            return {...state, Flow: flowState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_FLOW_REMOVE:{
            const flowState = state.Flow ?? {};
            flowState.loading = true;
            flowState.flowRemoved = false;
            return {...state, Flow: flowState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO: {
            const buildState = state.Build ?? {};
            buildState.loading = true;
            buildState.buildData = null;
            return {...state, Build: buildState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_START: {
            const buildState = state.Build ?? {};
            buildState.loading = true;
            buildState.buildStarted = false;
            return {...state, Build: buildState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_UNLOCK: {
            const buildState = state.Build ?? {};
            buildState.loading = true;
            buildState.buildUnlocked = false;
            return {...state, Build: buildState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_REMOVE: {
            const buildState = state.Build ?? {};
            buildState.loading = true;
            buildState.buildRemoved = false;
            return {...state, Build: buildState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DETAILS: {
            const buildState = state.Build ?? {};
            buildState.loading = true;
            buildState.error = false;
            const infoState = buildState.Info ?? {};
            infoState["Details"] = null;
            buildState.Info = infoState;
            return {...state, Build: buildState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_DOWNLOAD: {
            const buildState = state.Build ?? {};
            buildState.loading = true;
            buildState.error = false;
            const infoState = buildState.Info ?? {};
            infoState["Download"] = null;
            buildState.Info = infoState;
            return {...state, Build: buildState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_INFO_LOG: {
            const buildState = state.Build ?? {};
            buildState.loading = true;
            buildState.error = false;
            const infoState = buildState.Info ?? {};
            infoState["Log"] = null;
            buildState.Info = infoState;
            return {...state, Build: buildState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_SETTINGS_RESET:
            return {
                ...state,
                Settings: {
                    loading: false,
                    error: false,
                    settingsData: null,
                    settingsGit: null,
                    settingsUpdated: false,
                },
            };
        case BuildManagerActionTypes.BUILDMANAGER_FLOW_RESET:
            return {
                ...state,
                Flow: {
                    loading: false,
                    error: false,
                    flowData: null,
                    flowEssentials: null,
                    flowAdded: false,
                    flowEdited: false,
                    flowRemoved: false,
                },
            };
        case BuildManagerActionTypes.BUILDMANAGER_BUILD_RESET:
            return {
                ...state,
                Build: {
                    loading: false,
                    error: false,
                    buildData: null,
                    buildStarted: false,
                    buildUnlocked: false,
                    buildRemoved: false,
                    Info: {},
                },
            };
        case BuildManagerActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                validated: false,
                Settings: {
                    loading: false,
                    error: false,
                    settingsData: null,
                    settingsGit: null,
                    settingsUpdated: false,
                },
                Flow: {
                    loading: false,
                    error: false,
                    flowData: null,
                    flowEssentials: null,
                    flowAdded: false,
                    flowEdited: false,
                    flowRemoved: false,
                },
                Build: {
                    loading: false,
                    error: false,
                    buildData: null,
                    buildStarted: false,
                    buildUnlocked: false,
                    buildRemoved: false,
                    Info: {},
                },
            };
        // PUBLIC
        case BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_INFO: {
            const publicState = state.Public ?? {};
            const buildState = publicState.Build ?? {};
            buildState.loading = true;
            buildState.buildData = {};
            publicState.Build = buildState;
            return {...state, Public: publicState};
        }
        case BuildManagerActionTypes.BUILDMANAGER_PUBLIC_BUILD_RESET:
            return {
                ...state,
                Public: {
                    Build: {
                        loading: false,
                        error: false,
                        buildData: null,
                    },
                },
            };
        default:
            return { ...state };
    }
};

export default BuildManager;
