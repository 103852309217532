import BasePath from "./Base";

const DashboardRoutes = {
    BaseURL: BasePath,

    // Dashboard
    DefaultURL: BasePath + "dashboard",
    AccountURL: BasePath + "account",

    // Help
    HelpURL: BasePath + "help",
    FAQURL: BasePath + "help/faq",
    KnowledgeBaseURL: BasePath + "help/knowledgeBase",
    SupportURL: BasePath + "help/support",

    // + Auth
    AuthURL: BasePath + "auth",
    LoginURL: BasePath + "auth/login",
    RegisterURL: BasePath + "auth/register",
    RegisterConfirmURL: BasePath + "auth/register/confirm",
    ValidateEmailURL: BasePath + "auth/register/validate",
    ForgotPasswordURL: BasePath + "auth/forgot",
    ResetPasswordURL: BasePath + "auth/reset",
    LogoutURL: BasePath + "auth/logout",

    // + Organizations
    OrganizationsURL: BasePath + "organizations",
    CreateOrganizationURL: BasePath + "organizations/create",
    EditOrganizationURL: BasePath + "organizations/edit",
    DeleteOrganizationURL: BasePath + "organizations/delete",
    AddUserToOrganizationURL: BasePath + "organizations/add",
    RemoveUserFromOrganizationURL: BasePath + "organizations/remove",

    // + Applications
    ApplicationsURL: BasePath + "applications",
    CreateApplicationURL: BasePath + "applications/create",
    EditApplicationURL: BasePath + "applications/edit",
    DeleteApplicationURL: BasePath + "applications/delete",
};

export default DashboardRoutes;
