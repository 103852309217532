// @flow
import { PackageManagerActionTypes } from './constants';
import config from '../../../config';
import { APICore } from "../../../helpers/api/apiCore";
import services from "../../../constants/services";

const INIT_STATE = {
    loading: false,
    Key: {
        loading: false,
    },
    Packages: {
        loading: false,
    },
};

type PackageManagerAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const PackageManager = (state: State = INIT_STATE, action: PackageManagerAction): any => {
    if(config.DEBUG === true && Object.values(PackageManagerActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case PackageManagerActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PackageManagerActionTypes.PACKAGEMANAGER_VALIDATE: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        validated: true,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keysData = action.payload.data;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyAdded = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = false;
                    keyState.keyRemoved = true;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_INFO: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = false;
                    packageState.packagesData = action.payload.data;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_GIT: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = false;
                    packageState.packagesGit = action.payload.data;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_ADD: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = false;
                    packageState.packageAdded = true;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_EDIT: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = false;
                    packageState.packageEdited = true;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_SYNC: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = false;
                    packageState.packageSynced = true;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_REMOVE: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = false;
                    packageState.packageRemoved = true;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                default:
                    return { ...state };
            }

        case PackageManagerActionTypes.API_RESPONSE_ERROR:
            if(action.payload.error.includes("PMG-E-UV-AUW-"))
            {
                const api = new APICore();
                api.setServiceAccess(services.PackageManager, null);
            }
            switch (action.payload.actionType) {
                case PackageManagerActionTypes.PACKAGEMANAGER_VALIDATE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        validated: false,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_KEY_INFO: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keysData = [];
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_KEY_ADD: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyAdded = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_KEY_REMOVE: {
                    const keyState = state.Key ?? {};
                    keyState.loading = false;
                    keyState.error = action.payload.error;
                    keyState.keyRemoved = false;
                    return {
                        ...state,
                        Key: keyState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_INFO: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = action.payload.error;
                    packageState.packagesData = [];
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_GIT: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = action.payload.error;
                    packageState.packagesGit = [];
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_ADD: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = action.payload.error;
                    packageState.packageAdded = false;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_EDIT: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = action.payload.error;
                    packageState.packageEdited = false;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_SYNC: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = action.payload.error;
                    packageState.packageSynced = false;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_REMOVE: {
                    const packageState = state.Packages ?? {};
                    packageState.loading = false;
                    packageState.error = action.payload.error;
                    packageState.packageRemoved = false;
                    return {
                        ...state,
                        Packages: packageState,
                    };
                }
                default:
                    return { ...state };
            }
        case PackageManagerActionTypes.PACKAGEMANAGER_VALIDATE:
            return { ...state, loading: true, validated: false };
        case PackageManagerActionTypes.PACKAGEMANAGER_KEY_INFO: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keysData = null;
            return {...state, Key: keyState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_KEY_ADD: {
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyAdded = false;
            return {...state, Key: keyState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_KEY_REMOVE:{
            const keyState = state.Key ?? {};
            keyState.loading = true;
            keyState.keyRemoved = false;
            return {...state, Key: keyState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_INFO: {
            const packageState = state.Packages ?? {};
            packageState.loading = true;
            packageState.packagesData = null;
            return {...state, Packages: packageState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_GIT: {
            const packageState = state.Packages ?? {};
            packageState.loading = true;
            packageState.packagesGit = null;
            return {...state, Packages: packageState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_ADD: {
            const packageState = state.Packages ?? {};
            packageState.loading = true;
            packageState.packageAdded = false;
            return {...state, Packages: packageState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_EDIT: {
            const packageState = state.Packages ?? {};
            packageState.loading = true;
            packageState.packageEdited = false;
            return {...state, Packages: packageState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_SYNC:{
            const packageState = state.Packages ?? {};
            packageState.loading = true;
            packageState.packageSynced = false;
            return {...state, Packages: packageState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_REMOVE:{
            const packageState = state.Packages ?? {};
            packageState.loading = true;
            packageState.packageRemoved = false;
            return {...state, Packages: packageState};
        }
        case PackageManagerActionTypes.PACKAGEMANAGER_KEY_RESET:
            return {
                ...state,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
            };
        case PackageManagerActionTypes.PACKAGEMANAGER_PACKAGE_RESET:
            return {
                ...state,
                Packages: {
                    loading: false,
                    error: false,
                    packagesData: null,
                    packagesGit: null,
                    packageAdded: false,
                    packageEdited: false,
                    packageSynced: false,
                    packageRemoved: false,
                },
            };
        case PackageManagerActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                validated: false,
                Key: {
                    loading: false,
                    error: false,
                    keysData: null,
                    keyAdded: false,
                    keyRemoved: false,
                },
                Packages: {
                    loading: false,
                    error: false,
                    packagesData: null,
                    packagesGit: null,
                    packageAdded: false,
                    packageEdited: false,
                    packageSynced: false,
                    packageRemoved: false,
                },
            };
        default:
            return { ...state };
    }
};

export default PackageManager;
