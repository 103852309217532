// @flow
import { HelpActionTypes } from './constants';
import config from '../../../config';

const INIT_STATE = {
    loading: false,
};

type HelpAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Help = (state: State = INIT_STATE, action: HelpAction): any => {
    if(config.DEBUG === true && Object.values(HelpActionTypes).includes(action.type))
    {
        console.log(action);
    }
    switch (action.type) {
        case HelpActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case HelpActionTypes.HELP_SUPPORT: {
                    return {
                        ...state,
                        error: false,
                        loading: false,
                        supportSent: true,
                    };
                }
                default:
                    return { ...state };
            }

        case HelpActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case HelpActionTypes.HELP_SUPPORT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        supportSent: false,
                    };
                }
                default:
                    return { ...state };
            }
        case HelpActionTypes.HELP_SUPPORT:
            return { ...state, loading: true, supportSent: false };
        case HelpActionTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                supportSent: false,
            };
        default:
            return { ...state };
    }
};

export default Help;
