// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    mailerValidate as mailerValidateApi,

    mailerKeysInfo as mailerKeysInfoApi,
    mailerAddKey as mailerAddKeyApi,
    mailerRemoveKey as mailerRemoveKeyApi,

    mailerTokensInfo as mailerTokensInfoApi,
    mailerAddToken as mailerAddTokenApi,
    mailerRemoveToken as mailerRemoveTokenApi,

    mailerMailSend as mailerMailSendApi,
} from '../../../helpers/api/servicesActions/mailer';

import { mailerApiResponseSuccess, mailerApiResponseError } from './actions';
import { MailerActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* mailerValidate() {
    try {
        const response = yield call(mailerValidateApi);
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_VALIDATE, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_VALIDATE, ProcessError(error)));
    }
}
function* mailerKeysInfo({ payload: { keysIds } }) {
    try {
        const response = yield call(mailerKeysInfoApi, { keysIds });
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_KEY_INFO, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_KEY_INFO, ProcessError(error)));
    }
}
function* mailerAddKey({ payload: { keyName } }) {
    try {
        const response = yield call(mailerAddKeyApi, { keyName });
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_KEY_ADD, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_KEY_ADD, ProcessError(error)));
    }
}
function* mailerRemoveKey({ payload: { keyId } }) {
    try {
        const response = yield call(mailerRemoveKeyApi, { keyId });
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_KEY_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_KEY_REMOVE, ProcessError(error)));
    }
}
function* mailerTokensInfo({ payload: { tokens } }) {
    try {
        const response = yield call(mailerTokensInfoApi, { tokens });
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_TOKEN_INFO, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_TOKEN_INFO, ProcessError(error)));
    }
}
function* mailerAddToken({ payload: { tokenName, tokenProvider, from, token, isGlobal } }) {
    try {
        const response = yield call(mailerAddTokenApi, { tokenName, tokenProvider, from, token, isGlobal });
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_TOKEN_ADD, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_TOKEN_ADD, ProcessError(error)));
    }
}
function* mailerRemoveToken({ payload: { tokenId } }) {
    try {
        const response = yield call(mailerRemoveTokenApi, { tokenId });
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_TOKEN_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_TOKEN_REMOVE, ProcessError(error)));
    }
}
function* mailerMailSend({ payload: { token, to, subject, message } }) {
    try {
        const response = yield call(mailerMailSendApi, { token, to, subject, message });
        yield put(mailerApiResponseSuccess(MailerActionTypes.MAILER_MAIL_SEND, ProcessData(response)));
    } catch (error) {
        yield put(mailerApiResponseError(MailerActionTypes.MAILER_MAIL_SEND, ProcessError(error)));
    }
}

export function* watchMailerValidate(): any {
    yield takeEvery(MailerActionTypes.MAILER_VALIDATE, mailerValidate);
}
export function* watchMailerKeysInfo(): any {
    yield takeEvery(MailerActionTypes.MAILER_KEY_INFO, mailerKeysInfo);
}
export function* watchMailerAddKey(): any {
    yield takeEvery(MailerActionTypes.MAILER_KEY_ADD, mailerAddKey);
}
export function* watchMailerRemoveKey(): any {
    yield takeEvery(MailerActionTypes.MAILER_KEY_REMOVE, mailerRemoveKey);
}
export function* watchMailerTokensInfo(): any {
    yield takeEvery(MailerActionTypes.MAILER_TOKEN_INFO, mailerTokensInfo);
}
export function* watchMailerAddToken(): any {
    yield takeEvery(MailerActionTypes.MAILER_TOKEN_ADD, mailerAddToken);
}
export function* watchMailerRemoveToken(): any {
    yield takeEvery(MailerActionTypes.MAILER_TOKEN_REMOVE, mailerRemoveToken);
}
export function* watchMailerMailSend(): any {
    yield takeEvery(MailerActionTypes.MAILER_MAIL_SEND, mailerMailSend);
}

function* mailerSage(): any {
    yield all([
        fork(watchMailerValidate),
        fork(watchMailerKeysInfo),
        fork(watchMailerAddKey),
        fork(watchMailerRemoveKey),
        fork(watchMailerTokensInfo),
        fork(watchMailerAddToken),
        fork(watchMailerRemoveToken),
        fork(watchMailerMailSend),
    ]);
}

export default mailerSage;
