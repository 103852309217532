export const BuildManagerActionTypes = {
    API_RESPONSE_SUCCESS: '@@services/build-manager/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@services/build-manager/API_RESPONSE_ERROR',

    BUILDMANAGER_VALIDATE: '@@services/build-manager/VALIDATE',

    BUILDMANAGER_SETTINGS_INFO: '@@services/build-manager/SETTINGS_INFO',
    BUILDMANAGER_SETTINGS_GIT: '@@services/build-manager/SETTINGS_GIT',
    BUILDMANAGER_SETTINGS_UPDATE: '@@services/build-manager/SETTINGS_UPDATE',
    BUILDMANAGER_SETTINGS_RESET: '@@services/build-manager/SETTINGS_RESET',

    BUILDMANAGER_FLOW_INFO: '@@services/build-manager/FLOW_INFO',
    BUILDMANAGER_FLOW_ESSENTIAL: '@@services/build-manager/FLOW_ESSENTIAL',
    BUILDMANAGER_FLOW_ADD: '@@services/build-manager/FLOW_ADD',
    BUILDMANAGER_FLOW_EDIT: '@@services/build-manager/FLOW_EDIT',
    BUILDMANAGER_FLOW_REMOVE: '@@services/build-manager/FLOW_REMOVE',
    BUILDMANAGER_FLOW_RESET: '@@services/build-manager/FLOW_RESET',

    BUILDMANAGER_BUILD_INFO: '@@services/build-manager/BUILD_INFO',
    BUILDMANAGER_BUILD_START: '@@services/build-manager/BUILD_START',
    BUILDMANAGER_BUILD_UNLOCK: '@@services/build-manager/BUILD_UNLOCK',
    BUILDMANAGER_BUILD_REMOVE: '@@services/build-manager/BUILD_REMOVE',
    BUILDMANAGER_BUILD_RESET: '@@services/build-manager/BUILD_RESET',

    BUILDMANAGER_BUILD_INFO_DETAILS: '@@services/build-manager/BUILD_INFO/DETAILS',
    BUILDMANAGER_BUILD_INFO_DOWNLOAD: '@@services/build-manager/BUILD_INFO/DOWNLOAD',
    BUILDMANAGER_BUILD_INFO_LOG: '@@services/build-manager/BUILD_INFO/LOG',

    BUILDMANAGER_PUBLIC_BUILD_INFO: '@@services/build-manager/PUBLIC_BUILD_INFO',
    BUILDMANAGER_PUBLIC_BUILD_RESET: '@@services/build-manager/PUBLIC_BUILD_RESET',

    RESET: '@@services/build-manager/RESET',
};
