// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { APICore } from "../../../helpers/api/apiCore";

import {
    organizationInfo as organizationInfoApi,
    createOrganization as createOrganizationApi,
    editOrganization as editOrganizationApi,
    deleteOrganization as deleteOrganizationApi,
    addUserToOrganization as addUserToOrganizationApi,
    removeUserFromOrganization as removeUserFromOrganizationApi,
} from '../../../helpers/api/dashboardActions/organization';

import { organizationApiResponseSuccess, organizationApiResponseError } from './actions';
import { OrganizationActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* organizationInfo({ payload: { organizations } }) {
    try {
        const response = yield call(organizationInfoApi, { organizations });
        yield put(organizationApiResponseSuccess(OrganizationActionTypes.ORGANIZATION_INFO, ProcessData(response)));
    } catch (error) {
        yield put(organizationApiResponseError(OrganizationActionTypes.ORGANIZATION_INFO, ProcessError(error)));
    }
}

function* createOrganization({ payload: { organizationName } }) {
    try {
        const response = yield call(createOrganizationApi, { organizationName });
        const data = ProcessData(response);
        const api = new APICore();
        const loggedInUser = api.getLoggedInUser();
        if(loggedInUser.Organizations === null || loggedInUser.Organizations === undefined)
        {
            loggedInUser.Organizations = [];
        }
        loggedInUser.Organizations.push(data);
        api.setLoggedInUser(loggedInUser);

        yield put(organizationApiResponseSuccess(OrganizationActionTypes.ORGANIZATION_CREATE, data));
    } catch (error) {
        yield put(organizationApiResponseError(OrganizationActionTypes.ORGANIZATION_CREATE, ProcessError(error)));
    }
}

function* editOrganization({ payload: { organizationId, organizationName } }) {
    try {
        const response = yield call(editOrganizationApi, { organizationId, organizationName });

        const api = new APICore();
        const loggedInUser = api.getLoggedInUser();
        if(loggedInUser.Organizations !== null && loggedInUser.Organizations !== undefined && Array.isArray(loggedInUser.Organizations))
        {
            let index = -1;
            for(let i = 0; i < loggedInUser.Organizations.length; i++)
            {
                if(loggedInUser.Organizations[i].Id !== organizationId)
                    continue;
                loggedInUser.Organizations[i].Name = organizationName;
                index = i;
                break;
            }
            if (index > -1)
            {
                api.setLoggedInUser(loggedInUser);
            }
        }
        yield put(organizationApiResponseSuccess(OrganizationActionTypes.ORGANIZATION_EDIT, ProcessData(response)));
    } catch (error) {
        yield put(organizationApiResponseError(OrganizationActionTypes.ORGANIZATION_EDIT, ProcessError(error)));
    }
}

function* deleteOrganization({ payload: { organizationId } }) {
    try {
        const response = yield call(deleteOrganizationApi, { organizationId });

        const api = new APICore();
        const loggedInUser = api.getLoggedInUser();
        if(loggedInUser.Organizations !== null && loggedInUser.Organizations !== undefined && Array.isArray(loggedInUser.Organizations))
        {
            let index = -1;
            for(let i = 0; i < loggedInUser.Organizations.length; i++)
            {
                if(loggedInUser.Organizations[i].Id !== organizationId)
                    continue;
                index = i;
                break;
            }
            if (index > -1)
            {
                loggedInUser.Organizations.splice(index, 1);
                api.setLoggedInUser(loggedInUser);
            }
        }

        const selection = api.getSelection();
        if(selection !== null && selection !== undefined && selection.organizationId === organizationId)
        {
            api.setSelection(null);
        }

        yield put(organizationApiResponseSuccess(OrganizationActionTypes.ORGANIZATION_DELETE, ProcessData(response)));
    } catch (error) {
        yield put(organizationApiResponseError(OrganizationActionTypes.ORGANIZATION_DELETE, ProcessError(error)));
    }
}

function* addUserToOrganization({ payload: { organizationId, email } }) {
    try {
        const response = yield call(addUserToOrganizationApi, { organizationId, email });
        yield put(organizationApiResponseSuccess(OrganizationActionTypes.ORGANIZATION_ADD_USER, ProcessData(response)));
    } catch (error) {
        yield put(organizationApiResponseError(OrganizationActionTypes.ORGANIZATION_ADD_USER, ProcessError(error)));
    }
}

function* removeUserFromOrganization({ payload: { organizationId, userId } }) {
    try {
        const response = yield call(removeUserFromOrganizationApi, { organizationId, userId });

        const api = new APICore();
        const loggedInUser = api.getLoggedInUser();
        if((loggedInUser.Id === userId || userId === null || userId === undefined )&& loggedInUser.Organizations !== null && loggedInUser.Organizations !== undefined && Array.isArray(loggedInUser.Organizations))
        {
            let index = -1;
            for(let i = 0; i < loggedInUser.Organizations.length; i++)
            {
                if(loggedInUser.Organizations[i].Id !== organizationId)
                    continue;
                index = i;
                break;
            }
            if (index > -1)
            {
                loggedInUser.Organizations.splice(index, 1);
                api.setLoggedInUser(loggedInUser);
            }
        }

        yield put(organizationApiResponseSuccess(OrganizationActionTypes.ORGANIZATION_REMOVE_USER, ProcessData(response)));
    } catch (error) {
        yield put(organizationApiResponseError(OrganizationActionTypes.ORGANIZATION_REMOVE_USER, ProcessError(error)));
    }
}

export function* watchOrganizationInfo(): any {
    yield takeEvery(OrganizationActionTypes.ORGANIZATION_INFO, organizationInfo);
}
export function* watchCreateOrganization(): any {
    yield takeEvery(OrganizationActionTypes.ORGANIZATION_CREATE, createOrganization);
}
export function* watchEditOrganization(): any {
    yield takeEvery(OrganizationActionTypes.ORGANIZATION_EDIT, editOrganization);
}
export function* watchDeleteOrganization(): any {
    yield takeEvery(OrganizationActionTypes.ORGANIZATION_DELETE, deleteOrganization);
}
export function* watchAddUserToOrganization(): any {
    yield takeEvery(OrganizationActionTypes.ORGANIZATION_ADD_USER, addUserToOrganization);
}
export function* watchRemoveUserFromOrganization(): any {
    yield takeEvery(OrganizationActionTypes.ORGANIZATION_REMOVE_USER, removeUserFromOrganization);
}

function* userSage(): any {
    yield all([
        fork(watchOrganizationInfo),
        fork(watchCreateOrganization),
        fork(watchEditOrganization),
        fork(watchDeleteOrganization),
        fork(watchAddUserToOrganization),
        fork(watchRemoveUserFromOrganization),
    ]);
}

export default userSage;
