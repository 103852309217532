import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18n';

import App from './App';

import { Provider } from 'react-redux';
import { reactConfigureStore } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={reactConfigureStore({})}>
        <App />
    </Provider>
);
