// @flow
import { TranslationsActionTypes } from './constants';

type TranslationsAction = { type: string, payload: {} | string };

// common success
export const translationsApiResponseSuccess = (actionType: string, data: any): TranslationsAction => ({
    type: TranslationsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const translationsApiResponseError = (actionType: string, error: string): TranslationsAction => ({
    type: TranslationsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Translations
export const translationsValidate = (): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_VALIDATE,
    payload: {},
});

export const resetTranslations = (): TranslationsAction => ({
    type: TranslationsActionTypes.RESET,
    payload: {},
});

// Keys
export const translationsKeyInfo = (keys: any): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_KEY_INFO,
    payload: { keysIds: keys },
});
export const translationsAddKey = (keyName: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_KEY_ADD,
    payload: { keyName: keyName },
});
export const translationsRemoveKey = (keyId: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_KEY_REMOVE,
    payload: { keyId: keyId },
});
export const resetTranslationsKey = (): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_KEY_RESET,
    payload: {},
});

// Languages
export const translationsLanguagesInfo = (languagesIds: any): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_LANGUAGES_INFO,
    payload: { languagesIds: languagesIds },
});
export const translationsAddLanguage = (languageName: string, languageCode: string, automatic: boolean): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_LANGUAGES_ADD,
    payload: { languageName: languageName, languageCode: languageCode, automatic: automatic },
});
export const translationsRemoveLanguage = (languageId: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_LANGUAGES_REMOVE,
    payload: { languageId: languageId },
});
export const translationsDefaultLanguage = (languageId: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_LANGUAGES_DEFAULT,
    payload: { languageId: languageId },
});
export const translationsSyncLanguage = (languageId: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_LANGUAGES_SYNC,
    payload: { languageId: languageId },
});
export const resetTranslationsLanguages = (): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_LANGUAGES_RESET,
    payload: {},
});

// Values
export const translationsValuesInfo = (languageId: string, valueIds: any): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_VALUES_INFO,
    payload: { languageId: languageId, valueIds: valueIds },
});
export const translationsAddValues = (languageId: string, key: string, value: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_VALUES_ADD,
    payload: { languageId: languageId, key: key, value: value },
});
export const translationsUpdateValues = (languageId: string, key: string, value: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_VALUES_UPDATE,
    payload: { languageId: languageId, key: key, value: value },
});
export const translationsRemoveValues = (languageId: string, key: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_VALUES_REMOVE,
    payload: { languageId: languageId, key: key },
});
export const resetTranslationsValues = (): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_VALUES_RESET,
    payload: {},
});
export const translationsImportValues = (languageId: string, file: FileList, newValues: string, missingValues: string, existingValues: string): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_VALUES_IMPORT,
    payload: { languageId: languageId, file: file, newValues: newValues, missingValues: missingValues, existingValues: existingValues },
});

// Flags
export const translationsFlags = (): TranslationsAction => ({
    type: TranslationsActionTypes.TRANSLATIONS_FLAGS,
    payload: { },
});
