// @flow
import { APICore } from '../apiCore';
import services from '../../../constants/services';

// Routes
import { MailerAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// mailer
function mailerValidate(): any {
    return api.post(MailerAPIConstants.ValidateURL, {}, api.getServiceHeaders(services.Mailer));
}

function mailerKeysInfo(params: any): any {
    return api.post(MailerAPIConstants.APIKeysInfoURL, params, api.getServiceHeaders(services.Mailer));
}

function mailerAddKey(params: any): any {
    return api.post(MailerAPIConstants.APIKeyAddURL, params, api.getServiceHeaders(services.Mailer));
}

function mailerRemoveKey(params: any): any {
    return api.post(MailerAPIConstants.APIKeyRemoveURL, params, api.getServiceHeaders(services.Mailer));
}

function mailerTokensInfo(params: any): any {
    return api.post(MailerAPIConstants.TokensInfoURL, params, api.getServiceHeaders(services.Mailer));
}

function mailerAddToken(params: any): any {
    return api.post(MailerAPIConstants.TokenAddURL, params, api.getServiceHeaders(services.Mailer));
}

function mailerRemoveToken(params: any): any {
    return api.post(MailerAPIConstants.TokenRemoveURL, params, api.getServiceHeaders(services.Mailer));
}

function mailerMailSend(params: any): any {
    return api.post(MailerAPIConstants.SendURL, params, api.getServiceHeaders(services.Mailer));
}

export { mailerValidate,
    mailerKeysInfo, mailerAddKey, mailerRemoveKey,
    mailerTokensInfo, mailerAddToken, mailerRemoveToken,
    mailerMailSend
};
