// @flow
import { ManageCredentialsActionTypes } from './constants';

type ManageCredentialsAction = { type: string, payload: {} | string };

// common success
export const manageCredentialsApiResponseSuccess = (actionType: string, data: any): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const manageCredentialsApiResponseError = (actionType: string, error: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// Credentials
export const resetManageCredentials = (): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.RESET,
    payload: {},
});

// Certificate
export const manageCredentialsAppleCertificateInfo = (appStoreId: string, certificateIds: any): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_INFO,
    payload: { appStoreId: appStoreId, certificateIds: certificateIds },
});
export const manageCredentialsAppleCertificateAdd = (appStoreId: string, certName: string, certType: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_ADD,
    payload: { appStoreId: appStoreId, certName: certName, certType: certType },
});
export const manageCredentialsAppleCertificateRemove = (appStoreId: string, appleCertificateId: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_REMOVE,
    payload: { appStoreId: appStoreId, appleCertificateId: appleCertificateId },
});
export const resetManageCredentialsAppleCertificate = (): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_CERTIFICATE_RESET,
    payload: {},
});

// Device
export const manageCredentialsAppleDeviceInfo = (appStoreId: string, deviceIds: any): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_INFO,
    payload: { appStoreId: appStoreId, deviceIds: deviceIds },
});
export const manageCredentialsAppleDeviceAdd = (appStoreId: string, deviceName: string, deviceUdid: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_ADD,
    payload: { appStoreId: appStoreId, deviceName: deviceName, deviceUdid: deviceUdid },
});
export const manageCredentialsAppleDeviceToggle = (appStoreId: string, appleDeviceId: string, status: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_TOGGLE,
    payload: { appStoreId: appStoreId, appleDeviceId: appleDeviceId, status: status },
});
export const resetManageCredentialsAppleDevice = (): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_DEVICE_RESET,
    payload: {},
});

// Bundle Id
export const manageCredentialsAppleBundleIdInfo = (appStoreId: string, bundleIds: any): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_INFO,
    payload: { appStoreId: appStoreId, bundleIds: bundleIds },
});
export const manageCredentialsAppleBundleIdAdd = (appStoreId: string, bundleName: string, bundleId: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_ADD,
    payload: { appStoreId: appStoreId, bundleName: bundleName, bundleId: bundleId },
});
export const manageCredentialsAppleBundleIdRemove = (appStoreId: string, appleBundleId: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_REMOVE,
    payload: { appStoreId: appStoreId, appleBundleId: appleBundleId },
});
export const resetManageCredentialsAppleBundleId = (): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_BUNDLEID_RESET,
    payload: {},
});

// Provision Profile
export const manageCredentialsAppleProvisionProfileInfo = (appStoreId: string, provisionProfileIds: any): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_INFO,
    payload: { appStoreId: appStoreId, provisionProfileIds: provisionProfileIds },
});
export const manageCredentialsAppleProvisionProfileAdd = (appStoreId: string, profileName: string, bundleId: string, profileType: string, certificates: Array<string>, devices: Array<string>): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_ADD,
    payload: { appStoreId: appStoreId, profileName: profileName, bundleId: bundleId, profileType: profileType, certificates: certificates, devices: devices },
});
export const manageCredentialsAppleProvisionProfileImport = (appStoreId: string, appStoreProvisionProfileId: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_IMPORT,
    payload: { appStoreId: appStoreId, appStoreProvisionProfileId: appStoreProvisionProfileId },
});
export const manageCredentialsAppleProvisionProfileRemove = (appStoreId: string, appStoreProvisionProfileId: string): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_REMOVE,
    payload: { appStoreId: appStoreId, appStoreProvisionProfileId: appStoreProvisionProfileId },
});
export const resetManageCredentialsAppleProvisionProfile = (): ManageCredentialsAction => ({
    type: ManageCredentialsActionTypes.CREDENTIALS_MANAGE_APPLE_PROVISIONPROFILE_RESET,
    payload: {},
});
