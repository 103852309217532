import main from './main.json';
import buildList from './buildList.json';
import startBuild from './startBuild.json';
import unlockBuild from './unlockBuild.json';
import buildInfo from './buildInfo.json';
import removeBuild from './removeBuild.json';

const output = Object.assign({}, main, buildList, startBuild, unlockBuild, buildInfo, removeBuild);

export default output;
