//@flow
import React from 'react';

import { LoadComponent } from "../LoadComponent";
import { Navigate } from "react-router-dom";

// Routes
import { DashboardRoutesConstants } from '../../../constants/urls/routes';

// dashboard
const FAQ = React.lazy(() => import('../../../pages/hub/help/FAQ'));
const KnowledgeBase = React.lazy(() => import('../../../pages/hub/help/knowledgeBase'));
const Support = React.lazy(() => import('../../../pages/hub/help/Support'));

const HelpRouter = [
    { path: DashboardRoutesConstants.HelpURL, element: <Navigate to={DashboardRoutesConstants.SupportURL} />, },
    { path: DashboardRoutesConstants.FAQURL, element: <LoadComponent component={FAQ} />, },
    { path: DashboardRoutesConstants.KnowledgeBaseURL+"/*", element: <LoadComponent component={KnowledgeBase} />, },
    { path: DashboardRoutesConstants.SupportURL, element: <LoadComponent component={Support} />, },
];

export { HelpRouter };
