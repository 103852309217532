// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    translationsValidate as translationsValidateApi,

    translationsKeysInfo as translationsKeysInfoApi,
    translationsAddKey as translationsAddKeyApi,
    translationsRemoveKey as translationsRemoveKeyApi,

    translationsLanguagesInfo as translationsLanguagesInfoApi,
    translationsAddLanguage as translationsAddLanguageApi,
    translationsRemoveLanguage as translationsRemoveLanguageApi,
    translationsDefaultLanguage as translationsDefaultLanguageApi,
    translationsSyncLanguage as translationsSyncLanguageApi,

    translationsValuesInfo as translationsValuesInfoApi,
    translationsAddValues as translationsAddValuesApi,
    translationsUpdateValues as translationsUpdateValuesApi,
    translationsRemoveValues as translationsRemoveValuesApi,
    translationsImportValues as translationsImportValuesApi,

    translationsFlags as translationsFlagsApi,
} from '../../../helpers/api/servicesActions/translations';

import { translationsApiResponseSuccess, translationsApiResponseError } from './actions';
import { TranslationsActionTypes } from './constants';
import { ProcessData, ProcessError } from '../../generic';

function* translationsValidate() {
    try {
        const response = yield call(translationsValidateApi);
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_VALIDATE, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_VALIDATE, ProcessError(error)));
    }
}
function* translationsKeysInfo({ payload: { keysIds } }) {
    try {
        const response = yield call(translationsKeysInfoApi, { keysIds });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_KEY_INFO, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_KEY_INFO, ProcessError(error)));
    }
}
function* translationsAddKey({ payload: { keyName } }) {
    try {
        const response = yield call(translationsAddKeyApi, { keyName });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_KEY_ADD, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_KEY_ADD, ProcessError(error)));
    }
}
function* translationsRemoveKey({ payload: { keyId } }) {
    try {
        const response = yield call(translationsRemoveKeyApi, { keyId });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_KEY_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_KEY_REMOVE, ProcessError(error)));
    }
}
function* translationsLanguagesInfo({ payload: { languagesIds } }) {
    try {
        const response = yield call(translationsLanguagesInfoApi, { languagesIds });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_INFO, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_INFO, ProcessError(error)));
    }
}
function* translationsAddLanguage({ payload: { languageName, languageCode, automatic } }) {
    try {
        const response = yield call(translationsAddLanguageApi, { languageName, languageCode, automatic });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_ADD, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_ADD, ProcessError(error)));
    }
}
function* translationsRemoveLanguage({ payload: { languageId } }) {
    try {
        const response = yield call(translationsRemoveLanguageApi, { languageId });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_REMOVE, ProcessError(error)));
    }
}
function* translationsDefaultLanguage({ payload: { languageId } }) {
    try {
        const response = yield call(translationsDefaultLanguageApi, { languageId });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_DEFAULT, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_DEFAULT, ProcessError(error)));
    }
}
function* translationsSyncLanguage({ payload: { languageId } }) {
    try {
        const response = yield call(translationsSyncLanguageApi, { languageId });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_SYNC, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_SYNC, ProcessError(error)));
    }
}
function* translationsValuesInfo({ payload: { languageId, valueIds } }) {
    try {
        const response = yield call(translationsValuesInfoApi, { languageId, valueIds });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_VALUES_INFO, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_VALUES_INFO, ProcessError(error)));
    }
}
function* translationsAddValues({ payload: { languageId, key, value } }) {
    try {
        const response = yield call(translationsAddValuesApi, { languageId, key, value });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_VALUES_ADD, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_VALUES_ADD, ProcessError(error)));
    }
}
function* translationsUpdateValues({ payload: { languageId, key, value } }) {
    try {
        const response = yield call(translationsUpdateValuesApi, { languageId, key, value });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_VALUES_UPDATE, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_VALUES_UPDATE, ProcessError(error)));
    }
}
function* translationsRemoveValues({ payload: { languageId, key } }) {
    try {
        const response = yield call(translationsRemoveValuesApi, { languageId, key });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_VALUES_REMOVE, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_VALUES_REMOVE, ProcessError(error)));
    }
}
function* translationsImportValues({ payload: { languageId, file, newValues, missingValues, existingValues } }) {
    try {
        const response = yield call(translationsImportValuesApi, { languageId, file, newValues, missingValues, existingValues });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_VALUES_IMPORT, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_VALUES_IMPORT, ProcessError(error)));
    }
}
function* translationsFlags() {
    try {
        const response = yield call(translationsFlagsApi, { });
        yield put(translationsApiResponseSuccess(TranslationsActionTypes.TRANSLATIONS_FLAGS, ProcessData(response)));
    } catch (error) {
        yield put(translationsApiResponseError(TranslationsActionTypes.TRANSLATIONS_FLAGS, ProcessError(error)));
    }
}

export function* watchTranslationsValidate(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_VALIDATE, translationsValidate);
}
export function* watchTranslationsKeysInfo(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_KEY_INFO, translationsKeysInfo);
}
export function* watchTranslationsAddKey(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_KEY_ADD, translationsAddKey);
}
export function* watchTranslationsRemoveKey(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_KEY_REMOVE, translationsRemoveKey);
}
export function* watchTranslationsLanguagesInfo(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_INFO, translationsLanguagesInfo);
}
export function* watchTranslationsAddLanguage(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_ADD, translationsAddLanguage);
}
export function* watchTranslationsRemoveLanguage(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_REMOVE, translationsRemoveLanguage);
}
export function* watchTranslationsDefaultLanguage(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_DEFAULT, translationsDefaultLanguage);
}
export function* watchTranslationsSyncLanguage(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_LANGUAGES_SYNC, translationsSyncLanguage);
}
export function* watchTranslationsValuesInfo(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_VALUES_INFO, translationsValuesInfo);
}
export function* watchTranslationsAddValues(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_VALUES_ADD, translationsAddValues);
}
export function* watchTranslationsUpdateValues(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_VALUES_UPDATE, translationsUpdateValues);
}
export function* watchTranslationsRemoveValues(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_VALUES_REMOVE, translationsRemoveValues);
}
export function* watchTranslationsImportValues(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_VALUES_IMPORT, translationsImportValues);
}
export function* watchTranslationsFlags(): any {
    yield takeEvery(TranslationsActionTypes.TRANSLATIONS_FLAGS, translationsFlags);
}

function* translationsSage(): any {
    yield all([
        fork(watchTranslationsValidate),
        fork(watchTranslationsKeysInfo),
        fork(watchTranslationsAddKey),
        fork(watchTranslationsRemoveKey),
        fork(watchTranslationsLanguagesInfo),
        fork(watchTranslationsAddLanguage),
        fork(watchTranslationsRemoveLanguage),
        fork(watchTranslationsDefaultLanguage),
        fork(watchTranslationsSyncLanguage),
        fork(watchTranslationsValuesInfo),
        fork(watchTranslationsAddValues),
        fork(watchTranslationsUpdateValues),
        fork(watchTranslationsRemoveValues),
        fork(watchTranslationsImportValues),
        fork(watchTranslationsFlags),
    ]);
}

export default translationsSage;
