import BasePath from "./Base";
const RemoteSettingsRoutes = {
    // RemoteSettings
    RemoteSettingsURL: BasePath + "remoteSettings",

    // + API Keys
    RemoteSettingsAPIKeysURL: BasePath + "remoteSettings/apiKeys",
    RemoteSettingsAPIKeysAddURL: BasePath + "remoteSettings/apiKeys/add",
    RemoteSettingsAPIKeysRemoveURL: BasePath + "remoteSettings/apiKeys/remove",

    // + Settings
    RemoteSettingsSettingsURL: BasePath + "remoteSettings/settings",
    RemoteSettingsSettingsAddURL: BasePath + "remoteSettings/settings/add",
    RemoteSettingsSettingsRemoveURL: BasePath + "remoteSettings/settings/remove",

    // + Values
    RemoteSettingsValuesURL: BasePath + "remoteSettings/values",
    RemoteSettingsValuesAddURL: BasePath + "remoteSettings/values/add",
    RemoteSettingsValuesUpdateURL: BasePath + "remoteSettings/values/update",
    RemoteSettingsValuesRemoveURL: BasePath + "remoteSettings/values/remove",
    RemoteSettingsValuesImportURL: BasePath + "remoteSettings/values/import",
};

export default RemoteSettingsRoutes;
