// @flow
import { APICore } from '../apiCore';
import services from '../../../constants/services';

// Routes
import { CredentialsAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// Credentials
function credentialsValidate(): any {
    return api.post(CredentialsAPIConstants.ValidateURL, {}, api.getServiceHeaders(services.Credentials));
}

// Git
function credentialsGitInfo(params: any): any {
    return api.post(CredentialsAPIConstants.GitInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsAddGit(params: any): any {
    return api.post(CredentialsAPIConstants.GitAddURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsEditGit(params: any): any {
    return api.post(CredentialsAPIConstants.GitEditURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsSyncGit(params: any): any {
    return api.post(CredentialsAPIConstants.GitSyncURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsRemoveGit(params: any): any {
    return api.post(CredentialsAPIConstants.GitRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// Unity
function credentialsUnityInfo(params: any): any {
    return api.post(CredentialsAPIConstants.UnityInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsAddUnity(params: any): any {
    return api.post(CredentialsAPIConstants.UnityAddURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsEditUnity(params: any): any {
    return api.post(CredentialsAPIConstants.UnityEditURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsRemoveUnity(params: any): any {
    return api.post(CredentialsAPIConstants.UnityRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// Play Store
function credentialsGooglePlayStoreInfo(params: any): any {
    return api.post(CredentialsAPIConstants.GooglePlayStoreInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsGooglePlayStoreAdd(params: any): any {
    return api.postWithFile(CredentialsAPIConstants.GooglePlayStoreAddURL,
        {keyName: params.keyName},
        "file", params.file,
        api.getServiceHeaders(services.Credentials));
}
function credentialsGooglePlayStoreEdit(params: any): any {
    return api.postWithFile(CredentialsAPIConstants.GooglePlayStoreEditURL,
        {playStoreId: params.playStoreId, keyName: params.keyName},
        "file", params.file,
        api.getServiceHeaders(services.Credentials));
}
function credentialsGooglePlayStoreSync(params: any): any {
    return api.post(CredentialsAPIConstants.GooglePlayStoreSyncURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsGooglePlayStoreRemove(params: any): any {
    return api.post(CredentialsAPIConstants.GooglePlayStoreRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// Keystore
function credentialsGoogleKeystoreInfo(params: any): any {
    return api.post(CredentialsAPIConstants.GoogleKeystoreInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsGoogleKeystoreAdd(params: any): any {
    return api.postWithFile(CredentialsAPIConstants.GoogleKeystoreAddURL,
        {keyName: params.keyName, keyPassword: params.keyPassword},
        "file", params.file,
        api.getServiceHeaders(services.Credentials));
}
function credentialsGoogleKeystoreEdit(params: any): any {
    return api.postWithFile(CredentialsAPIConstants.GoogleKeystoreEditURL,
        {keystoreId: params.keystoreId, keyName: params.keyName, keyPassword: params.keyPassword},
        "file", params.file,
        api.getServiceHeaders(services.Credentials));
}
function credentialsGoogleKeystoreUpdatePassword(params: any): any {
    return api.post(CredentialsAPIConstants.GoogleKeystorePasswordURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsGoogleKeystoreSync(params: any): any {
    return api.post(CredentialsAPIConstants.GoogleKeystoreSyncURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsGoogleKeystoreRemove(params: any): any {
    return api.post(CredentialsAPIConstants.GoogleKeystoreRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// App Store
function credentialsAppleAppStoreInfo(params: any): any {
    return api.post(CredentialsAPIConstants.AppleAppStoreInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsAppleAppStoreAdd(params: any): any {
    return api.postWithFile(CredentialsAPIConstants.AppleAppStoreAddURL,
        {storeName: params.storeName, username: params.username, teamId: params.teamId, keyIssuer: params.keyIssuer, keyName: params.keyName, keyId: params.keyId},
        "file", params.file,
        api.getServiceHeaders(services.Credentials));
}
function credentialsAppleAppStoreEdit(params: any): any {
    return api.postWithFile(CredentialsAPIConstants.AppleAppStoreEditURL,
        {appStoreId: params.appStoreId, storeName: params.storeName, username: params.username, teamId: params.teamId, keyIssuer: params.keyIssuer, keyName: params.keyName, keyId: params.keyId},
        "file", params.file,
        api.getServiceHeaders(services.Credentials));
}
function credentialsAppleAppStoreSync(params: any): any {
    return api.post(CredentialsAPIConstants.AppleAppStoreSyncURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsAppleAppStoreRemove(params: any): any {
    return api.post(CredentialsAPIConstants.AppleAppStoreRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// Certificate
function credentialsAppleCertificateInfo(params: any): any {
    return api.post(CredentialsAPIConstants.AppleCertificateInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsAppleCertificateAdd(params: any): any {
    return api.postWithFiles(CredentialsAPIConstants.AppleCertificateAddURL,
        {certName: params.certName, certTag: params.certTag, password: params.password},
        ["file", "file2"], [params.file, params.file2],
        api.getServiceHeaders(services.Credentials));
}
function credentialsAppleCertificateEdit(params: any): any {
    return api.postWithFiles(CredentialsAPIConstants.AppleCertificateEditURL,
        {certificateId: params.certificateId, certName: params.certName, certTag: params.certTag, password: params.password},
        ["file", "file2"], [params.file, params.file2],
        api.getServiceHeaders(services.Credentials));
}
function credentialsAppleCertificateSync(params: any): any {
    return api.post(CredentialsAPIConstants.AppleCertificateSyncURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsAppleCertificateRemove(params: any): any {
    return api.post(CredentialsAPIConstants.AppleCertificateRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

// Provisions
function credentialsAppleProvisionInfo(params: any): any {
    return api.post(CredentialsAPIConstants.AppleProvisionInfoURL, params, api.getServiceHeaders(services.Credentials));
}
function credentialsAppleProvisionAdd(params: any): any {
    return api.postWithFile(CredentialsAPIConstants.AppleProvisionAddURL,
        {certificateId: params.certificateId, provisionName: params.provisionName, provisionTag: params.provisionTag},
        "file", params.file,
        api.getServiceHeaders(services.Credentials));
}
function credentialsAppleProvisionRemove(params: any): any {
    return api.post(CredentialsAPIConstants.AppleProvisionRemoveURL, params, api.getServiceHeaders(services.Credentials));
}

export { credentialsValidate,
    credentialsGitInfo, credentialsAddGit, credentialsEditGit, credentialsSyncGit, credentialsRemoveGit,
    credentialsUnityInfo, credentialsAddUnity, credentialsEditUnity, credentialsRemoveUnity,
    credentialsGooglePlayStoreInfo, credentialsGooglePlayStoreAdd, credentialsGooglePlayStoreEdit, credentialsGooglePlayStoreSync, credentialsGooglePlayStoreRemove,
    credentialsGoogleKeystoreInfo, credentialsGoogleKeystoreAdd, credentialsGoogleKeystoreEdit, credentialsGoogleKeystoreUpdatePassword, credentialsGoogleKeystoreSync, credentialsGoogleKeystoreRemove,
    credentialsAppleAppStoreInfo, credentialsAppleAppStoreAdd, credentialsAppleAppStoreEdit, credentialsAppleAppStoreSync, credentialsAppleAppStoreRemove,
    credentialsAppleCertificateInfo, credentialsAppleCertificateAdd, credentialsAppleCertificateEdit, credentialsAppleCertificateSync, credentialsAppleCertificateRemove,
    credentialsAppleProvisionInfo, credentialsAppleProvisionAdd, credentialsAppleProvisionRemove,
};
