// @flow
import { APICore } from '../apiCore';

// Routes
import { HubAPIConstants } from '../../../constants/urls/api';

const api = new APICore();

// applications
function applicationInfo(params: any): any {
    const headers = api.getSelectionOrganizationHeaders();
    return api.post(HubAPIConstants.ApplicationInfoURL, params, headers);
}

function createApplication(params: any): any {
    const headers = api.getSelectionOrganizationHeaders();
    return api.post(HubAPIConstants.ApplicationCreateURL, params, headers);
}

function editApplication(params: any): any {
    const headers = api.getSelectionOrganizationHeaders();
    headers["X-Application"] = params.applicationId;
    delete params.applicationId;
    return api.post(HubAPIConstants.ApplicationEditURL, params, headers);
}

function deleteApplication(params: any): any {
    const headers = api.getSelectionOrganizationHeaders();
    headers["X-Application"] = params.applicationId;
    delete params.applicationId;
    return api.post(HubAPIConstants.ApplicationDeleteURL, params, headers);
}

export { applicationInfo, createApplication, editApplication, deleteApplication };
