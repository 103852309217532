import main from './main.json';
import gitList from './gitList.json';
import addGit from './addGit.json';
import editGit from './editGit.json';
import syncGit from './syncGit.json';
import removeGit from './removeGit.json';

const output = Object.assign({}, main, gitList, addGit, editGit, syncGit, removeGit);

export default output;
